import apolloClient from 'providers/ApolloClientProvider';
import { GRAPHQL_CACHE_FIRST } from 'components/graphql/constants';
import gql from 'graphql-tag';

export const QUERY_TRADE_CONFIG = gql`query getTradeConfigsQuery {
  getTradeConfigs {
    id
    name
    countdown
  }
}`;

export const loadTradeConfig = () => apolloClient.query({
  query: QUERY_TRADE_CONFIG,
  fetchPolicy: GRAPHQL_CACHE_FIRST
}).then(({ data }) => data);
