import gql from 'graphql-tag';
import apolloClient from 'providers/ApolloClientProvider';

const PRICING_ACCUMULATOR_MIN_CALCULATE_PRICE_MUTATION = gql`mutation pricingAccumulatorCalculatePriceByCardId(
  $inputs: [CardInput!]
  $structureExpiryDate: DateTime!
  $pricingCardId: Int!
  $pricingTemplateId: Int!
  $commodityContractId: Int!
  $priceReference: Float!
  $updateState: AccumulatorPricingCardStateEnum!
  $msaFixings: Int
  $msaFixingDays: Int
  $calendar: [String]
) {
  pricingAccumulatorCalculatePriceByCardId (
    inputs: $inputs
    structureExpiryDate: $structureExpiryDate
    pricingCardId: $pricingCardId
    pricingTemplateId: $pricingTemplateId
    commodityContractId: $commodityContractId
    priceReference: $priceReference
    updateState: $updateState
    msaFixings: $msaFixings
    msaFixingDays: $msaFixingDays
    calendar: $calendar
  ) {
    trailId: id
    vega
    deltaPercentage
    notional
    futValPt
    leverage
    isTrade
    futTradingUnits
    roundingRule
    levels {
      field
      value
    }
  }
}`;

export const calculateAccumulatorMini = async (
  {
    pricingCardId,
    pricingTemplateId,
    selectedUnderlying,
    dailySize
  },
  inputs = [],
  {
    structureExpiryDate,
    reference: priceReference,
    referenceType: updateState,
    msaFixings = null,
    calendar
  }
) => {
  const commodityContractId = selectedUnderlying && selectedUnderlying.id ? selectedUnderlying.id : null;
  const variables = {
    commodityContractId,
    pricingCardId,
    pricingTemplateId,
    structureExpiryDate,
    priceReference: parseFloat(priceReference),
    inputs,
    updateState,
    msaFixings,
    msaFixingDays: dailySize,
    calendar
  };

  let response = await apolloClient.mutate({
    mutation: PRICING_ACCUMULATOR_MIN_CALCULATE_PRICE_MUTATION,
    variables,
  })

  return response?.data?.pricingAccumulatorCalculatePriceByCardId;
}
