import React from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { PRICING_PAGE_TITLE } from 'pages/pricing/constants';

export const PricingPage = props => (
  <ErrorBoundary>
    <Bundle title={PRICING_PAGE_TITLE} load={() => import('./PricingLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);

export default PricingPage;
