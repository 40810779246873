import React, { Fragment, PureComponent } from 'react';
import { apolloErrors } from 'providers/ApolloClientProvider';
import moment from 'moment';
import round from 'lodash/round';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import InlineInput from 'components/form/InlineInput';
import { TYPE_MARKET, ORDER_TYPES_DEFAULT, PRICINIG_ORDER_TYPES, FX_TIME_IN_FORCE_DEFAULT, TIME_IN_FORCE_GTD, FX_TIME_IN_FORCE_OPTIONS, FX_ORDER_TYPES } from 'components/trade/constants.js';
import { formatDate2UI, filterTimeInForceOptions } from 'pages/price/PriceForm/utils';
import { isWeekday } from 'pages/price/PriceForm/forms/accumulator/validators.js';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { compose, bindActionCreators } from 'redux';
import { Form, reduxForm, getFormValues, reset, SubmissionError, Field, change } from 'redux-form';
import { injectIntl, FormattedNumber } from 'react-intl';
import { DATE_FORMAT_LABEL, CARD_STATUSES, PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT } from 'constants.js';
import { PRICING_FX_TRADE_MUTATION2 } from './query';
import OrderAccount from 'components/orders/accounts/AccountsTrade';
import OrderTypeField from 'components/trade/components/OrderTypeField';
import ExpiryDateField from 'components/trade/components/ExpiryDateField';
import PriceField from './PriceField';

const { REJECTED } = CARD_STATUSES;
class FxTrade extends PureComponent {
  constructor(props) {
    super(props);
    const { price, form } = props;
    this.state = {
      submitted: false,
      isTraded: false,
      price,
      formName: form,
    };
  }

  submit = async (data, dispatch, props) => {
    const {
      id,
      client,
      priceForCalcSpread,
    } = this.props;
    const {
      orderType = ORDER_TYPES_DEFAULT.value,
      timeInForce = FX_TIME_IN_FORCE_DEFAULT.value,
      expiryDate,
      account,
      price,
    } = data

    try {
      const variables = {
        id,
        price,
        quoteId: null,
        orderType,
        timeInForce,
        expiryDate,
        slackIceClientsId: account,
        priceForCalcSpread
      };

      const { data: { fxTrade } } = await client.mutate({
        mutation: PRICING_FX_TRADE_MUTATION2,
        variables,
      })

      if (fxTrade?.id && fxTrade?.status) {
        this.setState({
          submitted: true,
          isTraded: fxTrade.status !== REJECTED
        });
        this.runResetFormTimeout();
      }

      return fxTrade;
    } catch (e) {
      console.log(e);
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  }

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(FX_ORDERS_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      direction,
      tradeDate,
      notional,
      notionalCurrency,
      fxContract,
      error,
      firstName,
      lastName,
      formData,
      intl: { formatMessage },
      initialValues: { fxBankHolidays },
      actualPrice,
      structure,
      priceDigitsConvention
    } = this.props;

    const { orderType, timeInForce, expiryDate, maxExpiryDate } = formData;
    const { submitted, isTraded, formName } = this.state;
    const { label: orderTypeLabel } = PRICINIG_ORDER_TYPES.find(item => item.value === orderType) || ORDER_TYPES_DEFAULT;

    const isNotFxHoliday = (date) => {
      return isWeekday(date) && !fxBankHolidays.includes(moment(date).format('YYYY-MM-DD'));
    }

    return (
      submitted ?
        isTraded ? <SuccessMessage children={renderSuccessMessage('Your order has been created successfully.')} />
          : <SuccessMessage children={renderSuccessMessage('Your trade was rejected.')} />
        : <Fragment>
          <h1>
            {direction === 'buy' ? 'Buy' : 'Sell'}&nbsp;
            <FormattedNumber
              value={notional}
              minimumFractionDigits={0}
              maximumFractionDigits={4} />&nbsp;
            ({notionalCurrency})&nbsp;
            {fxContract}&nbsp;
            {formatDate2UI(tradeDate, DATE_FORMAT_LABEL)}
          </h1>
          <MainErrorMessage error={error} />
          <Form id={`form-card-fx`} onSubmit={handleSubmit(this.submit)} noValidate>
            <Row className="row-size-xs">
              <OrderTypeField structure={structure} isCombinedCard={false} overriddenOrderTypes={FX_ORDER_TYPES} sm={4} />
              <Col xs={12} sm={4}>
                <Field
                  name="timeInForce"
                  className="timeInForce"
                  label={<FormattedMessage id="trade/timeInForce.label"
                    defaultMessage="Time In Force" />}
                  justValue={true}
                  component={InlineSelectInput}
                  options={filterTimeInForceOptions(FX_TIME_IN_FORCE_OPTIONS, orderType)}
                  clearable={false}
                />
              </Col>
              {timeInForce === TIME_IN_FORCE_GTD ? <ExpiryDateField formatMessage={formatMessage} maxExpiryDate={maxExpiryDate} filterDate={isNotFxHoliday} /> : null}
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                <Field
                  name="notional"
                  type="number"
                  placeHolder={formatMessage({
                    id: "trade/total-size.label",
                    defaultMessage: "Total Size",
                  })}
                  label={<FormattedMessage id="trade/total-size.label"
                    defaultMessage="Total Size" />}
                  step="1"
                  disabled={true}
                  component={InlineInput}
                />
              </Col>
              <Col xs={12} sm={4}>
                <PriceField orderType={orderType} actualPrice={actualPrice} formatMessage={formatMessage} maximumFractionDigits={priceDigitsConvention} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <OrderAccount
                  direction={direction}
                  formName={formName}
                />
              </Col>
            </Row>
            <div className="mb-3">
              <span className="text-bolder text-uppercase">Client {direction === 'buy' ? 'Buys' : 'Sells'}</span>&nbsp;
              <FormattedNumber
                value={notional}
                minimumFractionDigits={0}
                maximumFractionDigits={4} />&nbsp;
              ({notionalCurrency})&nbsp;
              {fxContract}&nbsp;
              {formatDate2UI(tradeDate, DATE_FORMAT_LABEL)}
            </div>

            <div className="mb-3">
              <FormattedMessage id="common/account"
                defaultMessage="User" />: {firstName} {lastName}
            </div>

            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.onCloseModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="submit"
                className={"text-uppercase" + (direction === 'buy' ? ' btn-buy' : ' btn-sell')}
                onClick={this.trade}
                disabled={invalid || submitting ||
                  (timeInForce === TIME_IN_FORCE_GTD && !expiryDate)}>
                <FormattedMessage id="orders.send-limit"
                  defaultMessage={`Send ${orderTypeLabel} order`} />
              </Button>
            </div>
          </Form>
        </Fragment>
    );
  }
}

FxTrade.defaultProps = {
  formData: {}
};

FxTrade.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.number
};

export const FX_ORDERS_FORM = '@PRICINIG/FX/FORM_TRADE';
export const selectorFormValues = getFormValues(FX_ORDERS_FORM);

const getPrice = (id, trailPrice = [], orderType, priceDigitsConvention) => {
  if (orderType === TYPE_MARKET) {
    if (!id) return { price: null };
    const { price = null } = trailPrice.find(({ id: cardId }) => cardId === id)
    // Let's not round the prices. GS does not like it.
    // No longer returning quoteId either
    return round(price, priceDigitsConvention) || null;
  }

  return 0;
}

const mapStateToProps = (state, ownProps) => {
  const { id, price: selectedPrice, notional, tradeDate } = ownProps;
  const formData = selectorFormValues(state);
  const trailPrice = state?.fx?.trailPrice?.cards || [];

  const contractConfig = state?.fx?.pricings?.find((x) =>
    x.baseCurrency === ownProps.baseCurrency &&
    x.termCurrency === ownProps.termCurrency);

  const { fxBankHolidays, priceDigitsConvention } = contractConfig || {};
  const { orderType = TYPE_MARKET } = formData || {};

  return {
    formData,
    actualPrice: getPrice(id, trailPrice, orderType, priceDigitsConvention),
    priceDigitsConvention,
    initialValues: {
      fxBankHolidays,
      price: round(selectedPrice, priceDigitsConvention) || null,
      orderType: TYPE_MARKET,
      timeInForce: FX_TIME_IN_FORCE_DEFAULT.value,
      notional,
      maxExpiryDate: moment(tradeDate).subtract(1, 'days'),
    },
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FX_ORDERS_FORM,
    enableReinitialize: true,
  }),
  withApollo,
  injectIntl,
)(FxTrade);
