import gql from 'graphql-tag';
import NotifyMeDropdownListItem from './NotifyMeDropdownListItem';
import apolloClient from 'providers/ApolloClientProvider';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

// @TODO: add posibility to get filter as graphql variable
export const notificationsQuery = (filter) => {
  return gql`query fetchNotificationsDropdownRequest (
      $cursor: String
    ) {
      userCurrent {
        id
        notifyMe (
          after: $cursor
          first: 10
          filter: ${filter}
        ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              endCursor
            },
            edges {
              node {
                id
                ...notificationDropdownConnectionFragment
                pricingHistory {
                  ... on PricingAccumulatorHistoryType {
                    id
                    structure
                    accumulationLevel: strikeLevel
                    calculateType
                    barrierLevel
                    priceToDisplay
                    referencePrice
                    notional
                    barrierType
                    price
                    pricePerLot
                    expired
                    marketDataDisabled
                    marketData {
                      id
                      bloombergTicker
                      optionExpiryDate
                      contractExpiry
                      quotedCurrency
                      commodityContract
                      structure
                    }
                    description {
                      id
                      type
                      leverage
                      leverageStyle
                    }
                  }
                  ... on PricingVanillaHistoryType {
                    id
                    structure
                    strikeLevel
                    calculateType
                    priceToDisplay
                    referencePrice
                    notional
                    barrierLevel
                    price
                    expired
                    marketDataDisabled
                    marketData {
                      id
                      bloombergTicker
                      optionExpiryDate
                      contractExpiry
                      quotedCurrency
                      commodityContract
                      structure
                    }
                    description {
                      id
                      type
                    }
                  }
                }
              }
              __typename
          }
          __typename
        }
        __typename
      }
    }
    ${NotifyMeDropdownListItem.fragments.item}`;
}

export const NOTIFICATIONS_QUERY = gql`query notificationsListQuery($isRead: Boolean) {
  notificationsList(isRead: $isRead) {
    id
    payload
    subject
    body
    updatedAt
    createdAt
    isRead
  }
}`;

export const fetchNotifications = (isRead) => {
  return apolloClient.query({
    query: NOTIFICATIONS_QUERY,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: {
      isRead,
    }
  }).then(({ data }) => data);
}

export const READ_ALL_NOTIFICATIONS = gql`mutation readAllNotificationsMutation {
  readAllNotifications
}`;

export const readAllNotificationsMutation = (order) => {
  return apolloClient.mutate({
    mutation: READ_ALL_NOTIFICATIONS,
  });
}


export const READ_NOTIFICATION_DROPDOWN = gql`mutation readNotificationMutation (
  $id: String!
) {
  readNotification (
    id: $id
  ) {
    isRead
  }
}`;

export const DELETE_NOTIFICATION_DROPDOWN = gql`mutation deleteNotificationMutation (
  $id: String!
) {
  deleteNotification (
    id: $id
  )
}`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`subscription notificcationsSubscription {
  notifications {
    id
    payload
    subject
    body
    updatedAt
    createdAt
    isRead
    type
  }
}`;

export const subscribeNotifications = () => {
  return apolloClient.subscribe({
    query: NOTIFICATIONS_SUBSCRIPTION
  });
};

export const NOTIFICATIONS_UNREAD_QUERY = gql`query unreadNotificationsQuery {
  unreadNotifications
}`;

export const fetchUnreadNotifications = () => {
  return apolloClient.query({
    query: NOTIFICATIONS_UNREAD_QUERY,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
  }).then(({ data }) => data);
}
