import React, { ReactNode, ReactElement, useMemo, useEffect, useCallback } from 'react';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import logger, { USER_ID, LOGIN_TIME } from 'utils/Logger';
import { setIsLoadingAction, setIsAuthenticatedAction } from 'redux/actions/auth';
import { JwtAdapter } from './JwtAdapter';
import { jwtStateSelector, AuthState, STORAGE_NAME } from './helpers';

interface JwtProviderOwnProps {
  children: ReactNode,
  context?: React.Context<Auth0ContextInterface>,
};

interface JwtProviderStateProps {
  state: AuthState,
};

interface JwtProviderDispatchProps {
  setIsLoading: typeof setIsLoadingAction,
  setIsAuthenticated: typeof setIsAuthenticatedAction,
};

type JwtProviderProps = JwtProviderOwnProps &
  JwtProviderStateProps &
  JwtProviderDispatchProps;

const JwtProvider: React.FC<JwtProviderProps> = ({
  children,
  context = Auth0Context,
  state,
  setIsAuthenticated,
  setIsLoading,
}): ReactElement => {
  useEffect(() => {
    const token = localStorage.getItem(STORAGE_NAME);
    setIsAuthenticated(!!token);
    setIsLoading(false);
  }, []);

  const logout = useCallback(async (): Promise<void> => {
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(STORAGE_NAME);
    setIsAuthenticated(false);
    setIsLoading(false);
  }, []);

  const loginWithRedirect = useCallback((token): void => {
    setIsLoading(true);
    localStorage.setItem(STORAGE_NAME, token);
    localStorage.setItem(LOGIN_TIME, `${Date.now()}`);
    logger.info('The user logged In.', {
      start: localStorage.getItem(LOGIN_TIME),
    });
    setIsAuthenticated(true);
    setIsLoading(false);
  }, []);

  const getAccessTokenSilently = useCallback(async (): Promise<any> => {
    const token = localStorage.getItem(STORAGE_NAME);
    if (state.isAuthenticated !== !!token) setIsAuthenticated(!!token);
    return !!token ? token : null
  }, [state]);

  const contextValue = useMemo(() => {
    return {
      ...state,
      getAccessTokenWithPopup: () => { },
      getIdTokenClaims: () => { },
      loginWithPopup: () => { },
      handleRedirectCallback: () => { },
      getAccessTokenSilently,
      loginWithRedirect,
      logout,
    };
  }, [
    state,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  ]);

  return <context.Provider value={contextValue}>
    <JwtAdapter>
      {children}
    </JwtAdapter>
  </context.Provider>;
}

const jwtProviderMapStateToProps = state => ({
  state: jwtStateSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setIsLoading: setIsLoadingAction,
  setIsAuthenticated: setIsAuthenticatedAction
}, dispatch);

export default compose(
  connect(jwtProviderMapStateToProps, mapDispatchToProps)
)(JwtProvider);
