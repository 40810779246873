import React, { memo, useState, useEffect } from "react";
import moment from "moment";
import isNull from 'lodash/isNull';
import InlineInput from "components/form/InlineInput";
import InlineSelectInput from "components/form/inputs/InlineSelectInput";
import InlineInputWithMask from "components/form/inputs/inlineInput/InlineInputWithMask";

import { ROUTE_PRICING_ORDERS_SWAP } from "pages/constants";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { Field, Form, getFormValues, reduxForm, change } from "redux-form";
import { orderInit } from "redux/actions/orders";
import { swapCardsRequestPrice } from "redux/actions/swap";
import validate from "./validate.js";
import { tommorowDay, isWeekday, tommorowDayWithoutWeekends } from "pages/price/PriceForm/forms/accumulator/validators.js";
import {
  DATE_FORMAT_PRICING_ACCUMULATOR_MINI,
  CARD_STATUSES,
  DEFAULT_UNIT_TYPE,
} from "constants.js";
const { REQUESTED, APPROVED } = CARD_STATUSES;

export const getSelectedCurrency = (currencyOptions = [], currency = "") =>
  currencyOptions.find(({ value }) => value === currency) || {};
function SwapPricingOutputCardForm(props) {
  const {
    touch,
    change,
    direction,
    id,
    expiryOptions,
    secondExpiryOptions,
    handleSubmit,
    cardRequestPrice,
    invalid,
    isLoading,
    history,
    orderInit,
    currencyOptions,
    status,
    quotedCurrency,
    termCurrency,
    unitOptions,
    formData: { payoutCurrency = "" },
    price,
    fxBankHolidays,
    unitsDisable,
    disableCompo,
    isError,
    contractExpirySecond,
    isSpot,
    isMetal,
    tradeDate,
    contractExpiry,
    isConvertingUScToUSD
  } = props;

  const [deliverable, setDeliverable] = useState(false);
  const [maxTradeDate, setMaxTradeDate] = useState(tradeDate);
  const isCombinedCard = payoutCurrency !== quotedCurrency;
  const isCalendarSpread = !!contractExpirySecond && !isCombinedCard
  const isDisabled = (!expiryOptions || !expiryOptions.length || !currencyOptions) && !isSpot;
  const isRequestedStatus = status === REQUESTED;
  const tradeDateLabel = deliverable ? "Value Date" : "Fixing Date";
  const isDisabledRequestOffer = isCombinedCard && !price;
  const tradeBtnName = isCombinedCard
    ? "Trade"
    : direction === "buyer"
    ? "Request Offer"
    : "Request Bid";

  const requestBtnName = direction === "buyer" ? "Offer" : "Bid";
  const trade = () => {
    orderInit({ id });
    history.push(ROUTE_PRICING_ORDERS_SWAP);
  };

  const requestPrice = () => {
    cardRequestPrice({ id });
  };

  const changeCurrency = (currency) => {
    const { deliverable } = getSelectedCurrency(currencyOptions, currency);
    setDeliverable(deliverable);
  };

  useEffect(() => {
    if(!isSpot && !contractExpiry && expiryOptions.length) {
      const { value, bloombergTicker, contractCode } = expiryOptions[0]
      change("contractExpiry", value);
      change("bloombergTicker", bloombergTicker);
      if(contractCode) change("contractCode", contractCode);
    }
  }, [contractExpiry, isSpot, expiryOptions.length]);

  useEffect(() => {
    if (isCombinedCard) {
      const { deliverable } = getSelectedCurrency(
        currencyOptions,
        termCurrency
      );
      setDeliverable(deliverable);
    }

    touch('payoutCurrency');
  }, [termCurrency]);

  const isNotFxHoliday = (date) => {
    return (
      isWeekday(date) &&
      !fxBankHolidays.includes(moment(date).format("YYYY-MM-DD"))
    );
  };

  const unitHandler = ({ unitType = DEFAULT_UNIT_TYPE }) => {
    change("unitType", unitType);
  };

  const contractExpiryHandler = (contractExpiry) => {
    const { bloombergTicker = null, contractCode = null } = expiryOptions.find(({ value }) => value === contractExpiry) || {}

    change("bloombergTicker", bloombergTicker);
    if(contractCode) change("contractCode", contractCode);
  }

  const contractExpirySecondHandler = (contractExpirySecond) => {
    const { bloombergTicker: bloombergTickerSecond = null, contractCode = null } = secondExpiryOptions.find(({ value }) => value === contractExpirySecond) || {}

    change("bloombergTickerSecond", bloombergTickerSecond);
    if(contractCode) change("contractCode", contractCode);
  }

  useEffect(() => {
    setMaxTradeDate(tradeDate);
  }, [contractExpiry])

  return (
    <Form id={`form-card-swap-${id}`} onSubmit={handleSubmit} noValidate>
      <Row className="row-size" style={{ marginTop: '14px' }}>
        <Col xs={6}>
          <Field
            name="payoutCurrency"
            label={
              <FormattedMessage
                id="form.price.fields.payout-currency.label"
                defaultMessage="Currency"
              />
            }
            justValue={true}
            clearable={false}
            showError={true}
            component={InlineSelectInput}
            onChange={changeCurrency}
            options={disableCompo ? currencyOptions.filter(i => isNull(i.fxCode)) : currencyOptions}
            forceDisabled={isDisabled ? isDisabled : disableCompo || isCalendarSpread}
            preselectIfOneOption={true}
            className={{
              'form-control-force-disabled': false
            }}
          />
        </Col>
        <Col xs={6}>
          {isSpot && isMetal ? (
            <Field
              name="contractExpiry"
              type="date"
              placeHolder='Prompt Date'
              label='Prompt Date'
              filterDate={isWeekday}
              minDate={tommorowDayWithoutWeekends()}
              dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
              component={InlineInput}
              disabled={isDisabled}
            />
          ) : (
            isSpot ? (
              <div>
                <FormattedMessage
                  id="swap/spot"
                  defaultMessage="Spot"
                />
              </div>
          ) : expiryOptions && expiryOptions.length ? (
            <Field
              name="contractExpiry"
              label="Contract Expiry"
              component={InlineSelectInput}
              justValue={true}
              clearable={false}
              disabled={!expiryOptions.length}
              options={expiryOptions}
              onChange={contractExpiryHandler}
            />
          ) : (
            <div className="form-control-label">
              <FormattedMessage
                id="swap/no-data"
                defaultMessage="There are no swaps available at the moment"
              />
            </div>
          ))}

        </Col>
        <Col xs={6}>
          <Field
            name="quantity"
            type="formatted-number"
            placeholder="Total Size"
            label={
              <FormattedMessage
                id="form.price.fields/total-size.label"
                defaultMessage="Total Size"
              />
            }
            step="1"
            disabled={isDisabled}
            component={InlineInputWithMask}
          />
        </Col>
        {isCalendarSpread ?
          <Col xs={6}>
            {secondExpiryOptions && secondExpiryOptions.length ? (
              <Field
                name="contractExpirySecond"
                label="Contract Expiry 2"
                component={InlineSelectInput}
                justValue={true}
                clearable={false}
                disabled={!secondExpiryOptions.length}
                options={secondExpiryOptions}
                onChange={contractExpirySecondHandler}
              />
            ) : (
              <div className="form-control-label">
                {isError ? (
                  <FormattedMessage
                    id="swap/no-expriry-2"
                    defaultMessage="There is no Contract Expiry 2 for the selected Contract Expiry"
                  />
                ): (
                  <FormattedMessage
                    id="swap/no-data"
                    defaultMessage="There are no swaps available at the moment"
                  />
                )}
              </div>
            )}
          </Col> : null
        }
        {!isCalendarSpread ? (
          <Col xs={6}>
            <Field
              name="unit"
              label="Unit"
              component={InlineSelectInput}
              justValue={false}
              clearable={false}
              onChange={unitHandler}
              disabled={isDisabled && unitsDisable}
              options={unitOptions}
            />
          </Col>
        ) : null}
      </Row>
      <Row className="row-size">
        <Col xs={6}>
          {isCalendarSpread ? (
            <Field
              name="unit"
              label="Unit"
              component={InlineSelectInput}
              justValue={false}
              clearable={false}
              onChange={unitHandler}
              disabled={isDisabled && unitsDisable}
              options={unitOptions}
            />
          ) : null}
          {isCombinedCard && !isConvertingUScToUSD && !(isMetal && isSpot) ? (
            <Field
              name="tradeDate"
              type="date"
              placeHolder={tradeDateLabel}
              label={tradeDateLabel}
              filterDate={isNotFxHoliday}
              minDate={tommorowDay()}
              maxDate={maxTradeDate || tradeDate}
              dateFormat={DATE_FORMAT_PRICING_ACCUMULATOR_MINI}
              component={InlineInput}
              disabled={isDisabled}
            />
          ) : null}
        </Col>

        <Col className="pb-3" xs={6}>
          {!isCombinedCard || isConvertingUScToUSD || status === APPROVED ? (
            <Button
              type="button"
              className={
                "w-100 px-1 btn-input-h m-t-label text-uppercase" +
                (direction === "buyer" ? " btn-buy" : " btn-sell")
              }
              onClick={trade}
              disabled={
                isLoading || isDisabled || invalid || isDisabledRequestOffer || isError
              }
            >
              {!invalid && !isDisabled && isLoading
                ? "Loading..."
                : isConvertingUScToUSD ? `Request ${requestBtnName}`: tradeBtnName}
            </Button>
          ) : (
            <Button
              type="button"
              className="w-100 px-1 btn-input-h m-t-label text-uppercase btn-primary"
              onClick={requestPrice}
              disabled={isLoading || isDisabled || invalid || isRequestedStatus || isError}
            >
              {!invalid && !isDisabled && isLoading
                ? "Loading..."
                : status === REQUESTED
                ? `Requested ${requestBtnName}`
                : `Request ${requestBtnName}`}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
}

SwapPricingOutputCardForm.defaultProps = {
  isLoading: false,
};

SwapPricingOutputCardForm.propTypes = {
  expiryOptions: PropTypes.array.isRequired,
  direction: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.any,
};

export const PRICINIG_SWAP_FORM_OUTPUT = "@PRICINIG/SWAP/FORM_OUTPUT";
export const swapFormCardName = (id) => `${PRICINIG_SWAP_FORM_OUTPUT}_${id}`;
export const swapFormValuesSelector = (id, state) =>
  getFormValues(swapFormCardName(id))(state);

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    quantity,
    quotedCurrency,
    termCurrency,
    tradeDate,
    currencyState = {},
    fxBankHolidays = [],
    unit,
    unitType,
    units: unitOptions,
    bloombergTicker,
    bloombergTickerSecond,
    contractCode,
    disableCompo,
    isSpot,
    isMetal
  } = ownProps;
  const currencyOptions = currencyState[quotedCurrency]
    ? currencyState[quotedCurrency]
    : [
        {
          label: quotedCurrency,
          value: quotedCurrency,
          deliverable: false,
          fxCode: null,
        },
      ];
  const contractExpiry = isSpot && !ownProps.contractExpiry ? tommorowDayWithoutWeekends() :
    ownProps && ownProps.contractExpiry
      ? ownProps.contractExpiry
      : ownProps.expiryOptions && ownProps.expiryOptions.length
      ? ownProps.expiryOptions[0].value
      : null;

  const contractExpirySecond =
    ownProps && ownProps.contractExpirySecond
      ? ownProps.contractExpirySecond
      : ownProps.secondExpiryOptions && ownProps.secondExpiryOptions.length
      ? ownProps.secondExpiryOptions[0].value
      : null;
  const qty = !isNaN(parseFloat(quantity)) ? quantity : 1;

  return {
    form: swapFormCardName(id),
    formData: swapFormValuesSelector(id, state) || {},
    currencyOptions,
    unitOptions,
    unitsDisable: unitOptions.length <= 1,
    initialValues: {
      quantity: qty,
      currency: quotedCurrency,
      payoutCurrency: termCurrency,
      tradeDate,
      unit,
      unitType,

      contractExpiry,
      contractExpirySecond,
      bloombergTicker,
      bloombergTickerSecond,
      contractCode,
      disableCompo,
    },
    fxBankHolidays,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cardRequestPrice: swapCardsRequestPrice,
      orderInit,
      change,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ enableReinitialize: true, asyncValidate: validate }),
  withRouter,
  memo
)(SwapPricingOutputCardForm);
