import { createSelector } from 'reselect';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withAuth0 } from '@auth0/auth0-react';
import { logout, setUnwindIdAction } from 'redux/actions/auth';
import logger from 'utils/Logger';
import client from 'providers/ApolloClientProvider';
import { cancelUnwindMutation } from 'pages/dashboards/components/types/trades/table/OpenTradesTableWrapper'
const CHECK_INTERVAL = 2000; // in ms
const STORAGE_KEY = 'LAST_USER_ACTION';
const DEBOUNCE_TIME = 150;
const UNITL_AUTO_LOGOUT_MS = parseInt(window.JWT_EXPIRATION_MS, 10) + DEBOUNCE_TIME; // in ms

const EVENTS = [
  'click',
  'mouseover',
  'mouseout',
  'keydown',
  'keyup',
  'keypress'
];

class IdleUser extends Component {
  constructor(props) {
    super(props);
    this.initListener();
    this.initInterval();
    this.reset = debounce(this._reset, DEBOUNCE_TIME);
  }

  getLastAction() {
    return parseInt(localStorage.getItem(STORAGE_KEY), 10);
  }

  setLastAction(lastAction) {
    localStorage.setItem(STORAGE_KEY, lastAction.toString());
  }

  resetFunction = () => {
    this.reset()
  }

  initListener = () => {
    EVENTS.forEach((ev) => {
      document.body.addEventListener(ev, this.resetFunction);
    });
  }

  componentWillUnmount() {
    EVENTS.forEach((ev) => {
      document.body.removeEventListener(ev, this.resetFunction);
    })
    this.clearInterval();
    localStorage.removeItem(STORAGE_KEY);
  }

  _reset = () => {
    this.setLastAction(Date.now());
  }
  initInterval = () => {
    this.interval = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  clearInterval = () => {
    clearInterval(this.interval);
  }

  check = async () => {
    const { logout, unwindHistoryId, setUnwindIdAction, auth0 } = this.props;
    const { getAccessTokenSilently } = auth0;
    const now = Date.now();
    const timeleft = this.getLastAction() + UNITL_AUTO_LOGOUT_MS;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    const authToken = await getAccessTokenSilently();

    if (isTimeout || !authToken) {
      if(unwindHistoryId) await cancelUnwindMutation(client, setUnwindIdAction, unwindHistoryId)
      logout();
      logger.warn('Idle logout.', {
        isTimeout,
        authToken: !authToken,
        UNITL_AUTO_LOGOUT_MS,
        lastAction: this.getLastAction(),
        now
      });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapStateToProps = createSelector(
  [state => state.auth],
  ({ unwindHistoryId }) => ({
    unwindHistoryId,
  })
);

IdleUser.propTypes = {
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

const mapDispatchToProps = dispatch => bindActionCreators({ logout, setUnwindIdAction }, dispatch);

export default compose(
  withAuth0,
  connect(mapStateToProps, mapDispatchToProps)
)(IdleUser);
