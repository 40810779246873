import apolloClient from 'providers/ApolloClientProvider';
import gql from 'graphql-tag';

const FETCH_STRUCTURES = gql`query {
  pricingStructure
  isAsianSwap
}`;

export const checkAvailableStructure = () => apolloClient.query({
  query: FETCH_STRUCTURES
}).then((resp) => {
  return resp.data;
});
