import apolloClient from 'providers/ApolloClientProvider';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { STRUCTURE_FX, DEFAULT_LIMIT_FOR_LOAD_CARDS } from 'constants.js';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { pricingResultsLoader } from 'redux/actions/price';
import store from 'store.js';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import { mapNodes } from 'utils/';

export const FX_LOAD_FORM_DATA = gql`query loadAllDataFx {
  pricingFXContractsConnection {
    structureStatus
    commodities {
      id
      commodity
      commodityCode
      baseCurrency
      termCurrency
      tradeDate
      deliverable
      fxBankHolidays
      priceDigitsConvention
    }
  }
}`;

export const loadFxData = async () => {
  store.dispatch(pricingResultsLoader(true));
  try {
    let resp = await apolloClient.query({
      query: FX_LOAD_FORM_DATA,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
    })
      .then(async (res) => {
        const data = res.data && res.data.pricingFXContractsConnection;
        const contracts = Array.isArray(data.commodities) && data.commodities

        if (isEmpty(contracts)) {
          store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_FX));
          store.dispatch(pricingResultsLoader(false));
          return;
        };

        return contracts;

      });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_FX));
    store.dispatch(pricingResultsLoader(false));
    console.log(e);
  }
};

const PRICING_FX_CALCULATE_PRICE_MUTATION = gql`mutation fxCardCreateMutation(
  $structure: String!
  $direction: FxCardDirectionEnumType!
  $tradeDate: DateTime!
  $fxCodeId: String!
  $notional: Float
  $notionalCurrency: String!
) {
  fxCardCreate (
    structure: $structure
    direction: $direction
    tradeDate: $tradeDate
    fxCodeId: $fxCodeId
    notional: $notional
    notionalCurrency: $notionalCurrency
  ) {
    id
    price,
    fxCode,
    notionalCurrency,
    notional,
    deliverable,
    baseCurrency,
    termCurrency,
    fxContract,
    status,
    limit,
  }
}`;

export const createFxCardMutation = (structure,
  {
    id,
    direction,
    tradeDate,
    notionalCurrency,
    notional,
  }) => {
  return apolloClient.mutate({
    mutation: PRICING_FX_CALCULATE_PRICE_MUTATION,
    variables: {
      fxCodeId: id,
      structure,
      direction,
      tradeDate,
      notionalCurrency,
      notional,
    },
  }).then(response => response.data.fxCardCreate);
}

const PRICINIG_FX_HISTORY = gql`query fxCardsConnectionQuery (
  $cursor: String
  $limit: Int
) {
  fxCardsConnection (
    after: $cursor
    first: $limit
  ) {
    edges {
      node{
        id
        direction
        fxCode
        price
        structure
        tradeDate
        notionalCurrency
        notional
        deliverable
        baseCurrency
        termCurrency
        fxContract
        status
        limit
        quoteId
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}`;

export const loadFxCardsData = async (cursor = null, limit) => {
  try {
    let resp = await apolloClient.query({
      query: PRICINIG_FX_HISTORY,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        cursor,
        limit: limit || DEFAULT_LIMIT_FOR_LOAD_CARDS
      }
    }).then((res) => {
      const cards = mapNodes(res.data.fxCardsConnection);
      const pageInfo = res.data.fxCardsConnection.pageInfo;
      return { cards, pageInfo };
    });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_FX));
    console.log(e);
  }
}

const PRICING_FX_DELETE_PRICE_MUTATION = gql`mutation fxCardDeleteMutation(
  $id: String!
) {
  fxCardDelete (
    id: $id
  )
}`;

export const deleteFxCardMutation = id => {
  return apolloClient.mutate({
    mutation: PRICING_FX_DELETE_PRICE_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

export const FX_COMMODIY_CONTRACT_SUBSCRIPTION = gql`subscription mspx {
  pricingFxContract {
    contract
    price
    direction
    tradeDate
    quoteId
    limit
    isContra
    notional
    priceForCalcSpread
  }
}`;

export const subscribeFxCommodityContracts = () => {
  return apolloClient.subscribe({
    query: FX_COMMODIY_CONTRACT_SUBSCRIPTION,
  });
};

const PRICING_FX_UPDATE_PRICE_MUTATION = gql`mutation fxCardUpdateMutation(
  $id: String!
  $structure: String
  $direction: FxCardDirectionEnumType
  $tradeDate: DateTime!
  $fxCodeId: String
  $notional: Float
  $notionalCurrency: String
) {
  fxCardUpdate (
    id: $id,
    structure: $structure
    direction: $direction
    tradeDate: $tradeDate
    fxCodeId: $fxCodeId
    notional: $notional
    notionalCurrency: $notionalCurrency
  ) {
    id
    price
    status
    limit
  }
}`;

export const updateFxCardMutation = (
  structure,
  {
    id,
    direction,
    tradeDate,
    notionalCurrency,
    notional,
  }) => {
  const variables = {
    structure,
    id,
    direction,
    tradeDate,
    notionalCurrency,
    notional,
  };
  return apolloClient.mutate({
    mutation: PRICING_FX_UPDATE_PRICE_MUTATION,
    variables,
  })
    .then(response => response.data.fxCardUpdate);
}


const PRICING_FX_REQUEST_PRICE_MUTATION = gql`mutation fxRequestPriceMutation(
  $id: String!
) {
  fxRequestPrice (
    id: $id
  ) {
    id
    status,
  }
}`;

export const requestPriceMutation = ({
    id,
  }) => {
  return apolloClient.mutate({
    mutation: PRICING_FX_REQUEST_PRICE_MUTATION,
    variables: {
      id,
    },
  }).then(response => response.data.fxRequestPrice);
}

const PRICING_FX_TRADE_MUTATION = gql`mutation fxTradeMutation(
  $id: String!
  $price: Float
  $quoteId: String
) {
  fxTrade (
    cardId: $id
    price: $price
    quoteId: $quoteId
  ) {
    id
    status,
  }
}`;

export const tradeMutation = ({
    id,
    price,
    quoteId
  }) => {
  return apolloClient.mutate({
    mutation: PRICING_FX_TRADE_MUTATION,
    variables: {
      id,
      price,
      quoteId
    },
  }).then(response => response.data.fxTrade);
}
