import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import { miniAccumulatorCardUpdate } from "redux/actions/accumulator-mini";
import { findPricinigMiniCard } from "redux/epics/price/mini/limitations";
import { getCardId } from "redux/epics/price/structures/vanilla/utils";
import { getScheduleDate } from "redux/queries/pricing-mini";

interface FormDate {
  startDate: string | undefined;
  endDate: string | undefined;
  structureExpiryDate: string | undefined;
}

const useUpdateScheduleDate = (
  { startDate, endDate, structureExpiryDate }: FormDate,
  form: string,
) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.accumulatorMini.cards);
  const {
    pricingCardId: cardId,
    id,
    underlyingId,
    selectedUnderlying,
  } = findPricinigMiniCard(cards, getCardId({ form })) || {};

  const getFixings = async () => {
    const variables = {
      cardId,
      underlyingId,
      underlyingDataId: selectedUnderlying?.id,
      startDate: startDate || null,
      endDate: endDate || structureExpiryDate,
    };

    dispatch(
      miniAccumulatorCardUpdate({
        id,
        isFixings: false,
      }),
    );

    if (variables.endDate) {
      const data = await getScheduleDate(variables);
      if (data) {
        const {
          size = null,
          frequency = null,
          calendar = [],
          dailySize = null,
        } = data;
        dispatch(change(form, "scheduleDates", `${size} ${frequency}`));
        dispatch(change(form, "msaFixings", size));
        dispatch(change(form, "calendar", calendar));
        dispatch(
          miniAccumulatorCardUpdate({
            id,
            dailySize,
          }),
        );
      }
    }
    dispatch(
      miniAccumulatorCardUpdate({
        id,
        isFixings: true,
      }),
    );
  };

  useEffect(() => {
    getFixings();
    // update schedule date when start date, end date or structure expiry date changes
  }, [startDate, endDate, structureExpiryDate]);
};

export default useUpdateScheduleDate;
