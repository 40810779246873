import logger, { USER_ID } from 'utils/Logger';
import { ACCOUNT_DASHBOARD_REQUEST_STATUS, AUTH_LOGOUT, AUTH_SET_ACCOUNT, USER_ONLINE_STATUS, UNWIND_STATUS, AUTH_SET_TYPE, AUTH_JWT_LOADING, AUTH_JWT_AUTHENTICATED } from 'redux/actions/auth';
import { initialAuthState, STORAGE_NAME } from 'providers/JwtProvider';
import { logout, IS_AUTH0 } from 'providers/AuthProvider';
import { closeWsLink } from 'providers/ApolloClientProvider';

const AUTH_INIT_STATE_EMPTY = {
  account: null,
  online: true,
  unwindHistoryId: null,
  isAuth0: localStorage.getItem(IS_AUTH0) === 'true' || false,
  jwtState: {
    ...initialAuthState
  }
};

const AUTH_INIT_STATE = AUTH_INIT_STATE_EMPTY;

export const setAccount = (_id) => {
  localStorage.setItem(USER_ID, _id);
  logger.setIdentity();
  logger.startApp();
};

export default function authReducer(state = AUTH_INIT_STATE, action = {}) {
  switch (action.type) {
    case AUTH_SET_ACCOUNT:
      setAccount(action.payload._id);
      return { ...state, account: action.payload };

    case ACCOUNT_DASHBOARD_REQUEST_STATUS:
      return {
        ...state,
        account: {
          ...state.account,
          dashboardRequestStatus: action.payload
        }
      }

    case USER_ONLINE_STATUS:
      return { ...state, online: action.payload }

    case AUTH_LOGOUT:
      closeWsLink();
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(STORAGE_NAME);
      logout().catch((error) => logger.error(error));
      
      return {
        ...state,
        account: null,
        online: true,
        unwindHistoryId: null,
        jwtState: {
          isAuthenticated: false,
          isLoading: false,
        }
      };

    case UNWIND_STATUS:
      return { ...state, unwindHistoryId: action.payload }

    case AUTH_SET_TYPE:
      return { ...state, isAuth0: Boolean(action.payload) };

    case AUTH_JWT_LOADING:
      return {
        ...state,
        jwtState: {
          ...state.jwtState,
          isLoading: Boolean(action.payload)
        }
      };

    case AUTH_JWT_AUTHENTICATED:
      return {
        ...state,
        jwtState: {
          ...state.jwtState,
          isAuthenticated: Boolean(action.payload)
        }
      };

    default:
      return state;
  }
}

