import { NEW_OPTION_STATE, STEPS, DEFAULT_INSTRUMENT, INSTRUMENT_FORM_TYPE, OPTION_STATUS_PENDING, OPTION_STATUS_FINISH } from 'pages/booking/constants';
import { getOptionId, getLegTitle } from 'pages/booking/helpers';
import { BOOKING_OPTION_ADD, BOOKING_OPTION_DELETE, BOOKING_OPTION_SELECT, BOOKING_OPTION_NEXT, BOOKING_OPTION_BACK, BOOKING_OPTION_STEP, BOOKING_RESET, BOOKING_ASYNC_ERROR, BOOKING_INSTRUMENT_CHANGE } from 'redux/actions/booking';

const id = getOptionId();
const BOOKING_STATE_INIT = {
  activeStep: 0,
  steps: [...STEPS.get(DEFAULT_INSTRUMENT)],
  active: id,
  options: [id],
  [id]: { ...NEW_OPTION_STATE },
  asyncErrors: {},
}

export default function bookingReducer(state = BOOKING_STATE_INIT, action) {
  switch (action.type) {
    case BOOKING_OPTION_ADD:
      const id = getOptionId();
      return {
        ...state,
        active: id,
        activeStep: 0,
        steps: [...STEPS.get(DEFAULT_INSTRUMENT)],
        options: [...state.options, id],
        [id]: { ...NEW_OPTION_STATE },
        asyncErrors: {}
      };
    case BOOKING_OPTION_DELETE: {
      let { [action.payload]: deletedOption, options, steps, active, asyncErrors, ...newSate } = state;
      options.splice(options.indexOf(action.payload), 1);
      if (active === action.payload) {
        active = options[0];
        const { data: { [INSTRUMENT_FORM_TYPE]: { instrument } } } = newSate[active];
        steps = [...STEPS.get(instrument)];
        asyncErrors = {};
      }
      return { ...newSate, active, options, steps, asyncErrors };
    }
    case BOOKING_OPTION_SELECT: {
      const { data: { [INSTRUMENT_FORM_TYPE]: { instrument } } } = state[action.payload];
      const steps = [...STEPS.get(instrument)];
      return { ...state, active: action.payload, steps, activeStep: 0, asyncErrors: {} };
    }
    case BOOKING_OPTION_NEXT: {
      const { activeStep, steps, active, asyncErrors } = state;
      const option = state[active];
      const { data, reset } = action.payload;
      const { form, ...formData } = data;
      const step = activeStep + 1 <= steps.length ? activeStep + 1 : activeStep;

      const resetForms = reset.reduce((a, v) => ({ ...a, [v]: {} }), {});

      const isNewInstrument = form === INSTRUMENT_FORM_TYPE && option.data[INSTRUMENT_FORM_TYPE].instrument !== formData.instrument;
      const leg = isNewInstrument
      ? {
        status: OPTION_STATUS_PENDING,
        data: {
          [form]: { ...formData },
          ...resetForms,
        }
      }
      : {
        ...option,
        status: step === steps.length ? OPTION_STATUS_FINISH : OPTION_STATUS_PENDING,
        data: {
          ...option.data,
          [form]: { ...formData },
          ...resetForms,
        }
      };
      leg.title = getLegTitle(leg.data);

      return {
        ...state,
        activeStep: step,
        [active]: leg,
        asyncErrors: {
          ...asyncErrors,
          steps: asyncErrors.steps ? asyncErrors.steps.filter(item => {
            return item !== form && (!reset.length || !reset.includes(item));
          }) : [],
          errors: {
            ...asyncErrors.errors,
            [form]: {},
            ...resetForms,
          }
        }
      };
    }
    case BOOKING_OPTION_BACK:
      const { activeStep } = state;
      return { ...state, activeStep: activeStep > 0 ? activeStep - 1 : activeStep };
    case BOOKING_OPTION_STEP: {
      const { activeStep, active, [active]: { status } } = state;
      if (status === OPTION_STATUS_FINISH || activeStep > action.payload) return { ...state, activeStep: action.payload };
      return { ...state };
    }
    case BOOKING_ASYNC_ERROR: {
      const { errors: { activeStep, ...asyncErrors }, data } = action.payload;
      const { form, ...formData } = data;
      const { active } = state;
      const option = state[active];

      return {
        ...state,
        activeStep,
        asyncErrors,
        [active]: {
          ...option,
          data: {
            ...option.data,
            [form]: { ...formData }
          }
        }
      };
    }
    case BOOKING_RESET:
      return { ...BOOKING_STATE_INIT };
    case BOOKING_INSTRUMENT_CHANGE:
      return {
        ...state,
        steps: [...(STEPS.get(action.payload) || STEPS.get(DEFAULT_INSTRUMENT))],
      }
    default:
      return state;
  }
}
