import React, { ReactElement } from 'react';
import Bundle from 'pages/Bundle';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { FX_PAGE_TITLE } from 'pages/fx/constants';

interface FxPageProps { };

export const FxPage: React.FC<FxPageProps> = (props): ReactElement => (
  <ErrorBoundary>
    <Bundle title={FX_PAGE_TITLE} load={() => import('./FxLayout')}>
      {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
    </Bundle>
  </ErrorBoundary>
);
export default FxPage;