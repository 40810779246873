import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuth0Selector } from 'providers/AuthProvider';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import { themeMetaDataSelector } from 'components/theme/utils';
import Bundle from 'pages/Bundle';

export const LoginPage = props => {
  const { meta, isAuth0 } = props;
  const { defaultTitle: titleSuffix, loginTitle: title, loginDescription: description } = meta || {};
  const layout = isAuth0 ? './Auth0Login' : './JwtLogin';
  return (
    <ErrorBoundary>
      <PageMeta
        title={title}
        description={description}
        titleSuffix={titleSuffix}
      />
      <Bundle title="Log In Page" load={() => import(`${layout}`)}>
        {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
      </Bundle>
    </ErrorBoundary>
  )
};

LoginPage.propTypes = {
  meta: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  meta: themeMetaDataSelector(state),
  isAuth0: isAuth0Selector(state),
});

export default connect(mapStateToProps)(LoginPage);
