import apolloClient from 'providers/ApolloClientProvider';
import gql from 'graphql-tag';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { PRICING_HISTORY_ACCUMULATOR_QUERY_FRAGMENT } from 'redux/queries/query';

export const GET_PRICING_BY_TAG = gql`query pricingHistoryShareQuery(
  $tag: String!
) {
  pricingHistoryShare (
    tag: $tag
  ) {
    ... on PricingAccumulatorHistoryType {
      id
      ...priceAccumulatorFragment
    }
  }
}
${PRICING_HISTORY_ACCUMULATOR_QUERY_FRAGMENT}`;

export const getAccumulatorByTag = (tag) =>
  apolloClient.query({
    query: GET_PRICING_BY_TAG,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
    variables: { tag }
  })
  .then(({ data }) => data.pricingHistoryShare)
