import gql from 'graphql-tag';
import apolloClient from 'providers/ApolloClientProvider';
import moment from 'moment';
import momentBusinessDays from 'moment-business-days';

import { DATE_FORMAT_INPUT } from 'constants.js';

export const QUERY_BANK_HOLIDAYS = gql`
  query getBankHolidaysQuery(
    $underlyingIds: [String],
    $underlyingType: PricingUnderlyingTypesEnum
    $from: DateTime
    $to: DateTime
  ) {
    getBankHolidays(underlyingIds: $underlyingIds, underlyingType: $underlyingType, from: $from, to: $to)
  }`;

const QUERY_ALL_BANK_HOLIDAYS = gql`
  query getBankHolidaysQuery(
    $underlyingIds: [String],
    $underlyingType: PricingUnderlyingTypesEnum
  ) {
    getBankHolidays(underlyingIds: $underlyingIds, underlyingType: $underlyingType)
  }`;

export const BANK_HOLIDAYS_TYPE = {
  ACCUMULATORS: 'accumulatorUnderlyings',
  EQ_UNDERLYING: 'eqUnderlyingData'
}

export const getBankHolidays = (underlyingIds = [], underlyingType = BANK_HOLIDAYS_TYPE.ACCUMULATORS) => {
  return apolloClient.query({
    query: QUERY_ALL_BANK_HOLIDAYS,
    variables: {
      underlyingIds,
      underlyingType
    }
  }).then(({ data = {} }) => {
    const { getBankHolidays } = data;
    const { holidays, dates } = getBankHolidays.reduce(({ holidays, dates }, date) => {

      // to show holidays only based on UK time we have to apply utc offset to the user's date
      const offsetToAdd = moment(date).utcOffset();
      const holiday = moment.utc(date).subtract(offsetToAdd, 'minutes');

      return {
        holidays: [...holidays, holiday.format(DATE_FORMAT_INPUT)],
        dates: [...dates, holiday.toDate()]
      }
    }, {
      holidays: [],
      dates: []
    });

    momentBusinessDays.updateLocale('en-gb', {
      holidays,
      holidayFormat: DATE_FORMAT_INPUT
    });
    return dates;
  })
  .catch((error) => {
    console.error(error);
    return []
  });
};

export const QUERY_FX_BANK_HOLIDAYS = gql`
query getFxBankHolidaysQuery(
  $ccy1: String,
  $ccy2: String,
) {
  getFxBankHolidays(ccy1: $ccy1, ccy2: $ccy2)
}`;

export const getFxBankHolidays = (ccy1 = '', ccy2 = '') => {
  return apolloClient.query({
    query: QUERY_FX_BANK_HOLIDAYS,
    variables: {
      ccy1,
      ccy2
    }
  }).then(({ data = {} }) => {
    const { getFxBankHolidays } = data;
    const { holidays, dates } = getFxBankHolidays.reduce(({ holidays, dates }, date) => {
      const holiday = moment.utc(date).format(DATE_FORMAT_INPUT);
      return {
        holidays: [...holidays, holiday],
        dates: [...dates, moment.utc(holiday, DATE_FORMAT_INPUT).toDate()]
      }
    }, {
      holidays: [],
      dates: []
    });

    momentBusinessDays.updateLocale('en-gb', {
      holidays,
      holidayFormat: DATE_FORMAT_INPUT
    });
    return dates;
  })
  .catch((error) => {
    console.error(error);
    return []
  });
}


