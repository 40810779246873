import apolloClient from 'providers/ApolloClientProvider';
import gql from 'graphql-tag';

const TWIN_WIN_CALCULATE_PRICE_MUTATION = gql`mutation priceTwinWinAutocall (
    $observations: [PriceAutocallScheduleObservations]
    $snowball: Boolean!
    $issuer: String
    $currency: String!
    $underlyings: [String]!
    $maturity: Int!
    $autocall: String!
    $frequency: String!
    $firstObservationIn: String
    $autocallTriggerLevel: Float
    $couponType: String!
    $memoryCoupon: Boolean!
    $couponTriggerLevel: Float
    $solveFor: String!
    $couponLevel: Float
    $reoffer: Float
    $strikeLevel: Float
    $downsideLeverage: Float
    $barrierType: String!
    $barrierLevel: Float
    $stepDown: Boolean!
    $noCouponPaidAtMaturity: Boolean!
    $step: Float
    $schedule: [PriceAutocallScheduleData]
    $strikeDate: DateTime!
    $observationLag: Int!
    $paymentLag: Int!
    $notional: Float!
    $legalShape: String
    $floatingCurve: String
    $spread: Float
    $structure: String
    $couponDirection: String
  ) {
  priceTwinWinAutocall (observations: $observations
      stepDown: $stepDown
      snowball: $snowball
      issuer: $issuer
      currency: $currency
      underlyings: $underlyings
      maturity: $maturity
      autocall: $autocall
      frequency: $frequency
      firstObservationIn: $firstObservationIn
      autocallTriggerLevel: $autocallTriggerLevel
      couponType: $couponType
      memoryCoupon: $memoryCoupon
      couponTriggerLevel: $couponTriggerLevel
      solveFor: $solveFor
      couponLevel: $couponLevel
      reoffer: $reoffer
      strikeLevel: $strikeLevel
      downsideLeverage: $downsideLeverage
      barrierType: $barrierType
      barrierLevel: $barrierLevel
      step: $step
      noCouponPaidAtMaturity: $noCouponPaidAtMaturity
      schedule: $schedule
      strikeDate: $strikeDate
      observationLag: $observationLag
      paymentLag: $paymentLag
      notional: $notional
      legalShape: $legalShape
      floatingCurve: $floatingCurve
      spread: $spread
      structure: $structure
      couponDirection: $couponDirection
    ) {
      reoffer
      couponLevel
      couponPerAnnum
      productAnalysis {
        vega
        delta
        title: bloombergTicker
      }
      productAnalysisAdditional {
        title
        value
      }
      valuationDate
      reportDate
      trackId
      optionId
      resultCurrency
      marketValue
      theta
      rho
      corrDelta
      marketData
      tag
      legalShape
      assets {
        vega
        divRisk
        vega
        gamma
        deltaSpot
        sdTicker
        currency
        fxCorrDelta
      }
      isTrade
      hasSlackId
      issueDate
      maxIssueDate
      notional
      isInternal
    }
}`;

export const submitStructuredProductsTwinWinFormMutation = data => {
  const {
    observations,
    snowball,
    structure,
    issuer,
    currency,
    underlyings,
    maturity,
    autocall,
    frequency,
    firstObservationIn,
    autocallTriggerLevel,
    couponType,
    memoryCoupon,
    couponTriggerLevel,
    solveFor,
    couponLevel,
    reoffer,
    strikeLevel,
    downsideLeverage,
    barrierType,
    barrierLevel,
    stepDown,
    step,
    schedule,
    noCouponPaidAtMaturity,
    strikeDate,
    observationLag,
    paymentLag,
    notional,
    legalShape,
    floatingCurve,
    spread,
    couponDirection,
  } = data;

  return apolloClient.mutate({
    mutation: TWIN_WIN_CALCULATE_PRICE_MUTATION,
    variables: {
      observations,
      snowball,
      structure,
      issuer,
      currency,
      underlyings,
      maturity,
      autocall,
      frequency,
      firstObservationIn,
      autocallTriggerLevel,
      couponType,
      memoryCoupon,
      couponTriggerLevel,
      solveFor,
      couponLevel,
      reoffer,
      strikeLevel,
      downsideLeverage,
      barrierType,
      barrierLevel,
      stepDown,
      step,
      schedule,
      noCouponPaidAtMaturity,
      strikeDate,
      observationLag,
      paymentLag,
      notional,
      legalShape,
      floatingCurve,
      spread,
      couponDirection,
    }
  }).then(({ data = {} }) => {
    const { priceTwinWinAutocall } = data;
    return priceTwinWinAutocall;
  });
};
