import { PartialBy } from "@agile/common-types/build/utils/types";
import ApolloClient, { FetchPolicy } from 'apollo-client';
import Loader from 'components/common/loader/Loader';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { DEBOUNCE_TIME } from 'constants.js';
import { DocumentNode } from 'graphql';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';

interface AutoCompleteProps {
  selectedSingleOption?: boolean;
  selectedActiveSingleOption?: boolean;
  client: ApolloClient<any>;
  query: DocumentNode;
  input: any;
  queryVariables?: (formValues: any, value?: string) => any;
  onInputChangeValue?: (params: { name: string, value?: string, options: Option[] | null }) => void;
  placeHolder?: string;
  label?: string;
  mapResponse: (data: any) => any[];
  field: InputField;
  formValues: any;
  disabled?: boolean;
  fetchPolicy?: FetchPolicy;
  onAutocompleteLoaded?: (options: Option[], input: any) => void;
  queryFilter?: any;
  bgLoaderOverlay?: boolean;
}

interface InputField extends PartialBy<Option, 'value'> {
  e?: string;
}

interface Option {
  name: string;
  value: string;
};

const AutoComplete: React.FC<AutoCompleteProps> = ({
  selectedSingleOption = false,
  selectedActiveSingleOption,
  client,
  query,
  input,
  queryVariables,
  onInputChangeValue,
  placeHolder = 'Please start typing text',
  label,
  mapResponse,
  field,
  formValues,
  disabled = false,
  fetchPolicy = GRAPHQL_NETWORK_ONLY,
  onAutocompleteLoaded,
  queryFilter,
  bgLoaderOverlay,
  ...rest
}) => {

  const [formValuesState, setFormValues] = useState<any>(null);
  const [firstRequest, setFirstRequest] = useState(true);
  const [options, setOptions] = useState<Option[] | null>(null);
  const [isLoading, setLoader] = useState(false);

  const getOptionsQuery = async (formValues: any = {}, value?: string) => {
    let variables = typeof queryVariables === 'function' ? queryVariables(formValues, value) : {};
    if (isObject(variables) && queryFilter) {
      variables['filter'] = queryFilter;
    }

    try {
      const response = await client.query({
        query,
        variables,
        fetchPolicy,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  const _getData = async ({ formValues, value }: { formValues?: any, value?: string } = {}, firstRequest?: boolean) => {
    setLoader(true);
    try {
      const { data } = await getOptionsQuery(formValues, value) || {};
      setResponse(data, firstRequest);
    } catch (e) {
      console.error('Error fetching data:', e);
    }
    setLoader(false);
  }

  const getData = debounce(_getData, DEBOUNCE_TIME);

  const setResponse = (data: any = {}, firstRequestParam: boolean = firstRequest) => {
    const options = typeof mapResponse === 'function' ? mapResponse(data) : [];
    setOptions(options);

    if (firstRequestParam) {
      setFirstRequest(!firstRequestParam);
      if (typeof onAutocompleteLoaded === 'function') {
        onAutocompleteLoaded(options, input);
      }
    }
  }

  const onInputChange = (field: InputField) => {
    const { value } = field;
    if (value) getData({ formValues, value }, false);
  }

  const onChangeFilter = (field: InputField) => {
    const { name, e: value } = field;
    if (typeof onInputChangeValue === 'function') onInputChangeValue({ name, value, options });
    const values = { ...formValuesState, [name]: value };
    setFormValues(values);
  }

  useEffect(() => {
    if (!isEqual(formValues, formValuesState)) {
      setFormValues(formValues);
      getData({ formValues }, firstRequest);
    }
  }, [formValues, formValuesState, firstRequest]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="loader-wrapper">
      <InlineSelectInput
        {...rest}
        {...field}
        disabled={disabled}
        input={input}
        label={label}
        placeHolder={placeHolder}
        options={options}
        justValue={true}
        className="select-border"
        selectedSingleOption={selectedSingleOption && firstRequest && options?.length === 1}
        selectedActiveSingleOption={selectedActiveSingleOption}
        onChangeFilter={onChangeFilter}
        onInputChange={onInputChange}
      />
      {isLoading ? (
        <Fragment>
          <Loader className="loader-bottom" />
          {bgLoaderOverlay && <div className="loader-overlay overlay-card-bg"></div>}
        </Fragment>
      ) : null}
    </div>
  )
};

export default compose(
  memo,
  withApollo,
)(AutoComplete);
