import PageMeta from 'components/common/PageMeta';
import { InlineInput } from 'components/form/InlineInput';
import RadioGroup from 'components/form/inputs/radioGroup/RadioGroup';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { DATE_FORMAT_INPUT, FORM_SUBMIT_SUCCESS_TIMEOUT } from 'constants.js';
import { optionsProvider } from 'pages/price/PriceForm/utils';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Row } from 'reactstrap';
import { compose, bindActionCreators } from 'redux';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';
import { apolloErrors } from 'providers/ApolloClientProvider';
import validate from './CustomPricingRequestFormValidate';
import { CREATE_CUSTOM_PRICING_MUTATION } from './query';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const CUSTOM_PRICING_FORM = '@CUSTOM_PRICING/FORM/REQUEST';

export const OPTIONS = {
  'status': optionsProvider(['indicative', 'live']),
  'type': [
    ...optionsProvider(['consumer', 'producer']),
    {
      value: 'none',
      label: 'Not Applicable'
    }
  ],
  'direction': optionsProvider(['buy', 'sell']),
};

class CustomPricingRequestForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }
  submit = async data => {
    const { mutate } = this.props;
    try {
      let response = await mutate({
        variables: {
          structure: data.cutomRequestStructure,
          status: data.cutomRequestStatus,
          type: data.cutomRequestType,
          underlying: data.cutomRequestUnderlying,
          currency: data.cutomRequestCurrency,
          expiredAt: data.customRequestExpiredAt,
          notional: data.cutomRequestNotional,
          direction: data.cutomRequestDirection,
          structureDetails: data.cutomRequestStructureDetails,
        }
      });
      this.setState({ submitted: true });

      this.runResetFormTimeout();

      return response.data;
    }
    catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  };

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose, reset } = this.props;
    reset(CUSTOM_PRICING_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  closeModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  render() {
    const { pristine, handleSubmit, invalid, submitting, error, intl: { formatMessage } } = this.props;
    const { submitted } = this.state;
    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage('Request successfully sent!', 'You can check the status of your request into the Custom Request page.')} />
        :
        <div className="custom-pricing-request-form">
          <PageMeta title="Custom Pricing Request" />
          <div className="modal-header">
            <div className="modal-title">
              <FormattedMessage id="custom-request.title"
                defaultMessage="Custom Pricing Request"
              />
              <div className="sub-header">
                <FormattedMessage id="custom-request.content"
                  defaultMessage="Please provide as much details as possible"
                />
              </div>
            </div>
          </div>

          <MainErrorMessage error={error} />
          <Form onSubmit={handleSubmit(this.submit)} noValidate>
            <Field
              name="cutomRequestStructure"
              type="text"
              label="Structure"
              placeHolder={formatMessage({
                id: 'form.pricing-request.structure',
                defaultMessage: 'Enter structure'
              })}
              component={InlineInput} />
            <Field
              name="cutomRequestStatus"
              type="text"
              label="Status"
              placeHolder={formatMessage({
                id: 'pricing-request.status',
                defaultMessage: 'Enter status'
              })}
              options={OPTIONS['status']}
              component={RadioGroup} />
            <Field
              name="cutomRequestType"
              type="text"
              label="Type"
              placeHolder={formatMessage({
                id: 'form.pricing-request.type',
                defaultMessage: 'Enter type'
              })}
              options={OPTIONS['type']}
              component={RadioGroup} />
            <Field
              name="cutomRequestUnderlying"
              type="text"
              label="Underlying"
              placeHolder={formatMessage({
                id: 'form.pricing-request.underlying',
                defaultMessage: 'Enter underlying'
              })}
              component={InlineInput} />
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="cutomRequestCurrency"
                  type="text"
                  label="Currency"
                  placeHolder={formatMessage({
                    id: 'form.pricing-request.currency',
                    defaultMessage: 'Enter currency'
                  })}
                  component={InlineInput} />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="customRequestExpiredAt"
                  type="date"
                  label="Expiry Date"
                  placeHolder={formatMessage({
                    id: 'form.pricing-request.expiredAt',
                    defaultMessage: DATE_FORMAT_INPUT
                  })}
                  minLength={10}
                  maxLength={10}
                  component={InlineInput} />
              </Col>
            </Row>
            <Field
              name="cutomRequestNotional"
              type="text"
              label="Notional"
              placeHolder={formatMessage({
                id: 'form.pricing-request.notional',
                defaultMessage: 'Enter notional'
              })}
              component={InlineInput} />
            <Field
              name="cutomRequestDirection"
              type="text"
              label="Direction"
              placeHolder={formatMessage({
                id: 'form.pricing-request.direction',
                defaultMessage: 'Enter direction'
              })}
              options={OPTIONS['direction']}
              component={RadioGroup} />
            <Field
              name="cutomRequestStructureDetails"
              type="textarea"
              label="Structure Details"
              placeHolder={formatMessage({
                id: 'form.pricing-request.structureDetails',
                defaultMessage: 'Enter structure details'
              })}
              component={InlineInput} />

            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.closeModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="submit" color="primary" disabled={pristine || invalid || submitting}>
                <FormattedMessage id="form.custom-pricing.submit"
                  defaultMessage="Submit" />
              </Button>
            </div>
          </Form>
        </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  reset,
}, dispatch);

const reduxFormOptions = {
  form: CUSTOM_PRICING_FORM,
  initialValues: {
    cutomRequestStatus: OPTIONS['status'][0].value,
    cutomRequestType: OPTIONS['type'][0].value,
    cutomRequestDirection: OPTIONS['direction'][0].value
  },
  validate
};

CustomPricingRequestForm.propTypes = {
  reset: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

export default compose(
  graphql(CREATE_CUSTOM_PRICING_MUTATION),
  connect(null, mapDispatchToProps),
  reduxForm(reduxFormOptions),
  injectIntl,
)(CustomPricingRequestForm)
