import { Categorisation, WrapperData } from "@agile/common-types/build/configurableSp/types";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import { isDefined } from "validate.js";
import { CommonConfigurableForm, ConfigurableOwnProps, ParsedStructureData, Structure, Underlying } from "../types";
import {
  getCalculatedValue,
  getStructureLayoutId,
  getValue,
  getWrapperLayout,
  handleDateValue,
  isDateComponent,
  SIMPLE_ARITHMETIC_REGEX,
} from "../utils";
import validateSp from "../validateSp";

export const spFormValueSelector = (name: string) => (state) =>
  getFormValues(name)(state);

export const scheduleSelector = (state) => {
  const { structuredProducts } = state;
  const schedule = structuredProducts?.schedule;
  const floatingLegSchedule = structuredProducts?.floatingLegSchedule;
  if (schedule || floatingLegSchedule) {
    return { ...schedule, ...floatingLegSchedule };
  }
  return undefined;
};

export const getStructureList = (_, props: ConfigurableOwnProps) => {
  return props.structureList || [];
};

export const getWrapperList = (_, props: ConfigurableOwnProps) => {
  return props.wrapperList || [];
};

export const getStructureMap = (_, props: ConfigurableOwnProps) => {
  return props.structuresMap || {};
};

export const selectedStructureSelector = (formName: string) =>
  // @ts-ignore
  createSelector(
    [getSelectedValueSelector(formName, "structure"), getStructureList],
    (selectedStructure, structures) =>
      getStructureLayoutId(structures, selectedStructure),
  );

export const getSelectedValueSelector = (formName: string, formField: string) =>
  createSelector(
    [getFormValues(formName)],
    (formValues: CommonConfigurableForm) => formValues?.[formField],
  );
const flattenLayout = (layouts) =>
  layouts.flatMap((item) => {
    const { compositeArray, ...rest } = item;
    return [
      { ...rest },
      ...(compositeArray?.length
        ? compositeArray.flatMap((child) => [child])
        : []),
    ];
  });

const isDateOrArithmetic = (componentType: string, defaultValue: string, parsedValue: any) =>
  defaultValue.match(SIMPLE_ARITHMETIC_REGEX) ||
  (isDateComponent(componentType) && isNaN(parsedValue));
const isValidCalculatedDate = (componentType: string, defaultValue: string, calculatedValue: any) => 
  isDateComponent(componentType) && calculatedValue !== defaultValue;

export const UNDERLYING_FIELDS = [
  "payoff>underlying>initial-fixing-type",
  "payoff>underlying>initial-fixing-date",
  "payoff>underlying>initial-fixing",
];

const getDefaultValueFromOptions = (options, defaultValue: string) =>
  options.find(
    (option) => option.value === defaultValue || option.label === defaultValue,
  );

export const getInitialValues = (
  structureData: ParsedStructureData,
  structures: Structure[],
  wrapperList: WrapperData[],
  selectedWrapper: string | undefined,
  underlyings: Underlying[],
) => {
  const layout = structureData?.layout || [];
  const wrapperLayout = getWrapperLayout(wrapperList, selectedWrapper);
  const combinedLayouts = [...flattenLayout(layout), ...wrapperLayout];
  const layoutMapping = combinedLayouts.reduce(
    (acc, { id, defaultValue }) => ({ ...acc, [id]: defaultValue }),
    {},
  );
  const underlyingIndices = underlyings.reduce<number[]>((acc, underlying) => {
    const id = underlying.name.split("underlying_")[1];
    if (isDefined(id)) {
      return [...acc, Number(id)];
    }
    return acc;
  }, []);
  const initialValues = combinedLayouts.reduce(
    (result, { id, componentConfig, defaultValue }) => {
      const { options, componentType } = componentConfig;
      if (!UNDERLYING_FIELDS.includes(id)) {
        if (componentType === "custom" && !isDefined(defaultValue)) {
          return result;
        }

        if (isDefined(defaultValue) && options?.length) {
          const defaultSelectedOption = getDefaultValueFromOptions(options, defaultValue);
          if (defaultSelectedOption) {
            result[id] = defaultSelectedOption;
          }
        } else if (!isDefined(defaultValue) && options?.length) {
          result[id] = options[0];
        } else if (isDefined(defaultValue)) {
          const parsedValue = parseInt(defaultValue);
          if (
            typeof defaultValue === "string" &&
            !defaultValue.includes("schedule") &&
            isDateOrArithmetic(componentType, defaultValue, parsedValue)
          ) {
            const calculatedValue = isDateComponent(componentType)
              ? handleDateValue(defaultValue, layoutMapping)
              : getCalculatedValue({ value: defaultValue, formValues: layoutMapping });
            if (
              isDefined(calculatedValue) &&
              (isValidCalculatedDate(componentType, defaultValue, calculatedValue) ||
                !isDateComponent(componentType))
            ) {
              result[id] = calculatedValue;
            }
          } else if (isDateComponent(componentType)) {
            if (!isNaN(parsedValue)) {
              const date = new Date();
              date.setDate(date.getDate() + parsedValue);
              result[id] = date;
            }
          } else if (!isNaN(parsedValue) && componentType !== "text") {
            result[id] = parsedValue;
          } else {
            result[id] = getValue(defaultValue);
          }
        }
      } else if (underlyingIndices.length && isDefined(defaultValue)) {
        underlyingIndices.forEach((underlyingIndex) => {
          if (isDateComponent(componentType)) {
            result[`${id}_${underlyingIndex}`] = handleDateValue(
              defaultValue,
              layoutMapping,
            );
          } else {
            result[`${id}_${underlyingIndex}`] = options?.length
              ? getDefaultValueFromOptions(options, defaultValue)
              : defaultValue;
          }
        });
      }
      return result;
    },
    {},
  );

  return {
    ...initialValues,
    structure: structures[0],
    format: wrapperList[0]
      ? {
          label: wrapperList[0]?.wrapperName,
          value: wrapperList[0]?.wrapperUri,
        }
      : undefined,
  };
};

export const initialValuesSelector = (formName: string) =>
  createSelector(
    [
      getStructureMap,
      getStructureList,
      selectedStructureSelector(formName),
      getWrapperList,
      getSelectedValueSelector(formName, "format"),
      underlyingsSelector,
    ],
    (
      structuresMap,
      structures,
      selectedStructure = "",
      wrapperList,
      selectedWrapper,
      underlyings: Underlying[],
    ) => {
      return getInitialValues(structuresMap[selectedStructure], structures, wrapperList, selectedWrapper?.value, underlyings);
    },
  );

export const underlyingsSelector = createSelector(
  [(state: any) => state?.structuredProducts?.underlyings || []],
  (allUnderlyings): Underlying[] => {
    return allUnderlyings.reduce((result, u) => {
      const uri = u?.value;
      const id = u?.id;
      if (uri) {
        result.push({
          uri,
          id,
          iceTicker: u.iceTicker,
          label: u.label,
          name: u.name,
        });
      }
      return result;
    }, []);
  },
);

export const fieldsSettingsSelector = createSelector(
  [(state: any) => state?.structuredProducts?.fieldsSettings],
  (fieldsSettings) => {
    return fieldsSettings || {};
  },
);

export const initialValidationRulesSelector = (formName: string) =>
  // @ts-ignore
  createSelector(
    [
      getStructureMap,
      getStructureList,
      selectedStructureSelector(formName),
      fieldsSettingsSelector,
      getWrapperList,
      getSelectedValueSelector(formName, "format"),
    ],
    (
      structuresMap,
      structures,
      selectedStructure = "",
      fieldsSettings,
      wrapperList,
      selectedWrapper,
    ) => {
      const structureData =
        typeof selectedStructure === "number"
          ? structuresMap[selectedStructure]
          : structuresMap[structures[0].layoutId];
      const wrapper = wrapperList?.find(
        (wrapper) => wrapper.wrapperUri === selectedWrapper?.value,
      );
      const wrapperFieldValidationRules = wrapper?.fieldValidationRules || [];
      const fieldValidationRules = structureData?.fieldValidationRules || [];
      const combinedLayouts = [
        ...(structureData?.layout || []),
        ...(wrapper?.layout || []),
      ];

      const defaultRules = {
        notional: {
          presence: {
            message: "^Cannot be left blank",
          },
        },
        reoffer: {
          presence: {
            message: "^Cannot be left blank",
          }
        }
      };

      const rules = [...fieldValidationRules, ...wrapperFieldValidationRules].reduce(
        (result, { id, validation }) => {
          if (isDefined(validation)) {
            if (id === "payoff>underlying>underlying-id") {
              return result;
            } else if (
              combinedLayouts?.find(
                (l) =>
                  l.categorisations.includes(Categorisation.Pricing) &&
                  l.id === id,
              )
            ) {
              result[id] = validation;
            }
          }
          return result;
        },
        { ...defaultRules },
      );
      if (fieldsSettings.settings) {
        Object.keys(fieldsSettings.settings).forEach((key) => {
          if (fieldsSettings.settings[key] === false) {
            rules[key] = {
              restricted: true,
            };
          }
        });
      }
      return validateSp(rules);
    },
  );
