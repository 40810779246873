import gql from 'graphql-tag';

export const DISABLE_USER_QUIDE = gql`mutation disableGuideMutation {
  disableGuide
}`;


export const GET_USER_ACCOUNT = gql`query getCurrentUser {
  userData {
    id
    _id
    isSlackTeam
    isSlackId
    firstName
    lastName
    email
    dashboardRequestStatus
    isGuide
    isAcceptedAdditionalTerms: additionalTerms
    clientStatus
    passwordExpirationWarning
    theme {
      images
      styles: css
      texts
    }
    dashboards {
      id
      name
      description
      groupId
      reportId
      refresh
      pages
      powerbiFilter
      slackDatabase {
        id
        referenceNumber
        statement	{
          id
          fileExists
          fileExistsCsv
          referenceNumber
        }
      }
    }
    roles {
      id
      name
    }
    cardsLimit
    greeksDisplay
    isConfigurableSp
    isBulletStrip
    isConfigurableMf
    isPricingBeta
    openTrades {
      total
      showTotalRow
    }
  }
}`;

export const ACCEPT_ADDITIONAL_TERMS_MUTATION = gql`mutation acceptTermsMutation {
  acceptTerms
}`;
