import { ApolloClientContext, ApolloWsContext } from './ApolloContext';
import { defineTabID } from './helpers';
export * from './ApolloClientProvider';
export * from './helpers';


export const closeWsLink = (): void => {
  const wsLink = ApolloWsContext.get();
  if (wsLink && wsLink?.subscriptionClient) wsLink.subscriptionClient.close();
}

export const connectWsLink = (): void => {
  const wsLink = ApolloWsContext.get();
  if (wsLink && wsLink?.subscriptionClient) {
    defineTabID()
    wsLink.subscriptionClient.connect()
  }
}

// redefine new client from Context to use apollo connections outside react components
export default new Proxy({}, {
  get(target, prop, receiver) {
    const client = ApolloClientContext.get();
    return Reflect.get(client, prop, receiver);
  },
});
