import Bowser from 'bowser';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { OperationDefinitionNode } from 'graphql';
import { onError } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import { ApolloLink } from 'apollo-link';
import logger from 'utils/Logger';
import { logout } from 'redux/actions/auth';
import { STORAGE_NAME } from 'providers/JwtProvider';
import { AUTH_WARNING } from 'providers/Auth0Provider';
import store from 'store.js';
import { ApolloClientContext, ApolloWsContext } from './ApolloContext';
import { config } from './../../config';

const REQUEST_RESPONSE_HEADER_TOKEN = 'authorization';

export const httpLink = createHttpLink({ uri: config.API_PROTOCOL + '://' + config.API_HOST + '/graphql' });

export const splitTestHandler = ({ query }) => {
  const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
  return kind === 'OperationDefinition' && operation === 'subscription';
};

export const errorLink = onError(({ networkError, graphQLErrors, operation: { operationName } }) => {
  logger.error('Http errors.', {
    operationName,
    graphQLErrors,
    networkError,
    networkErrorCode: networkError ? (networkError as ServerError).statusCode : null,
  });
  if (graphQLErrors) {
    graphQLErrors.map(({ code = '', message }) => {
      console.log('[GraphQL error]: Message', message);
      if(`${code}`.includes('ACCOUNT.') || code === 401) {
        localStorage.setItem(AUTH_WARNING, message);
      }
    });

    const authError = graphQLErrors.find(({ code }) => code === 401);
    if (authError) {
      const client = ApolloClientContext.get();
      if (client && client?.cache) client.cache.reset();
      const wsLink = ApolloWsContext.get();
      if (wsLink && wsLink?.subscriptionClient) wsLink.subscriptionClient.close();
      store.dispatch(logout());
    }
  }

  if (networkError && (networkError as ServerError).statusCode === 401) {
    localStorage.setItem(AUTH_WARNING, 'Error: user is not logged in');
    const client = ApolloClientContext.get();
    if (client && client?.cache) client.cache.reset();
    const wsLink = ApolloWsContext.get();
    if (wsLink && wsLink?.subscriptionClient) wsLink.subscriptionClient.close();
    store.dispatch(logout());
  } else if (networkError && (networkError as ServerError).statusCode > 401) {
    console.log('Server returned an error');
  }
});

export const apolloErrors = (e) => {
  return e && Array.isArray(e.graphQLErrors) && e.graphQLErrors.length ? e.graphQLErrors : [{ code: 'E1' }]
};

export const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const { response: { headers } } = context;
    if (headers) {
      const token = headers.get(REQUEST_RESPONSE_HEADER_TOKEN);
      if (token) {
        localStorage.setItem(STORAGE_NAME, token);
      }
    };
    return response;
  });
});

export const defineTabID = () => {
  const { userAgent } = window.navigator;
  const { browser, platform } = Bowser.parse(userAgent);
  const iPageTabID = sessionStorage.getItem(config.TAB_ID_KEY);
  if (!iPageTabID) {
    const iLocalTabID = localStorage.getItem(config.TAB_ID_KEY);
    const iPageTabID = iLocalTabID ? Number(iLocalTabID) + 1 : 1;
    localStorage.setItem(config.TAB_ID_KEY, `${iPageTabID}`);
    sessionStorage.setItem(config.TAB_ID_KEY, `${browser.name}_${platform.type}_${iPageTabID}`);
  }
};