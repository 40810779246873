import React, { memo, useEffect, useMemo } from "react";
import moment from 'moment';
import { bindActionCreators, compose } from "redux";
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from "react-redux";
import { Field, Form, getFormValues, reduxForm, change } from "redux-form";
import { withRouter } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import InlineSelectInput from "components/form/inputs/InlineSelectInput";
import InputAnimation from 'components/form/inputs/InputAnimation';
import { ROUTE_PRICING_ORDERS_BULLET_STRIP } from "pages/constants";
import SizePerSettlementInput from 'pages/price/output/asianSwap/AsianSwapPricingOutputCardForm/SizePerSettlementInput';
import { orderInit } from "redux/actions/orders";
import { bulletStripCardsRequestPrice } from "redux/actions/bulletStrip";
import validate from "./validate";
import {
  RECORD_EMPTY,
  CARD_STATUSES,
  DEFAULT_UNIT_TYPE,
} from "constants.js";
import FixingDatesField from './FixingDatesField';


const { REQUESTED, APPROVED, NEW } = CARD_STATUSES;

type EmptyCurrencyOptionType = {
  fxCode: string | null
}

type CurrencyOptionType = {
  value: string,
  label: string,
  fxCode: string | null
  deliverable: boolean,
}

interface BulletStripPricingOutputCardFormOwnProps {
  id: string
  direction: string,
  invalid: boolean,
  isLoading: boolean,
  status: string,
  baseCurrency: string,
  price?: number,
  disableCompo: boolean,
  isError: boolean,
  monthListOptions: OptionType[],
  currencyState: string,
  unitType: string,
  commodityCode: string,
  fxCode: string,
  legs: LegType[],
}
interface BulletStripPricingOutputCardFormDispatchProps {
  cardRequestPrice: typeof bulletStripCardsRequestPrice,
  orderInit: typeof orderInit,
  change: typeof change,
}

type OptionType = {
  label: string,
  value: string,
  deliverable?: boolean,
  fxCode?: string,
}

type LegType = {
  notional: number,
  contractExpiry: string,
  expiryDate: string
}

type CardType = {
  fixingDates: string,
  termCurrency: string,
  baseCurrency: string,
  unit: string,
  unitType: string,
  lastMonth: string,
  legs: LegType[],
  notional: number,
  firstMonth: string,
  monthListOptions: OptionType[],
  payoutCurrency: string,
  fillAllLegs: null|number,
}

interface BulletStripPricingOutputCardFormStateProps {
  form: string,
  formData: CardType,
  currencyOptions: OptionType[],
  unitOptions: OptionType[],
  unitsDisable: boolean,
  initialValues: CardType,
  fxBankHolidays: any[]
}

type BulletStripPricingOutputCardFormProps = BulletStripPricingOutputCardFormOwnProps
  & BulletStripPricingOutputCardFormDispatchProps
  & BulletStripPricingOutputCardFormStateProps;

export const getSelectedCurrency =
  (currencyOptions: CurrencyOptionType[] = [], currency = ""): CurrencyOptionType | EmptyCurrencyOptionType =>
    currencyOptions.find(({ value }) => value === currency) || { fxCode: null };

const checkLegDates = (allLegDates = [], requestInfoDates = []) => {
  if (allLegDates.length !== requestInfoDates.length) {
    return false;
  }
  return allLegDates.every(date => requestInfoDates.includes(date));
}

const useIsPriceRequestValid = ({
  legs = [],
  commodityCode,
  fxCode,
  direction,
  unitType
}: BulletStripPricingOutputCardFormProps, requestInfo) => {

  return useMemo(() => {
    if (!requestInfo) {
      return false;
    }

    const { total, allDates } = legs.reduce((result, leg) => {
      result.total += leg.notional;
      result.allDates.push(moment.utc(leg.contractExpiry).format('YYYY/MM'))
      return result;
    }, { total: 0, allDates: [] })
    const isCommoditySame = commodityCode === requestInfo.commodity;
    const isTotalSizeSame = total === requestInfo.totalSize;
    const isCurrencySame = fxCode == requestInfo.currencyPair; // can be null or undefined
    const isDirectionSame = direction === requestInfo.direction;
    const isUnitTypeSame = unitType === requestInfo.unitType;
    const isDatesSame = checkLegDates(allDates, requestInfo.months);

    return isCommoditySame
      && isTotalSizeSame
      && isCurrencySame
      && isDirectionSame
      && isUnitTypeSame
      && isDatesSame;
  }, [requestInfo, legs, commodityCode, fxCode, direction, unitType])
}

const BulletStripPricingOutputCardForm: React.FC<BulletStripPricingOutputCardFormProps> = (props) => {
  const {
    touch,
    change,
    direction,
    id,
    handleSubmit,
    cardRequestPrice,
    invalid,
    isLoading,
    history,
    orderInit,
    currencyOptions,
    status,
    baseCurrency,
    unitOptions,
    price,
    unitsDisable,
    disableCompo,
    isError,
    formData,
    formData: { firstMonth, payoutCurrency = '' },
    requestInfo,
    monthListOptions = []
  } = props;
  const isPriceRequestValid = useIsPriceRequestValid(props, requestInfo);
  const isCombinedCard = payoutCurrency !== baseCurrency;
  const isNewStatus = status === NEW;
  const isRequestedStatus = status === REQUESTED;
  const isDisabledRequestOffer = isCombinedCard && !price;

  const requestBtnName = direction === "buyer" ? "Offer" : "Bid";
  const trade = () => {
    orderInit({ id });
    history.push(ROUTE_PRICING_ORDERS_BULLET_STRIP);
  };

  const requestPrice = () => {
    cardRequestPrice({ id });
  };

  useEffect(() => {
    touch('termCurrency');
  }, []);

  const unitHandler = (unit) => {
    const { unitType = DEFAULT_UNIT_TYPE } = unitOptions.find(({ value }) => value === unit) || { unitType: DEFAULT_UNIT_TYPE };
    change('unitType', unitType);
  };

  const FIRST_MONTH_OPTIONS = useMemo(() => monthListOptions, [monthListOptions]);
  const LAST_MONTH_OPTIONS = useMemo(() => monthListOptions.filter(({ value }) => !firstMonth || moment(value).diff(firstMonth) >= 0), [monthListOptions, firstMonth]);

  const isPriceMismatch = (!isCombinedCard || status === APPROVED) && !isPriceRequestValid && isNumber(price);

  const tradeBtnName = isCombinedCard
    ? !isPriceMismatch ? "Trade" : 'Mismatch'
    : direction === "buyer"
      ? "Request Offer"
      : "Request Bid";

  return (
    <Form id={`form-card-swap-${id}`} onSubmit={handleSubmit} noValidate>
      <Row xs={0} className="pr-0 pl-3">
        <Col className="pl-0" >
          <div className="position-relative h1 d-inline-block">
            {isNewStatus ? RECORD_EMPTY : isNumber(price) ?
              <FormattedNumber
                value={price}
                minimumFractionDigits={0}
                maximumFractionDigits={4} />
              : RECORD_EMPTY}
            <InputAnimation value={price} active={true} />
          </div>
        </Col>
        <Col className="pl-0 col-currency">
          <Field
            name="termCurrency"
            label={
              <FormattedMessage
                id="form.price.fields.payout-currency.label"
                defaultMessage="Currency"
              />
            }
            justValue={true}
            clearable={false}
            showError={true}
            component={InlineSelectInput}
            options={disableCompo ? currencyOptions?.filter(i => isNull(i.fxCode)) : currencyOptions}
            forceDisabled={!currencyOptions}
            preselectIfOneOption={true}
            className={{
              'form-control-force-disabled': false,
              'field-currency': true
            }}
          />
        </Col>
      </Row>
      <Row xs={2} className="pr-0 pl-3">
        <Col className="pl-0">
          {FIRST_MONTH_OPTIONS && FIRST_MONTH_OPTIONS.length
            ? <Field
              name="firstMonth"
              label="First Contract"
              component={InlineSelectInput}
              justValue={true}
              clearable={false}
              showError={true}
              preselectIfOneOption={true}
              options={FIRST_MONTH_OPTIONS}
            /> : <div className="form-control-label">
              <FormattedMessage
                id="swap/no-data"
                defaultMessage="There are no swaps available at the moment"
              />
            </div>}

        </Col>
        <Col className="pl-0">
          {LAST_MONTH_OPTIONS && LAST_MONTH_OPTIONS.length
            ? <Field
              name="lastMonth"
              label="Last Contract"
              component={InlineSelectInput}
              justValue={true}
              clearable={false}
              showError={true}
              preselectIfOneOption={true}
              options={LAST_MONTH_OPTIONS}
            /> : <div className="form-control-label">
              <FormattedMessage
                id="swap/no-data"
                defaultMessage="There are no swaps available at the moment"
              />
            </div>}
        </Col>
        <Col className="pl-0">
          <SizePerSettlementInput
            label="Total Size"
            formData={formData}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="unit"
            label="Unit"
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            onChange={unitHandler}
            disabled={unitsDisable}
            options={unitOptions}
          />
        </Col>
        <Col className="pl-0">
          <FixingDatesField
            label="Fixing Dates"
            formData={formData}
          />
        </Col>
        <Col className="pb-3 pl-0">
          {isPriceMismatch && <UncontrolledTooltip placement="top" target={`bullet-strip-submit-btn`}>
            <FormattedMessage
              id="price.bullet-strip.priceTooltip"
              defaultMessage="Price mismatch, please reload agile" />
          </UncontrolledTooltip>}
          {!isCombinedCard || status === APPROVED ? (
            <Button
              id="bullet-strip-submit-btn"
              type="button"
              className={
                "w-100 px-1 btn-input-h m-t-label text-uppercase" +
                (direction === "buyer" ? " btn-buy" : " btn-sell")
              }
              onClick={trade}
              disabled={
                isLoading || invalid || isDisabledRequestOffer || isError || !isPriceRequestValid
              }
            >
              {!invalid && isLoading
                ? "Loading..."
                : tradeBtnName}
            </Button>
          ) : (
            <Button
              type="button"
              className="w-100 px-1 btn-input-h m-t-label text-uppercase btn-primary"
              onClick={requestPrice}
              disabled={isLoading || invalid || isRequestedStatus || isError}
            >
              {!invalid && isLoading
                ? "Loading..."
                : status === REQUESTED
                  ? `Requested ${requestBtnName}`
                  : `Request ${requestBtnName}`}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
}

export const PRICINIG_BULLET_STRIP_FORM_OUTPUT = "@PRICINIG/BULLET_STRIP/FORM_OUTPUT";
export const swapFormCardName = (id) => `${PRICINIG_BULLET_STRIP_FORM_OUTPUT}_${id}`;
export const swapFormValuesSelector = (id, state) =>
  getFormValues(swapFormCardName(id))(state);

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    quotedCurrency: baseCurrency,
    termCurrency,
    currencyState = {},
    fxBankHolidays = [],
    unit,
    unitType,
    units: unitOptions,
    firstMonth,
    lastMonth,
    legs,
    quantity,
    monthListOptions = [],
    fillAllLegs = null,
  } = ownProps;

  const currencyList: CurrencyOptionType[] = currencyState[baseCurrency]
    ? currencyState[baseCurrency]
    : [
      {
        label: baseCurrency,
        value: baseCurrency,
        deliverable: false,
        fxCode: null,
      },
    ];

  // TODO: remove 388-391 and rename currencyList (376) when implementing USD convertation for bullet strip
  let currencyOptions: CurrencyOptionType[] = currencyList;
  if (baseCurrency === 'USc') {
    currencyOptions = currencyList?.filter(i => i.value !== 'USD');
  };

  return {
    form: swapFormCardName(id),
    formData: swapFormValuesSelector(id, state) || {},
    currencyOptions,
    unitOptions,
    unitsDisable: unitOptions.length <= 1,
    initialValues: {
      fixingDates: 'Standard',
      termCurrency,
      baseCurrency,
      unit,
      unitType,
      lastMonth,
      legs: legs && legs.length ? legs.map(({ notional, contractExpiry, expiryDate }) => ({ notional, contractExpiry, expiryDate })) : [],
      notional: quantity,
      firstMonth,
      monthListOptions,
      fillAllLegs,
    },
    fxBankHolidays,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cardRequestPrice: bulletStripCardsRequestPrice,
      orderInit,
      change,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    validate,
    shouldValidate: () => true,
  }),
  withRouter,
  memo
)(BulletStripPricingOutputCardForm);
