export type CustomContext = {
  provider: (value: any) => any,
  get: () => any,
}

export const createContext = (defaultValue: any): CustomContext => {
  const context = {
    currentValue: defaultValue
  }

  return {
    provider: (value: any) => context.currentValue = value,
    get: () => context.currentValue
  }
};