import React, { ReactNode, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { themeNameSelector } from 'components/theme/utils';
import { Auth0Adapter } from './Auth0Adapter';
import { config } from './../../config';

const { auth0 } = config;

interface CustomAuth0ProviderProps {
  children: ReactNode,
  themeName: string,
}

const CustomAuth0Provider: React.FC<CustomAuth0ProviderProps> = ({
  children,
  themeName,
}): ReactElement => {

  if (!auth0.domain || !auth0.clientId) {
    throw new Error("Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables");
  }

  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        scope: auth0.scope,
        redirect_uri: window.location.origin,
        audience: auth0.audience,
        theme_name: themeName,
        source: 'agile'
      }}
    >
      <Auth0Adapter>
        {children}
      </Auth0Adapter>
    </Auth0Provider>
  );
}

const customAuth0ProviderMapStateToProps = state => ({
  themeName: themeNameSelector(state),
});

export default connect(
  customAuth0ProviderMapStateToProps
)(CustomAuth0Provider);