import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavLink as Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logout } from 'redux/actions/auth';
import { toursWelcome } from 'redux/actions/tours';
import { ROUTE_PRICING, ROUTE_PROFILE } from 'pages/constants';
import UserNameComponent from '../../user/UserNameComponent';
import { withApollo } from 'react-apollo';
import { bindActionCreators, compose } from 'redux';


export const toggleOpen = function () {
  this.setState({ isOpen: !this.state.isOpen });
}

class ProfileDropdown extends PureComponent {
  state = {
    isOpen: false,
  }

  logout = () => {
    const { client, logout } = this.props;
    client.cache.reset()
    logout();
  }

  toggle = toggleOpen.bind(this);

  toursWelcome = () => {
    const { toursWelcome } = this.props;
    toursWelcome(true);
  }

  render() {
    const { location: { pathname } } = this.props;
    const showTour = pathname.indexOf(ROUTE_PRICING) >= 0;
    return (
      <Dropdown key="headerDropDown" isOpen={this.state.isOpen} toggle={this.toggle} className="header-dropdown">
        <DropdownToggle nav aria-label="Profile menu" >
          <UserNameComponent className="header-username"/>
        </DropdownToggle>
        <DropdownMenu right>
          <span className="dropdown-arrow"></span>
          <div className="dropdown-menu-block">
            <DropdownItem tag={Link} to={ROUTE_PROFILE}>
              <FormattedMessage id="logged.header.user-menu.profile"
                defaultMessage="Profile" />
            </DropdownItem>
            {showTour ?
              <DropdownItem onClick={this.toursWelcome}>
                <FormattedMessage id="logged.header.user-menu.user-guides"
                  defaultMessage="User Guide" />
              </DropdownItem>
              :
              null}
            <DropdownItem onClick={this.logout}>
              <FormattedMessage id="logged.header.user-menu.log-out-message"
                defaultMessage="Log out" />
            </DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ProfileDropdown.propTypes = {
  toursWelcome: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ logout, toursWelcome }, dispatch);

export default compose(
  withApollo,
  connect(null, mapDispatchToProps),
)(ProfileDropdown);
