import {
  ComponentOptions,
  Layout,
  Rule,
  Update,
  WrapperData,
} from "@agile/common-types/build/configurableSp/types";
import { InlineSelectInput } from "@agile/react-components";
import moment from "moment-business-days";
import { ScheduleType } from "pages/structured-products/components/output/schedule/v2/types";
import { Dispatch, SetStateAction } from "react";
import { isDefined } from "validate.js";
import FirstObservationIn from "../../pages/structured-products/components/forms/FirstObservationIn/FirstObservationIn";
import FloatingCurve from "../../pages/structured-products/components/forms/FloatingCurve/FloatingCurve";
import Frequency from "../../pages/structured-products/components/forms/Frequency/Frequency";
import { ConfigurableUnderlyings } from "../../pages/structured-products/components/forms/underlyings/Underlyings";
import { UNDERLYING_FIELDS } from "./selectors/selectors";
import { FormValue, ParsedStructureData } from "./types";

// key - defined in custom config in db
// legalShape is value mapped from resolve (graphql does not like hyphens)
export const CUSTOM_OPTIONS = {
  "legal-shapes": "legalShapes",
};

export const getCustomComponentsProps = (
  name: string,
  id: string,
  customOptionsMap: Record<string, ComponentOptions> = {},
  onChange: (evt, value: any) => void,
  formName: string,
  changeField: (formName: string, field: string, value: any) => void,
  label: string | undefined,
) => {
  switch (name) {
    case "underlyings": {
      return {
        name: id,
        component: ConfigurableUnderlyings,
        total: 1,
        showBottomPadding: false,
        addUnderlyingImmediately: true,
        formName,
        isForConfigurableSP: true,
        onUnderlyingChanged: onChange,
        label,
      };
    }
    case "legal-shapes": {
      const key = CUSTOM_OPTIONS[name];

      return {
        name: id,
        component: InlineSelectInput,
        options: customOptionsMap[key],
        onChange,
        clearable: false,
      };
    }
    case "floatingCurve": {
      const optionsMap = customOptionsMap["floatingCurve"];
      return {
        name: id,
        component: FloatingCurve,
        optionsMap,
        formName,
        changeField,
        onChange,
      };
    }
    case "frequency": {
      return {
        name: id,
        component: Frequency,
        formName,
        changeField,
      };
    }
    case "firstObservationIn": {
      return {
        name: id,
        component: FirstObservationIn,
        formName,
        changeField,
      };
    }
    case "issuer": {
      if (!customOptionsMap[name]?.length) {
        return undefined;
      }
      const options = customOptionsMap[name];
      return {
        name: id,
        component: InlineSelectInput,
        options,
        onChange,
        clearable: false,
      };
    }
    default:
      // handle custom data from static service
      if (customOptionsMap[name]) {
        const options = customOptionsMap[name];
        return {
          name: id,
          component: InlineSelectInput,
          options,
          onChange,
          clearable: false,
        };
      }

      return undefined;
  }
};

export type FieldValueMapping = { [field: string]: number | string | FormValue };

export interface UpdateValuesParams {
  formUpdate: any;
  fieldName: string;
  formValues: FieldValueMapping;
  rules: Rule[];
  onChangeField: (field: string, value: any) => void;
  setVisible: (visibleMap: Record<string, boolean>) => void;
  setReadOnly: (readOnlyMap: Record<string, boolean>) => void;
  activeRules: Rule[];
  setActiveRules: Dispatch<SetStateAction<UpdateValuesParams["activeRules"]>>;
  layout: ParsedStructureData["layout"];
  updateFormOptions?: (id: string, options?: string[]) => void;
}
/**
 *  This regex will match <variable or whole/decinal numbers> < +, -, *, / or % > <variable or whole/decinal numbers>
 *  e.g.
 *      1 / $payoff>underlying>strike-level
 *      $payoff>underlying>strike-level + 2
 *      $payoff>underlying>strike-level * $payoff>redemption>gearing
 */
export const SIMPLE_ARITHMETIC_REGEX =
  /^((?:\$?\w+(?:-+\w+)*>?)+|\d*\.?\d*)\s*([-+\/*%])\s*((?:\$?\w+(?:-+\w+)*>?)+|\d*\.?\d*)$/;
const FORM_INDEX_REGEX = /^(.*?)(?:\[(\d+)\])?$/;

export const getFormField = (value: string) => {
  const formValue = (value?.charAt(0) === "$" ? value.split("$")?.[1] : value)?.trim();
  const formFieldMatches = formValue?.match(FORM_INDEX_REGEX);
  if (formFieldMatches) {
    const [_, fieldNameWithoutIndex, index] = formFieldMatches;
    if (fieldNameWithoutIndex && isDefined(index)) {
      return `${fieldNameWithoutIndex}_${Number(index) + 1}`;
    }
  }
  return formValue;
};

type Formatter = (value: string | number) => string | number;

export const getFormValue = (
  formField: string | undefined,
  formValues: FieldValueMapping,
  formatter?: Formatter,
) => {
  const formValue = formField ? formValues[getFormField(formField)] : undefined;
  const displayValue = formValue?.label || formValue;
  if (typeof displayValue === "string" && displayValue.includes("today")) {
    return moment().format("YYYY-MM-DD");
  }
  const formattedValue = formatter ? formatter(displayValue) : displayValue;
  return isDefined(formattedValue) && !isNaN(formattedValue)
    ? Number(formattedValue)
    : formattedValue;
};

const getValueForCalculation = (
  value: string,
  formValues,
  fieldName?: string,
  formUpdate?: string,
  formatter?: Formatter,
) => {
  const numValue = Number(value);
  const formField = getFormField(value);
  if (isDefined(formUpdate) && formField === fieldName && !isNaN(Number(formUpdate))) {
    return Number(formUpdate);
  }

  return isNaN(numValue) && isDefined(formField)
    ? getFormValue(formField, formValues, formatter)
    : numValue;
};

// Match the content inside parentheses after "workday"
const WORKDAY_REGEX = /workday\(([^,]+),([^,]+)\)/;
const INDEX_REGEX = /\[(\d+)\]/;

const getDateField = (formValues, dateField: string) => {
  if (dateField.includes("payoff>underlying>initial-fixing-date")) {
    const dates = Object.keys(formValues)
      .filter((key) => key.includes("payoff>underlying>initial-fixing-date_"))
      .sort();
    const index = dateField.match(INDEX_REGEX);
    if (dateField === "payoff>underlying>initial-fixing-date") {
      return dates[0];
    }
    return index ? dates[index[1]] : dateField;
  }
  return dateField;
};
const SCHEDULE_INDEX_FIELD_REGEX = /\[?(\d*)\]?\[([^\]]+)\]/;

const getScheduleDate = (
  schedule,
  fieldName: string,
  scheduleType: ScheduleType,
  index: number | undefined,
) => {
  const scheduleData = schedule[scheduleType];
  if (scheduleData) {
    const selectedIndex = index ?? scheduleData.length - 1;
    const dateValue = scheduleData[selectedIndex]?.[fieldName];
    return dateValue ? moment(dateValue).format("YYYY-MM-DD") : undefined;
  }
  return undefined;
};

const getScheduleIndex = (value:string, indexMatched: number | undefined, schedule)=> value.includes("last") ? schedule.length - 1 : indexMatched

export const handleDateValue = (value: string, formValues, schedule?: any) => {
  if (value) {
    if (value.includes("today")) {
      return moment().format("YYYY-MM-DD");
    }

    const matches = value.toLowerCase().match(WORKDAY_REGEX);

    if (value.includes("workday")) {
      if (matches?.length === 3) {
        const [_, startDateField, offsetField] = matches;
        const offset = getFormValue(offsetField, formValues);
        const startDate = getFormValue(
          getDateField(formValues, startDateField),
          formValues,
        );
        if (startDate && isDefined(offset) && !isNaN(offset)) {
          const newDate = moment(startDate).businessAdd(offset);
          if (newDate.isValid()) {
            return newDate.format("YYYY-MM-DD");
          }
        }
      }
      return null;
    }

    if (value.includes("schedule")) {
      if (schedule) {
        const match = value.match(SCHEDULE_INDEX_FIELD_REGEX);
        if (match) {
          const fieldName = match[2];
          const indexMatched = value.includes("first") ? 0 : Number(match[1]);
          if (value.includes(ScheduleType.FLOATING)) {
            return getScheduleDate(
              schedule,
              fieldName,
              ScheduleType.FLOATING,
              getScheduleIndex(value, indexMatched, schedule[ScheduleType.FLOATING]),
            );
          }
          if (value.includes(ScheduleType.COUPON)) {
            return getScheduleDate(
              schedule,
              fieldName,
              ScheduleType.COUPON,
              getScheduleIndex(value, indexMatched, schedule[ScheduleType.COUPON]),
            );
          }
          if (value.includes(ScheduleType.EARLY_TERM)) {
            return getScheduleDate(
              schedule,
              fieldName,
              ScheduleType.EARLY_TERM,
              getScheduleIndex(value, indexMatched, schedule[ScheduleType.EARLY_TERM]),
            );
          }
        }
      }
      return undefined;
    }
  }
  return getValue(value);
};
interface CalculatedValue {
  value: string;
  formValues: FieldValueMapping;
  fieldName?: string;
  formUpdate?: string;
  formatter?: Formatter;
}

export const getCalculatedValue = ({
  value,
  formValues,
  fieldName,
  formUpdate,
  formatter,
}: CalculatedValue) => {
  const formValue = getFormValue(value, formValues);
  if (isDefined(formValue)) {
    return formValue;
  }
  const parsedValue = value.match(SIMPLE_ARITHMETIC_REGEX);
  if (parsedValue) {
    const number1 = getValueForCalculation(
      parsedValue[1],
      formValues,
      fieldName,
      formUpdate,
      formatter,
    );
    const operator = parsedValue[2];
    const number2 = getValueForCalculation(
      parsedValue[3],
      formValues,
      fieldName,
      formUpdate,
      formatter,
    );

    if (!isDefined(number1) || !isDefined(number2)) {
      return value;
    }

    switch (operator) {
      case "+":
        return number1! + number2!;
      case "-":
        return number1! - number2!;
      case "*":
        return number1! * number2!;
      case "%":
        return number1! % number2!;
      case "/":
        if (number2 !== 0) {
          return number1! / number2!;
        }
        return null;
      default:
        return value;
    }
  }

  return handleDateValue(value, formValues);
};

export const getValue = (value?: string | number | boolean | FormValue) => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};

const getUnderlyingFieldWithIndex = (
  formValues: FieldValueMapping,
  id: string,
  fieldName: string,
  formUpdate: string | number | FormValue,
  value,
) => {
  const underlyingFieldIndex = Object.keys(formValues)
    .map((key) =>
      key.includes("underlying_") ? key.split("underlying_")[1] : undefined,
    )
    .filter(Boolean);
  return underlyingFieldIndex
    .map((index) => `${id}_${index}`)
    .filter(
      (i) =>
        getValue(getUpdatedFormValue(i!, fieldName, formValues, formUpdate)) ===
        getValue(value),
    );
};

const getUpdatedFormValue = (
  formFieldId: string,
  fieldName: string,
  formValues: FieldValueMapping,
  formUpdate: string | number | FormValue,
) => {
  const fieldValue =
    formFieldId === fieldName ? formUpdate : formValues[formFieldId];
  if (formFieldId === "solveFor") {
    return (fieldValue as FormValue)?.value || fieldValue;
  }
  return (fieldValue as FormValue)?.label || fieldValue;
};

export const updateValuesWithRules = ({
  formUpdate,
  formValues,
  fieldName,
  rules,
  onChangeField,
  setVisible,
  setReadOnly,
  activeRules,
  setActiveRules,
  layout,
  updateFormOptions
}: UpdateValuesParams) => {
  const visibleMap = {};
  const readOnlyMap = {};

  if (rules.length && onChangeField) {
    const formKeys = Object.keys(formValues);
    const underlyings = formKeys.filter(
      (key) => key.includes("underlying_") && isDefined(formValues[key]),
    );

    /**
     * Note: Rules are run in order, last rule will have priority
     */
    const rulesToRun: Rule[] = [];

    rules.forEach((rule) => {
      const { condition = [] } = rule;
      let applyRule = { ...rule };

      const hasMetAllConditions = condition.every(({ id, value, hasValue }) => {
        /**
         * special logic to handle reading underlyings custom field
         */
        const isUnderlyingField = id.includes("underlying_");
        const shouldUptadeUnderlyingFields = [
          ...UNDERLYING_FIELDS,
          "payoff>underlying>underlying-id",
        ].includes(id);

        const formFieldId = getFormField(id);
        const formValue = getUpdatedFormValue(
          formFieldId,
          fieldName,
          formValues,
          formUpdate,
        );

        if (shouldUptadeUnderlyingFields && isDefined(value)) {
          const ids = getUnderlyingFieldWithIndex(
            formValues,
            id,
            fieldName,
            formUpdate,
            value,
          );
          if (ids?.length) {
            const newUpdate = rule.update.reduce<Update[]>((acc, u) => {
              ids.forEach((i) => {
                const match = i.match(/_([0-9]+)$/); // Match a number after the last underscore
                const numberAfterUnderscore = match ? match[1] : undefined;
                if (numberAfterUnderscore) {
                  acc.push({ ...u, id: `${u.id}_${numberAfterUnderscore}` });
                } else {
                  acc.push(u);
                }
              });
              return acc;
            }, []);
            applyRule = { ...rule, update: newUpdate };
            return true;
          }
        }
        if (isUnderlyingField) {
          const index = parseInt(id.split("_")[1]) - 1;
          if (hasValue && isDefined(formValues[underlyings[index]])) {
            return true;
          }

          /**
           * generic logic to check rules
           */
        } else if (hasValue && isDefined(formValue)) {
          return true;
        } else if (isDefined(value) && getValue(value) === getValue(formValue)) {
          return true;
        }
        return false;
      });

      if (hasMetAllConditions) {
        rulesToRun.push(applyRule);
      }
    });

    /**
     * Need to revert readOnly and visible fields when conditions
     * no longer match
     */
    const rulesToBeReverted = activeRules.filter((r) => {
      return !rulesToRun.includes(r);
    });

    rulesToBeReverted.forEach(({ update }) => {
      update.forEach((u) => {
        const id = getFormField(u.id);
        const { readOnly, visible, defaultValue } = layout?.find((l) => l.id === id) || {};
        if (isDefined(u.visible)) {
          visibleMap[id] = visible ?? true;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[id] = readOnly ?? false;
        }

        if (isDefined(u.value)) {
          onChangeField(id, null);
        }
        if(updateFormOptions && u.options){
          updateFormOptions(id);
        }
      });
    });

    rulesToRun.forEach(({ update }) => {
      update.forEach((u) => {
        const id = getFormField(u.id);
        if (isDefined(u.value)) {
          if (typeof u.value === "string") {
            const calculatedValue = Date.parse(u.value)
              ? u.value
              : getCalculatedValue({
                  value: u.value,
                  formValues,
                  fieldName,
                  formUpdate,
                });
            onChangeField(id, calculatedValue);
          } else {
            onChangeField(id, u.value);
          }
        }
        if(updateFormOptions && u.options){
          updateFormOptions(id, u.options);
        }
        if (isDefined(u.visible)) {
          visibleMap[id] = u.visible;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[id] = u.readOnly;
        }
      });
    });

    setActiveRules(rulesToRun);
  }

  setVisible(visibleMap);
  setReadOnly(readOnlyMap);
};


export const getDefaultValue = (value: Layout["defaultValue"], formValues:FieldValueMapping, roundUpTo?: number) => {
  if (typeof value === "string") {
    if (value.includes("schedule")) { // already handled in the reducer
      return undefined;
    }
    const defaultValue = getCalculatedValue({ value, formValues });
    const numValue = Number(defaultValue);
    if (!isNaN(numValue) && numValue % 1 !== 0 && isDefined(roundUpTo)) {
      return Number(numValue.toFixed(roundUpTo));
    }
    return defaultValue;
  }
  // return undefined when initially null in layout so we don't override rule
  return value ?? undefined; 
};

export const getWrapperLayout = (
  wrapperList: WrapperData[] | undefined,
  selectedWrapper: string | undefined,
) =>
  wrapperList?.find((wrapper) => wrapper.wrapperUri === selectedWrapper)
    ?.layout || [];

export const getStructureLayoutId = (structures, selectedStructure) =>
  structures.find((structure) => structure.value === selectedStructure?.value)
    ?.layoutId;

export const shouldUpdate = (prev, props) => {
  // reduxform has a bug (infinite loop)  where it will re-render too many times
  return JSON.stringify(prev) === JSON.stringify(props);
};

export const isDateComponent = (componentType: string) =>
  componentType === "date";
