import { combineEpics, ofType } from 'redux-observable';
import { from, merge } from 'rxjs';
import { filter, switchMap, debounceTime } from 'rxjs/operators';
import { getFormValues, updateSyncErrors, touch } from 'redux-form';
import { changeActionFilter } from 'utils/reduxFormSelector';
import { fillAllAction, BOOKING_ASYNC_ERROR, setInstrument } from 'redux/actions/booking';
import { BOOKING_FORM_CALENDAR, BOOKING_FORM_INSTRUMENT, BOOKING_FORM_OPTION, CUSTOM_STRIPE_FORMS, INSTRUMENT_FORM_TYPE } from 'pages/booking/constants';
import { REDUX_FORM_INITIALIZE } from 'redux/epics/constants';
import { isEmpty } from 'lodash';

const valueselector = getFormValues(BOOKING_FORM_CALENDAR);
const ALL_TO_FIELDS = new Map([
  ['directionAll', 'direction'],
  ['volumeAll', 'volume']
])

const onChangeFillAll = (action$, state$) =>
  merge(
    action$.pipe(filter(changeActionFilter(BOOKING_FORM_CALENDAR, 'directionAll'))),
    action$.pipe(filter(changeActionFilter(BOOKING_FORM_CALENDAR, 'volumeAll'))),
  ).pipe(
    debounceTime(200),
    switchMap(({ payload: value, meta: { field } }) => {
      const { schedule } = valueselector(state$.value);
      return from([
        fillAllAction({
          schedule: schedule.map((item => ({
            ...item,
            [ALL_TO_FIELDS.get(field)]: value
          })))
        }),
      ]);
    })
  )

const formInitializeFilter = form => action => action.type === REDUX_FORM_INITIALIZE && action.meta.form === form;

const showAsyncErrors = (action$, state$) =>
  merge(
    action$.pipe(filter(formInitializeFilter(`${BOOKING_FORM_OPTION}/${INSTRUMENT_FORM_TYPE}`))),
    action$.pipe(filter(formInitializeFilter(`${BOOKING_FORM_OPTION}/${CUSTOM_STRIPE_FORMS.STRUCTURE}`))),
    action$.pipe(filter(formInitializeFilter(`${BOOKING_FORM_OPTION}/${CUSTOM_STRIPE_FORMS.DETAILS}`))),
    action$.pipe(filter(formInitializeFilter(`${BOOKING_FORM_OPTION}/${CUSTOM_STRIPE_FORMS.CALENDAR}`))),
  ).pipe(
    debounceTime(200),
    switchMap(({ meta: { form: formName }, payload: { form } }) => {
      const { booking: { asyncErrors } } = state$.value;
      const { errors = {} } = asyncErrors;
      if (!errors[form] || isEmpty(errors[form])) return from([]);
      const fields = form === CUSTOM_STRIPE_FORMS.CALENDAR && errors[form].schedule ?
        errors[form].schedule.reduce((previous, current, index) => {
          return [...previous, ...Object.keys(current).map(field => `schedule[${index}].${field}`)]
        }, []) :
        Object.keys(errors[form] || {});
      return from([
        updateSyncErrors(formName, errors[form] || {}),
        touch(formName, ...fields)
      ]);
    })
  )

const asyncErrorsActions = action$ =>
  action$.pipe(
    ofType(BOOKING_ASYNC_ERROR),
    filter(({ payload: { errors } }) => !!errors && errors.activeStep === 3),
    switchMap(({ payload: { errors: payload } }) => {
      const { errors: { [CUSTOM_STRIPE_FORMS.CALENDAR]: errors } } = payload;
      const formName = `${BOOKING_FORM_OPTION}/${CUSTOM_STRIPE_FORMS.CALENDAR}`;
      return from([
        updateSyncErrors(formName, errors),
      ]);
    })
  )

const onChangeInstrument = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(BOOKING_FORM_INSTRUMENT, 'instrument')),
    debounceTime(200),
    switchMap(({ payload: value }) => {
      return from([
        setInstrument(value)
      ]);
    })
  )

export default combineEpics(
  onChangeFillAll,
  showAsyncErrors,
  asyncErrorsActions,
  onChangeInstrument,
);
