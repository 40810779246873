import React from 'react';
import Bundle from 'pages/Bundle';
import { connect } from 'react-redux';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import PageMeta from 'components/common/PageMeta';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { isAuth0Selector } from 'providers/AuthProvider';

export const Profile = props => {
  const { isAuth0 } = props;
  const layout = isAuth0 ? './Profile' : './ProfileWithTabs';
  return (
    <ErrorBoundary>
      <PageMeta title="Profile" />
      <Bundle title="Profile Page" load={() => import(`${layout}`)}>
        {Component => (Component ? <Component {...props} /> : <LoadingFullPage />)}
      </Bundle>
    </ErrorBoundary>
  );
}


const mapStateToProps = state => ({
  isAuth0: isAuth0Selector(state),
});

export default connect(mapStateToProps)(Profile);