import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody, CardTitle, Form } from 'reactstrap';
import { InlineInput } from 'components/form/InlineInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import validate from './validate';

class TagForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  submit = async ({ tag }) => {
    const { submitFormHandler } = this.props;
    await submitFormHandler(tag);
  };

  render() {
    const {
      title,
      pristine,
      handleSubmit,
      invalid,
      submitting,
      error,
      fielLabel,
    } = this.props;
    return (
      <Card className="mb-3 card-modern tour-pricing-tag-load">
        <CardBody>
          <CardTitle>
            {title}
          </CardTitle>
          {error ? <MainErrorMessage error={error} /> : null}
          <Form onSubmit={handleSubmit(this.submit)} noValidate>
            <Field
              name="tag"
              type="text"
              label={fielLabel}
              clearBtn={true}
              placeHolder={`Enter ${fielLabel}`}
              component={InlineInput} />
            <div className="text-right">
              <Button type="submit" color="primary" disabled={pristine || invalid || submitting}>
                <FormattedMessage id="form.load-pricing.submit"
                  defaultMessage="Load" />
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

TagForm.defaultProps = {
  form: '@FORM/TAG',
  title: <FormattedMessage id="price.page.headers.load-pricing"
    defaultMessage="Load Pricing"
  />,
  fielLabel: 'Tag'
};

TagForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitFormHandler: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  fielLabel: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  return {
    form,
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ validate }),
)(TagForm);
