import gql from 'graphql-tag';

export const CREATE_SWAP_ORDER_MUTATION = gql`mutation createNeonOrderMutation(
  $commodityCode: String!
  $contractExpiry: String
  $contractExpirySecond: String
  $quantity: Float!
  $direction: PricingSwapDirectionEnumType!
  $price: Float
  $quotedCurrency: String!
  $contractCode: String!
  $commodityContract: String!
  $structure: String!
  $timeInForce: String!
  $orderType: String!
  $stopPrice: Float
  $maxShow: Float
  $expiryDate: DateTime
  $slackIceClientsId: ID
  $priceForCalcSpread: Float
  $unit: String
  $unitType: PricingUnitEnumType
  $metalHedgeCost: Float
  $metalMark: Float
  $isCardSpot: Boolean
  $isConvertingUScToUSD: Boolean
) {
  createOrder(
    commodityCode: $commodityCode
    contractExpiry: $contractExpiry
    contractExpirySecond: $contractExpirySecond
    quantity: $quantity
    direction: $direction
    price: $price
    quotedCurrency: $quotedCurrency
    contractCode: $contractCode
    commodityContract: $commodityContract
    structure: $structure
    timeInForce: $timeInForce
    orderType: $orderType
    stopPrice: $stopPrice
    maxShow: $maxShow
    expiryDate: $expiryDate
    slackIceClientsId: $slackIceClientsId
    priceForCalcSpread: $priceForCalcSpread
    unit: $unit
    unitType: $unitType
    metalHedgeCost: $metalHedgeCost
    metalMark: $metalMark
    isCardSpot: $isCardSpot
    isConvertingUScToUSD: $isConvertingUScToUSD
    ) {
      id
    }
}`;

export const CREATE_COMPO_SWAP_ORDER_MUTATION = gql`mutation createCompoOrderMutation(
  $commodityCode: String!
  $quantity: Float!
  $cardId: String!
  $rate: Float!
  $cmPrice: Float!
  $price: Float
  $structure: String!
  $quoteId: String!
  $timeInForce: String
  $orderType: String
  $slackIceClientsId: ID
  $fxPriceForCalcSpread: Float
  $cmPriceForCalcSpread: Float
  $metalHedgeCost: Float
  $metalMark: Float
) {
  createCompoOrder(
    commodityCode: $commodityCode
    quantity: $quantity
    cardId: $cardId
    rate: $rate
    cmPrice: $cmPrice
    price: $price
    structure: $structure
    quoteId: $quoteId
    timeInForce: $timeInForce
    orderType: $orderType
    slackIceClientsId: $slackIceClientsId
    fxPriceForCalcSpread: $fxPriceForCalcSpread
    cmPriceForCalcSpread: $cmPriceForCalcSpread
    metalHedgeCost: $metalHedgeCost
    metalMark: $metalMark
    ) {
      id
    }
}`;

export const CREATE_SWAP_ORDER_WITH_LEGS_MUTATION = gql`mutation createOrderWithLegsMutation(
  $commodityCode: String!
  $cardId: String!
  $structure: String!
  $contractExpiry: String!
  $contractExpirySecond: String
  $orderType: String
  $timeInForce: String
  $expiryDate: DateTime
  $quantity: Float!
  $priceForCalcSpread: Float
  $maxShow: Float
  $legs: [OrderLegsType]
  $slackIceClientsId: ID
  $unit: String
  $unitType: PricingUnitEnumType
) {
  createOrderWithLegs(
    commodityCode: $commodityCode
    cardId: $cardId
    structure: $structure
    contractExpiry: $contractExpiry
    contractExpirySecond: $contractExpirySecond
    orderType: $orderType
    timeInForce: $timeInForce
    expiryDate: $expiryDate
    quantity: $quantity
    priceForCalcSpread: $priceForCalcSpread
    maxShow: $maxShow
    legs: $legs
    slackIceClientsId: $slackIceClientsId
    unit: $unit
    unitType: $unitType
  ) {
    id
  }
}`;
