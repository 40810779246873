import apolloClient from 'providers/ApolloClientProvider';
import gql from 'graphql-tag';
import uniqWith from 'lodash/uniqWith';
import { PRICING_FORM_RESPONSE_FRAGMENT } from 'pages/price/PriceFormResponseFragment';
import { createSelector } from 'reselect';
import { PRICING_ACCUMULATOR_DATA_LOADED, PRICING_VANILLA_FORM_LOADED } from 'redux/actions/price';
import { LIMITATION_RELATION_VANILLA } from '../prices/config-vanilla';
import { LIMITATION_RELATION } from '../prices/configs';
import { PRICING_HISTORY_ACCUMULATOR_QUERY_FRAGMENT } from './query';

export type CurrencyType = 'usd';

export type IVanillaPriceSheet = {
  id: number,
  expiryDate: Date,
  startDate: Date,
  referencePrice: number,
  currency: CurrencyType
}

export type IPriceSheet = IVanillaPriceSheet;

export type IProduct = {
  id: number,
  sheets?: IPriceSheet[]
}

export type Range = {
  min: number,
  max: number,
}

const MUTATION_CALCULATE_ACCUMULATOR_LEVEL = gql`mutation calculateStrikeLevel(
    $underlyingId: String!
    $descriptionId: String!
    $notional: Float!
    $barrierLevel: Float = null
    $pricePerLot: Float!
    $referencePrice: Float!
    $pricingsId: String!
  ) {
    newTrail: pricingAccumulatorCalculateStrikeLevel(
      underlyingId: $underlyingId,
      descriptionId: $descriptionId,
      notional: $notional,
      barrierLevel: $barrierLevel,
      pricePerLot: $pricePerLot,
      referencePrice: $referencePrice,
      pricingsId: $pricingsId
    ) {
    id
    ...PriceFormResponseFragment
  }
}
${PRICING_FORM_RESPONSE_FRAGMENT}`;

export const fetchAccumulationLevel = (underlyingId, barrierLevel, notional, pricePerLot, referencePrice, descriptionId, pricingsId) =>
  apolloClient.mutate({
    mutation: MUTATION_CALCULATE_ACCUMULATOR_LEVEL,
    variables: {
      underlyingId,
      barrierLevel,
      notional,
      pricePerLot,
      referencePrice,
      descriptionId,
      pricingsId
    },
  })
    .then(response => response.data.newTrail);

const MUTATION_CALCULATE_BARRIER_LEVEL = gql`mutation calculateBarrierLevel(
    $underlyingId: String!
    $descriptionId: String!
    $notional: Float!
    $strikeLevel: Float!
    $pricePerLot: Float!
    $referencePrice: Float!
    $pricingsId: String!
  ) {
  newTrail: pricingAccumulatorCalculateBarrierLevel(
      underlyingId: $underlyingId,
      descriptionId: $descriptionId,
      notional: $notional,
      strikeLevel: $strikeLevel,
      pricePerLot: $pricePerLot,
      referencePrice: $referencePrice,
      pricingsId: $pricingsId
    ) {
    id
    ...PriceFormResponseFragment
  }
}
${PRICING_FORM_RESPONSE_FRAGMENT}`;

export const fetchBarrierLevel = (underlyingId, strikeLevel, notional, pricePerLot, referencePrice, descriptionId, pricingsId) => {
  return apolloClient.mutate({
    mutation: MUTATION_CALCULATE_BARRIER_LEVEL,
    variables: {
      underlyingId,
      strikeLevel,
      notional,
      pricePerLot,
      referencePrice,
      descriptionId,
      pricingsId
    },
  })
    .then(response => {
      return response.data.newTrail;
    })
};

const MUTATION_CALCULATE_PRICE = gql`mutation calculatePrice(
    $underlyingId: String!
    $descriptionId: String!
    $notional: Float!
    $strikeLevel: Float!
    $barrierLevel: Float = null
    $remainderLevel: Float = null
    $structureExpiryDate: DateTime!
  ) {
  newTrail: pricingAccumulatorCalculatePrice(
      underlyingId: $underlyingId,
      descriptionId: $descriptionId,
      notional: $notional,
      strikeLevel: $strikeLevel,
      barrierLevel: $barrierLevel,
      structureExpiryDate: $structureExpiryDate,
      remainderLevel: $remainderLevel,
    ) {
    id
    ...PriceFormResponseFragment
    pricePerLot
  }
}
${PRICING_FORM_RESPONSE_FRAGMENT}`;

export const fetchPrice = (underlyingId, notional, strikeLevel, barrierLevel, descriptionId, structureExpiryDate, remainderLevel) => {
  return apolloClient.mutate({
    mutation: MUTATION_CALCULATE_PRICE,
    variables: {
      underlyingId,
      descriptionId,
      barrierLevel,
      strikeLevel,
      notional,
      structureExpiryDate,
      remainderLevel,
    },
  })
    .then(response => response.data.newTrail);
}

export function filterSheets(records, filterData, limitationArray = LIMITATION_RELATION, skipField = '') {
  return Array.isArray(records) && records.filter(record => {
    return !limitationArray
      .filter(({ key }) => key !== skipField)
      .some(({ key, limitationKey, sheetExtractor, compare }) => {
        return filterData[key] && !compare(filterData[key], sheetExtractor(record));
      })
  });
}

export const filterAction = (filterData = {}, records = [], limitationRelation = LIMITATION_RELATION) => {
  let filteredSheets = records;
  let currentFilter = {};

  return limitationRelation.filter(item => item.sheetExtractor).reduce((prevLimit, limitationConfig) => {
    const { key, limitationKey, sheetExtractor, compare } = limitationConfig;

    filteredSheets = filterSheets(filteredSheets, currentFilter, limitationRelation);

    const filterValues = uniqWith(
      filteredSheets.map(sheetExtractor),
      compare
    );
    if (filterData && filterData[key]
      && filterValues.some((current) => compare(filterData[key], current))
    ) {
      currentFilter[key] = filterData[key];
    }

    prevLimit[limitationKey] = filterValues;
    return prevLimit;
  }, {});
}

const limitationSelector = createSelector(
  [
    ({ filterData }) => filterData,
    ({ pricings }) => pricings,
    ({ limitationRelation }) => limitationRelation,
  ],
  filterAction
)

export const loadAllVariable = (filterData, pricings, limitationRelation) =>
  limitationSelector({ filterData, pricings, limitationRelation });


const PRICING_HISTORY_ACCUMULATOR_QUERY = gql`query pricingHistoryAccumulatorQuery($id: ID!) {
  pricingAccumulatorHistory (id: $id) {
    id
    ...priceAccumulatorFragment
  }
}
${PRICING_HISTORY_ACCUMULATOR_QUERY_FRAGMENT}`;

export const PRICING_HISTORY_VANILLA_QUERY_FRAGMENT = gql`fragment priceVanillaFragment on PricingVanillaHistoryType {
  id
  structure
  tag
  quoteUnits
  notional
  strikeLevel
  referencePrice
  direction
  marketData {
    id
    bloombergTicker
    optionExpiryDate
    contractExpiry
    commodityContract
    quotedCurrency
  }
  description {
    id
    type
  }
}`;

const PRICING_HISTORY_VANILLA_QUERY = gql`query pricingHistoryVanillaQuery($id: ID!) {
  pricingVanillaHistory(id: $id) {
    id
    ...priceVanillaFragment
  }
}
${PRICING_HISTORY_VANILLA_QUERY_FRAGMENT}`;

// @TODO: clean up code
// move to redux?!
// get marketData
export const preapreAccumulatorTrail = (trail) => {
  return {
    ...trail,
    type: trail.description.type,
    structureName: trail.structure,
    structure: {
      type: trail.description.type,
      barrierType: trail.barrierType,
      leverage: trail.description.leverage,
      leverageStyle: trail.description.leverageStyle,
      accumulationStyle: trail.description.accumulationStyle,
    }
  }
}

export const preapreTrailVanilla = (trail) => {
  return {
    ...trail,
    structureName: trail.structure,
    structureType: trail.description.type
  }
}

export const chooseStructureConfig = structure => {
  switch (structure) {
    case ('Vanilla'):
      return {
        querty: PRICING_HISTORY_VANILLA_QUERY,
        resp: 'pricingVanillaHistory',
        limitation: LIMITATION_RELATION_VANILLA,
        prepareteResponse: preapreTrailVanilla,
        dataLoaded: PRICING_VANILLA_FORM_LOADED
      };
    default:
      return {
        querty: PRICING_HISTORY_ACCUMULATOR_QUERY,
        resp: 'pricingAccumulatorHistory',
        limitation: LIMITATION_RELATION,
        prepareteResponse: preapreAccumulatorTrail,
        dataLoaded: PRICING_ACCUMULATOR_DATA_LOADED
      };
  }
}
// @TODO: end

export const loadTrailForFill = (id, structure) => {
  const structureData = chooseStructureConfig(structure);
  return apolloClient.query({
    query: structureData.querty,
    variables: { id }
  }).then(({ data }) => {
    const resp = data[structureData.resp];
    return structureData.prepareteResponse(resp);
  })
};
