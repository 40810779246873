import React from 'react';
import './Loader.scss';

interface LoaderProps {
  className?: string;  
}

const Loader: React.FC<LoaderProps> = ({ className }) => (
  <div className={`loader${className ? ' ' + className : ''}`} data-testid="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default Loader;
