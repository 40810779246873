import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { split, from } from 'apollo-link';
import { setContext } from 'apollo-link-context';

import { config } from './../../config';
import { errorLink, httpLink, splitTestHandler, afterwareLink } from './helpers';
import introspectionQueryResultData from './fragmentTypes.json';
import { ApolloClientContext, ApolloWsContext } from './ApolloContext';


interface ApolloClientProviderProps {
  children: ReactNode
}

export const ApolloClientProvider: React.FC<ApolloClientProviderProps> = ({
  children,
}): ReactElement => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth();

  const getToken = useCallback(async () => {
    try {
      if(!isLoading && !isAuthenticated) return null;
      return await getAccessTokenSilently();
    } catch (error) {
      return null;
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);


  const client = useMemo(() => {

    const wsLink = new WebSocketLink({
      uri: `${config.API_WS_PROTOCOL}://${config.SUBSCRIPTION_HOST}/subscription`,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => {
          const token = await getToken();
          return {
            authorization: `Bearer ${token}`,
            tabId: sessionStorage.getItem(config.TAB_ID_KEY),
          }
        }
      }
    });
    ApolloWsContext.provider(wsLink);

    const authLink = setContext(async () => {
      const token = await getToken();
      const headers = token ? {
        'Authorization': `Bearer ${token}`,
      } : {};

      return { headers };
    });

    const link = from([
      errorLink,
      afterwareLink,
      authLink,
      httpLink
    ])

    const apolloClient = new ApolloClient({
      link: split(
        splitTestHandler,
        wsLink,
        link,
      ),
      cache: new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData
        })
      }),
    });

    ApolloClientContext.provider(apolloClient);
    return apolloClient;
  }, [getAccessTokenSilently, getToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};