import { ParsedStructureData } from 'components/configurableSp/types';
import merge from "lodash/merge";
import { COUPON_DIRECTION_OPTIONS } from "pages/structured-products/components/forms/constants";
import { PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from "pages/structured-products/components/forms/types/autocallable/constats";
import AUTOCALLABLE_FORM_FIELDS from "pages/structured-products/components/forms/types/autocallable/fields";
import {
  createEmptyUnderlying,
  createEmptyUnderlyings,
  getUnderlyingIndex,
  UNDERLYING_PREFIX,
} from "pages/structured-products/components/forms/underlyings/utils";
import {
  STRUCTURED_PRODUCTS_BANK_HOLIDAYS_SET,
  STRUCTURED_PRODUCTS_FORM_CONFIG_SET,
  STRUCTURED_PRODUCTS_FORM_FIELD_UPDATE,
  STRUCTURED_PRODUCTS_FORM_FIELDS_SET,
  STRUCTURED_PRODUCTS_FORM_LAYOUT,
  STRUCTURED_PRODUCTS_FORM_LOADER,
  STRUCTURED_PRODUCTS_FORM_OPTIONS_DEFAULT,
  STRUCTURED_PRODUCTS_FORM_OPTIONS_SET,
  STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH,
  STRUCTURED_PRODUCTS_FORM_RESTORE_START,
  STRUCTURED_PRODUCTS_FORM_RESULTS_LOADER,
  STRUCTURED_PRODUCTS_FORM_RESULTS_SET,
  STRUCTURED_PRODUCTS_FORM_UPDATE_OPTIONS,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_ADD_EMPTY,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_CREATE_LIST,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_DELETE,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_RESET,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE,
  STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE_LIST,
  STRUCTURED_PRODUCTS_RESET,
  STRUCTURED_PRODUCTS_SCHEDULE_FROM_HISTORY,
  STRUCTURED_PRODUCTS_SCHEDULE_INVALID,
  STRUCTURED_PRODUCTS_SCHEDULE_LOADER,
  STRUCTURED_PRODUCTS_SCHEDULE_LOCK,
  STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_LOADER,
  STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_SET,
  STRUCTURED_PRODUCTS_SCHEDULE_RESPONSE_SET,
  STRUCTURED_PRODUCTS_SCHEDULE_SET,
  STRUCTURED_PRODUCTS_SCHEDULE_UPDATE,
  STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS,
  STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS_RESET,
  STRUCTURED_PRODUCTS_SET_FIELD_SETTINGS,
  STRUCTURED_PRODUCTS_SET_FORM_STRUCTURES,
  STRUCTURED_PRODUCTS_STRUCTURE_SET,
} from "redux/actions/structured-products";
import { availableFirstObservationIn } from "redux/epics/structured-products/form/options/firstObservationIn";
import { handleVisibleFormFields } from "redux/epics/structured-products/form/visibleFields";

export type StructuredProductsReducerState = {
  formConfig: Object | {};
  formOptions: Object | {};
  formOptionsDefault: Object | null;
  formFields: Array<any> | [];
  underlyings: Array<any> | [];
  schedule: Array<any> | null;
  scheduleConfig: any;
  floatingLegSchedule: Array<any> | null;
  floatingLegScheduleConfig: any;
  scheduleResponse: Array<any> | null;
  results: Object | null;
  isLoading: Boolean | false;
  isScheduleLoading: Boolean | false;
  isScheduleOptionsLoading: Boolean | false;
  isScheduleLocked: Boolean | false;
  isScheduleFromHistory: Boolean | false;
  isResultsLoader: Boolean | false;
  structures: Array<any> | [];
  selectedStrucutre: String | null;
  isRestoringFromHistory: Boolean | false;
  scheduleOptions: Object | null;
  bankHolidays: Array<any> | [];
  scheduleInvalid: Boolean | false;
  layout?: ParsedStructureData['layout'];
};

const firstObservationIn = availableFirstObservationIn(
  PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES,
);

export const SOLVE_FOR_REOFFER_VALUE = "Reoffer";
export const SOLVE_FOR_REOFFER_LABEL = "Reoffer";
export const SOLVE_FOR_COUPON_VALUE = "Coupon";
export const SOLVE_FOR_COUPON_LABEL = "Coupon";
const solveFor = [
  { value: SOLVE_FOR_REOFFER_VALUE, label: SOLVE_FOR_REOFFER_LABEL },
  { value: SOLVE_FOR_COUPON_VALUE, label: "Coupon" },
];

const updateUnderlyings = (state, payload) => {
  let underlyingsList = state.underlyings;
  const { name, options, ...rest } = payload;
  const index = getUnderlyingIndex(name, underlyingsList);
  if (index >= 0 && underlyingsList[index]) {
    const find = underlyingsList[index];
    underlyingsList[index] = {
      ...find,
      ...rest,
      name,
      options: options && options.length ? options : find.options,
    };
  }
  return underlyingsList;
};

const STRUCTUED_PRODUCTS_INIT_STATE: StructuredProductsReducerState = {
  formConfig:
    handleVisibleFormFields(PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES) || {},
  formOptions: {
    firstObservationIn,
    solveFor,
    couponDirection: COUPON_DIRECTION_OPTIONS,
  },
  underlyings: [...createEmptyUnderlyings(1)],
  selectedStrucutre: null, //Use it instead loader and change form view only after get data from API
  formFields: AUTOCALLABLE_FORM_FIELDS,
  schedule: null,
  scheduleResponse: null,
  isScheduleLoading: false,
  isScheduleOptionsLoading: false,
  isScheduleLocked: false,
  isScheduleFromHistory: false,
  isLoading: true,
  formOptionsDefault: [],
  results: null,
  isResultsLoader: false,
  isRestoringFromHistory: false,
  structures: [],
  scheduleOptions: null,
  bankHolidays: [],
  scheduleInvalid: false,
};

type strucuturedProductsUpdateFieldType = {
  name: string;
};

const strucuturedProductsUpdateField = (
  formFields: Array<strucuturedProductsUpdateFieldType> = [],
  fieldName: string,
  options: object = {},
) => {
  const findIndex = formFields.findIndex(({ name }) => name === fieldName);
  if (findIndex >= 0) {
    formFields[findIndex] = {
      ...formFields[findIndex],
      ...options,
    };
  }
  return formFields;
};

export default function structuredProductsReducer(
  state = STRUCTUED_PRODUCTS_INIT_STATE,
  action,
) {
  switch (action.type) {
    case STRUCTURED_PRODUCTS_FORM_CONFIG_SET:
      return { ...state, formConfig: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_LOADER:
      return { ...state, isScheduleLoading: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_LOADER:
      return { ...state, isScheduleOptionsLoading: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_SET: {
      return { ...state, schedule: action.payload };
    }

    case STRUCTURED_PRODUCTS_SCHEDULE_UPDATE: {
      return {
        ...state,
        schedule: { ...state.schedule, ...action.payload.schedule },
      };
    }
    case STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_SET:
      return { ...state, scheduleOptions: action.payload };

    case STRUCTURED_PRODUCTS_BANK_HOLIDAYS_SET:
      return { ...state, bankHolidays: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS:
      const scheduleUserInputs = merge(
        state.scheduleUserInputs,
        action.payload,
      );
      return { ...state, scheduleUserInputs };

    case STRUCTURED_PRODUCTS_SCHEDULE_USER_INPUTS_RESET:
      return { ...state, scheduleUserInputs: null };

    case STRUCTURED_PRODUCTS_SCHEDULE_RESPONSE_SET:
      return { ...state, scheduleResponse: action.payload };

    case STRUCTURED_PRODUCTS_FORM_LOADER:
      return { ...state, isLoading: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_LOCK:
      return { ...state, isScheduleLocked: action.payload };

    case STRUCTURED_PRODUCTS_SCHEDULE_FROM_HISTORY:
      return { ...state, isScheduleFromHistory: Boolean(action.payload) };

    case STRUCTURED_PRODUCTS_SCHEDULE_INVALID:
      return {
        ...state,
        scheduleInvalid: Boolean(action.payload),
      };

    case STRUCTURED_PRODUCTS_FORM_OPTIONS_SET:
      const formOptions = {
        ...state.formOptions,
        ...action.payload,
      };
      return {
        ...state,
        formOptions,
      };

    case STRUCTURED_PRODUCTS_RESET:
      return {
        ...STRUCTUED_PRODUCTS_INIT_STATE,
        selectedStrucutre: state.selectedStrucutre,
      };

    case STRUCTURED_PRODUCTS_FORM_OPTIONS_DEFAULT:
      const formOptionsDefault = {
        ...state.formOptions,
        ...action.payload,
      };
      return {
        ...state,
        formOptionsDefault,
      };

    case STRUCTURED_PRODUCTS_FORM_RESULTS_SET:
      return { ...state, results: action.payload };

    case STRUCTURED_PRODUCTS_FORM_FIELD_UPDATE:
      const { name, options } = action.payload;
      const formFields = strucuturedProductsUpdateField(
        state.formFields,
        name,
        options,
      );
      return { ...state, formFields };

    case STRUCTURED_PRODUCTS_FORM_FIELDS_SET:
      return { ...state, formFields: action.payload };

    case STRUCTURED_PRODUCTS_STRUCTURE_SET:
      const selectedStrucutre = action.payload;
      return { ...state, selectedStrucutre };

    case STRUCTURED_PRODUCTS_FORM_RESULTS_LOADER:
      return { ...state, isResultsLoader: action.payload };

    case STRUCTURED_PRODUCTS_SET_FIELD_SETTINGS:
      return { ...state, fieldsSettings: action.payload };

    // Underlyings
    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_ADD_EMPTY:
      return {
        ...state,
        underlyings: [...state.underlyings, createEmptyUnderlying()],
      };

    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_DELETE:
      let underlyings = [...state.underlyings];
      underlyings.splice(parseInt(action.payload, 10), 1);
      return { ...state, underlyings };

    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_RESET:
      return {
        ...state,
        underlyings: [{ name: `${UNDERLYING_PREFIX}_1`, options: [] }],
      };

    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_CREATE_LIST:
      const count = parseInt(action.payload) || 1;
      return {
        ...state,
        underlyings: [...createEmptyUnderlyings(count)],
      };

    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE_LIST:
      return {
        ...state,
        underlyings: [...action.payload],
      };

    case STRUCTURED_PRODUCTS_FROM_UNDERLYINGS_UPDATE:
      return {
        ...state,
        underlyings: [...updateUnderlyings(state, action.payload)],
      };

    case STRUCTURED_PRODUCTS_SET_FORM_STRUCTURES:
      return {
        ...state,
        structures: [...action.payload],
      };

    case STRUCTURED_PRODUCTS_FORM_RESTORE_START:
      return { ...state, isRestoringFromHistory: true };

    case STRUCTURED_PRODUCTS_FORM_RESTORE_FINISH:
      return { ...state, isRestoringFromHistory: false };
    case STRUCTURED_PRODUCTS_FORM_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };
    case STRUCTURED_PRODUCTS_FORM_UPDATE_OPTIONS:
      const { id, permittedOptions } = action.payload;
      const updatedLayout = state.layout ? [...state.layout] : [];
      updatedLayout.forEach((layout) => {
        if (id === layout.id && layout.componentConfig?.options) {
          layout.componentConfig = {
            ...layout.componentConfig,
            permittedOptions,
          };
        }
      });
      return {
        ...state,
        layout: updatedLayout,
      };
    default:
      return state;
  }
}
