import gql from 'graphql-tag';
import apolloClient from 'providers/ApolloClientProvider';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

export const GET_CONTRACT_EXPIRY_LIST = gql`query GetContractExpiryList (
  $type: StructureEnumType!
  $commodityCode: String!
  $contractExpiry: String
) {
  CMContractExpiryList(
    type: $type
    commodityCode: $commodityCode
    contractExpiry: $contractExpiry
  ){
    strike
    contract
    expiry
    bloombergTicker
    isSpot
    isMetal
  }
}`;

export const fetchContractExpiryList = ({
  type,
  commodityCode,
  contractExpiry
}) => {
  return apolloClient.query({
    query: GET_CONTRACT_EXPIRY_LIST,
    variables: {
      type,
      commodityCode,
      contractExpiry
    },
    fetchPolicy: GRAPHQL_NETWORK_ONLY
  })
    .then(response => response?.data?.CMContractExpiryList);
}
