import React from "react";
import PropTypes from "prop-types";
import StaticPageModalRoute from "components/staticPage/StaticPageModalRoute";
import MockedDashboardLayout from "layouts/MockedDashboardLayout";
import PortalLayout from "layouts/PortalLayout";
import isEmpty from "lodash/isEmpty";
import {
  ROUTE_CUSTOM_REQUESTS,
  ROUTE_CUSTOM_REQUEST_OPEN,
  ROUTE_DASHBOARDS,
  ROUTE_DASHBOARDS_REQUEST,
  ROUTE_EMAIL_CONFIRM,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_MINIFUTURE,
  ROUTE_MINIFUTURE_ORDERS,
  ROUTE_MINIFUTURE_TRADE,
  ROUTE_MOCKED_DASHBOARD,
  ROUTE_MOCKED_DASHBOARD_REQUEST,
  ROUTE_MY_DASHBOARD,
  ROUTE_NOT_FOUND,
  ROUTE_PORTAL,
  ROUTE_PORTAL_CATEGORY,
  ROUTE_PORTAL_SINGLE_POST,
  ROUTE_PRICING,
  ROUTE_PRICING_HISTORY,
  ROUTE_PRICING_MINI,
  ROUTE_PRICING_MINI_COMMODITY,
  ROUTE_PRICING_ORDERS,
  ROUTE_PRICING_ORDERS_SWAP,
  ROUTE_PRICING_ORDERS_ASIAN,
  ROUTE_PRICING_ORDERS_BULLET_STRIP,
  ROUTE_PRICING_ORDERS_FX,
  ROUTE_PRICING_ORDERS_VANILLA,
  ROUTE_PRICING_STRUCTURE,
  ROUTE_PROFILE,
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGN_UP,
  ROUTE_STATIC_PAGE,
  ROUTE_STRUCTURED_PRODUCTS,
  ROUTE_STRUCTURED_PRODUCTS_HISTORY,
  ROUTE_STRUCTURED_PRODUCTS_SINGLE,
  ROUTE_TRADES_HISTORY,
  ROUTE_BOOKING,
  ROUTE_BOOKING_DETAIL,
  ROUTE_PRICING_SWAP_ASIAN,
  ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS,
  ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS_HISTORY,
} from "pages/constants";
import DashboardsLayout from "pages/dashboards/";
import HomePage from "pages/home/";
import MinifuturePage from "pages/minifuture/";
import MyDashboardLayout from "pages/my-dashboard";
import NoMatch from "pages/no-match/";
import PricePage from "pages/price/";
import PriceMiniPage from "pages/price/mini/";
import ProfilePage from "pages/profile/";
import {
  ROLE_BOOKING,
  ROLE_CONFIGURABLE_SP,
  ROLE_DASHBOARD,
  ROLE_MINIFUTURE,
  ROLE_MY_DASHBOARD,
  ROLE_PORTAL,
  ROLE_PRICING,
  ROLE_PRICING_MINI,
  ROLE_STRUCTURED_PRODUCTS,
  ROLE_TRADES_HISTORY,
  ROLE_PRICING_BETA,
  ROLE_AGILE_FX,
} from "pages/roles";
import PrivateRoute, { allowedStates } from "pages/routes/PrivateRouter";
import StructuredProductsPage from "pages/structured-products/";
import TradesHistoryPage from "pages/trades-history/";
import BookingPage from "pages/booking";
import { Redirect, Route, Switch } from "react-router-dom";
import ExperimentalConfigurableSp from "../preview-configurable-sp";
import PriceBetaPage from 'pages/pricing';
import { ROUTE_PRICING as ROUTE_PRICING_BETA } from 'pages/pricing/constants';
import FxPage from 'pages/fx';
import { ROUTE_FX } from 'pages/fx/constants';

const DEFAULT_REDIRECT = ROUTE_HOME;

const ROUTES_MINIFUTURE = [
  <PrivateRoute
    authorized={[ROLE_MINIFUTURE]}
    path={ROUTE_MINIFUTURE}
    exact
    component={MinifuturePage}
  />,
  <PrivateRoute
    authorized={[ROLE_MINIFUTURE]}
    path={ROUTE_MINIFUTURE_ORDERS}
    exact
    component={MinifuturePage}
  />,
  <PrivateRoute
    authorized={[ROLE_MINIFUTURE]}
    path={ROUTE_MINIFUTURE_TRADE}
    exact
    component={MinifuturePage}
  />,
];

const ROUTES_PRICING = [
  // <PrivateRoute authorized={[ROLE_PRICING]} path={ROUTE_PRICING_HISTORY_ID} exact component={PriceHistoryPage} />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_HISTORY}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_STRUCTURE}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_SWAP_ASIAN}
    exact
    component={PricePage}
  />,
  // <PrivateRoute authorized={[ROLE_PRICING]} path={ROUTE_PRICING_HISTORY_ID} exact component={PricePage} />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS_SWAP}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS_FX}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS_VANILLA}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS_ASIAN}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS_BULLET_STRIP}
    exact
    component={PricePage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING]}
    path={ROUTE_PRICING_ORDERS}
    exact
    component={PricePage}
  />,
];

const ROUTES_DASHBOARDS = [
  <PrivateRoute
    authorized={[ROLE_DASHBOARD]}
    path={ROUTE_DASHBOARDS}
    exact
    component={DashboardsLayout}
  />,
  <PrivateRoute
    authorized={[ROLE_DASHBOARD]}
    path={ROUTE_DASHBOARDS_REQUEST}
    exact
    component={DashboardsLayout}
  />,
  <PrivateRoute
    authorized={[ROLE_DASHBOARD]}
    path={ROUTE_MOCKED_DASHBOARD}
    exact
    component={MockedDashboardLayout}
  />,
  <PrivateRoute
    authorized={[ROLE_DASHBOARD]}
    path={ROUTE_MOCKED_DASHBOARD_REQUEST}
    exact
    component={MockedDashboardLayout}
  />,
];

const ROUTES_MY_DASHBOARDS = [
  <PrivateRoute
    authorized={[ROLE_MY_DASHBOARD]}
    path={ROUTE_MY_DASHBOARD}
    exact
    component={MyDashboardLayout}
  />,
];

const ROUTES_PORTAL = [
  <PrivateRoute
    authorized={[ROLE_PORTAL]}
    path={ROUTE_PORTAL}
    exact
    component={PortalLayout}
  />,
  <PrivateRoute
    authorized={[ROLE_PORTAL]}
    path={ROUTE_PORTAL_CATEGORY}
    exact
    component={PortalLayout}
  />,
  <PrivateRoute
    authorized={[ROLE_PORTAL]}
    path={ROUTE_PORTAL_SINGLE_POST}
    exact
    component={PortalLayout}
  />,
];

const ROUTES_TRADES_HISTORY = [
  <PrivateRoute
    authorized={[ROLE_TRADES_HISTORY]}
    path={ROUTE_TRADES_HISTORY}
    exact
    component={TradesHistoryPage}
  />,
];

const ROUTES_PRICING_MINI = [
  <PrivateRoute
    authorized={[ROLE_PRICING_MINI]}
    path={ROUTE_PRICING_MINI}
    exact
    component={PriceMiniPage}
  />,
  <PrivateRoute
    authorized={[ROLE_PRICING_MINI]}
    path={ROUTE_PRICING_MINI_COMMODITY}
    exact
    component={PriceMiniPage}
  />,
];

const ROUTES_ROUTE_STRUCTURED_PRODUCTS = [
  <PrivateRoute
    authorized={[ROLE_STRUCTURED_PRODUCTS]}
    path={ROUTE_STRUCTURED_PRODUCTS}
    exact
    component={StructuredProductsPage}
  />,
  <PrivateRoute
    authorized={[ROLE_STRUCTURED_PRODUCTS]}
    path={ROUTE_STRUCTURED_PRODUCTS_HISTORY}
    exact
    component={StructuredProductsPage}
  />,
  <PrivateRoute
    authorized={[ROLE_STRUCTURED_PRODUCTS]}
    path={ROUTE_STRUCTURED_PRODUCTS_SINGLE}
    exact
    component={StructuredProductsPage}
  />,
];

const ROUTES_BOOKINGS = [
  <PrivateRoute
    authorized={[ROLE_BOOKING]}
    path={ROUTE_BOOKING}
    exact
    component={BookingPage}
  />,
  <PrivateRoute
    authorized={[ROLE_BOOKING]}
    path={ROUTE_BOOKING_DETAIL}
    exact
    component={BookingPage}
  />,
];

const ROUTES_ROUTE_PREVIEW_STRUCTURED_PRODUCTS = [
  <PrivateRoute
    authorized={[ROLE_CONFIGURABLE_SP]}
    path={ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS}
    exact
    component={ExperimentalConfigurableSp}
  />,
  <PrivateRoute
    authorized={[ROLE_CONFIGURABLE_SP]}
    path={ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS_HISTORY}
    exact
    component={ExperimentalConfigurableSp}
  />,
];

const ROUTES_PRICING_BETA = [
  <PrivateRoute
    authorized={[ROLE_PRICING_BETA]}
    path={ROUTE_PRICING_BETA}
    exact
    component={PriceBetaPage}
  />,
];

const ROUTES_FX = [
  <PrivateRoute
    authorized={[ROLE_AGILE_FX]}
    path={ROUTE_FX}
    exact
    component={FxPage}
  />,
];

const ROLE_ROUTES = [
  {
    key: ROLE_PRICING_MINI,
    routeMain: ROUTE_PRICING_MINI,
    routes: ROUTES_PRICING_MINI,
  },
  {
    key: ROLE_PRICING,
    routeMain: ROUTE_PRICING,
    routes: [
      ...ROUTES_PRICING,
      // ...ROUTES_NOTIFICATIONS,
    ],
  },
  {
    key: ROLE_DASHBOARD,
    routeMain: ROUTE_DASHBOARDS,
    routes: ROUTES_DASHBOARDS,
  },
  {
    key: ROLE_MY_DASHBOARD,
    routeMain: ROUTES_MY_DASHBOARDS,
    routes: ROUTES_MY_DASHBOARDS,
  },
  {
    key: ROLE_TRADES_HISTORY,
    routeMain: ROUTE_TRADES_HISTORY,
    routes: ROUTES_TRADES_HISTORY,
  },
  {
    key: ROLE_PORTAL,
    routeMain: ROUTE_PORTAL,
    routes: ROUTES_PORTAL,
  },
  {
    key: ROLE_STRUCTURED_PRODUCTS,
    routeMain: ROUTES_ROUTE_STRUCTURED_PRODUCTS,
    routes: ROUTES_ROUTE_STRUCTURED_PRODUCTS,
  },
  {
    key: ROLE_MINIFUTURE,
    routeMain: ROUTE_MINIFUTURE,
    routes: ROUTES_MINIFUTURE,
  },
  {
    key: ROLE_BOOKING,
    routeMain: ROUTE_BOOKING,
    routes: ROUTES_BOOKINGS,
  },
  {
    key: ROLE_CONFIGURABLE_SP,
    routeMain: ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS,
    routes: ROUTES_ROUTE_PREVIEW_STRUCTURED_PRODUCTS,
  },
  {
    key: ROLE_PRICING_BETA,
    routeMain: ROUTE_PRICING_BETA,
    routes: ROUTES_PRICING_BETA,
  },
  {
    key: ROLE_AGILE_FX,
    routeMain: ROUTE_FX,
    routes: ROUTES_FX,
  },
];

export const ROLE_ROUTES_MAP = new Map();
ROLE_ROUTES.forEach((role) => {
  ROLE_ROUTES_MAP.set(role.key, role);
});

const REDIRECT_LOGGED_USER_STATES = [
  ROUTE_LOGIN,
  ROUTE_SIGN_UP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_PROFILE,
  ROUTE_CUSTOM_REQUESTS,
  ROUTE_EMAIL_CONFIRM,
  ROUTE_CUSTOM_REQUEST_OPEN,
];

const ProtectedRoutes = ({ roles }) => {
  const routes = allowedStates(roles);
  return (
    <Switch>
      {routes
        .filter((role) => {
          const roleMap = ROLE_ROUTES_MAP.get(role);
          return roleMap && !isEmpty(roleMap.routes);
        })
        .map((role) => ROLE_ROUTES_MAP.get(role).routes)}
      <PrivateRoute path={ROUTE_PROFILE} exact component={ProfilePage} />
      <Route path={ROUTE_STATIC_PAGE} exact component={StaticPageModalRoute} />
      <Route path={ROUTE_HOME} exact component={HomePage} />
      <Redirect from="/" exact to={DEFAULT_REDIRECT} />
      {REDIRECT_LOGGED_USER_STATES.map((route) => (
        <Redirect key={route} from={route} to={DEFAULT_REDIRECT} />
      ))}
      <Route path={ROUTE_NOT_FOUND} component={NoMatch} />
      <Redirect from="*" exact to={ROUTE_NOT_FOUND} component={NoMatch} />
    </Switch>
  );
};

ProtectedRoutes.defaultProps = {
  roles: [],
};

ProtectedRoutes.propTypes = {
  roles: PropTypes.array.isRequired,
};

export default ProtectedRoutes;
