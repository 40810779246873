
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  (window as any)._env = process.env;
}

export const get = (name: string) => {
  const value = (window as any)[name] || ((window as any)._env && (window as any)._env[`REACT_APP_${name}`]) || null;
  if (!value) {
    throw new Error('Environment variable ' + name + ' does not exist.');
  }
  return value;
};

export const config = {
  API_WS_PROTOCOL: get(`API_WS_PROTOCOL`),
  SUBSCRIPTION_HOST: get(`SUBSCRIPTION_HOST`),
  API_PROTOCOL: get('API_PROTOCOL'),
  API_HOST: get('API_HOST'),
  auth0: {
    clientId: get('AUTH_CLIENT_ID'),
    domain: get('AUTH_DOMAIN'),
    audience: get('AUTH_AUDIENCE'),
    scope: 'agile'
  },
  TAB_ID_KEY: 'tabID'
}