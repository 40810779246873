import { jwtDecode } from 'jwt-decode';
import { ROUTE_LOGIN } from 'pages/constants';
import { config } from './../../config';


const { auth0 } = config;

export const AUTH_WARNING = 'AUTH_WARNING';

export const LOGOUT_OPTION = { logoutParams: { returnTo: `${window.location.origin}${ROUTE_LOGIN}` } }

export const ignoredScopes: string[] = ['openid', 'profile', 'email', 'offline_access'];

export const verifyScopes = (userScopes: string[]): boolean => {
  const scopeList = auth0.scope.split(' ');
  if (!scopeList.some((x) => userScopes.includes(x))) {
    return false;
  }
  return true;
}

export const verifyToken = (token: string): boolean => {
  const decodedAccessToken = jwtDecode<{ scope?: string }>(token);
  const scopes = decodedAccessToken &&
    decodedAccessToken.scope &&
    decodedAccessToken.scope.split(' ').filter(scope => !ignoredScopes.includes(scope)) || [];

  const scopeStatus = verifyScopes(scopes);
  if (!scopeStatus) {
    localStorage.setItem(AUTH_WARNING, 'One or more scopes are not configured for the authorization server resource.');
  }
  return scopeStatus;
}
