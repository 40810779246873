import { apolloErrors } from "providers/ApolloClientProvider";
import { InlineInput } from "components/form/InlineInput";
import InlineInputWithMask from "components/form/inputs/inlineInput/InlineInputWithMask";
import MainErrorMessage from "components/form/MainErrorMessage";
import { selectorAccount } from "components/layout/logged/LoggedHeader";
import SuccessMessage from "components/successMessage/SuccessMessage";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { NavLink } from 'react-router-dom';
import { withApollo } from "react-apollo";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { Field, reduxForm, reset, SubmissionError } from "redux-form";
import { miniAccumulatorCardUpdate } from "redux/actions/accumulator-mini";
import { getPricingMiniFormName } from "redux/epics/price/mini/utils";
import { createSelector } from "reselect";
import { PRICING_MINI_CARD_TYPE_FLEX } from "../pricinigMiniForm/constants";
import { pricingMiniFormValuesSelector } from "../pricinigMiniForm/utils";
import ClientFields from "./ClientFields";
import { CLIENT_STATUS_NA, PRICINIG_MINI_TRADE_FORM } from "./constants";
import { ROUTE_MY_DASHBOARD } from "pages/constants";
import { ROLE_MY_DASHBOARD } from "pages/roles";
import {
  ACCUMULATOR_MINI_TRADE_MUTATION,
  ACCUMULATOR_MINI_TRADE_FLEXIBLE_MUTATION,
} from "./mutations";
import { isClientFields } from "./utils";
import validate from "./validate";

class TradeForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      successMsg: "Your order is currently being reviewed by the Trading team. Please wait.",
    };
  }

  submitTrade = async (formValues) => {
    const {
      trailId: id,
      clientStatus,
      cardId,
      client,
      type,
      cardFormData,
      xmlType,
      useMsa,
      ...restProps
    } = this.props;
    const { notional, comment, clientId, locationId, customerId, alreadyTraded } = formValues || {};
    let mutation = null;
    let responseProp = null;
    const { startDate, endDate, referenceType, reference, msaFixings, premiumAmount = 0 } = cardFormData;
    let variables = {
      id,
      notional: parseFloat(notional),
      comment,
      clientId: !clientStatus && clientId ? clientId : null,
      locationId,
      customerId,
      msaFixings,
      alreadyTraded
    };

    switch (type) {
      case PRICING_MINI_CARD_TYPE_FLEX:
        const {
          pricingTemplateId,
          commodityContractId,
          pricingCardId,
        } = restProps;
        variables = {
          ...variables,
          startDate,
          endDate,
          pricingTemplateId,
          updateState: referenceType,
          priceReference: reference,
          commodityContractId,
          pricingCardId,
        };
        if (useMsa && xmlType.toLowerCase() === 'tas') {
          variables = {
            ...variables,
            premiumAmount
          };
        }
        mutation = ACCUMULATOR_MINI_TRADE_FLEXIBLE_MUTATION;
        responseProp = "accumulatorTradeMiniWithoutPricing";
        break;
      default:
        mutation = ACCUMULATOR_MINI_TRADE_MUTATION;
        responseProp = "accumulatorTradeMini";
    }

    const response = await client.mutate({ mutation, variables, });
    const { data } = response;
    const successMsg = data?.[responseProp]
      ? data[responseProp]
      : this.state.successMsg;
    return successMsg;
  };

  submit = async (data) => {
    const { cardId, miniAccumulatorCardUpdate } = this.props;
    try {
      const successMsg = await this.submitTrade(data);
      this.setState({ submitted: true, successMsg });

      // @TODO: API return only text
      // for now it is ok because fake used only for FactSheet
      // and will ve removed if user change any input value in Pricing Mini Form
      // but need to retur message and id from accumulatorTradeMini mutation
      const tradeId = uniqueId();
      miniAccumulatorCardUpdate({ id: cardId, tradeId, factsheet: {} });
    } catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  };

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  };

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(PRICINIG_MINI_TRADE_FORM);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  closeModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  successContent = (hasMyDashboard) => (
    <div className="form-message text-center">
      {this.state.successMsg}
      {
        !hasMyDashboard ? null :
        <NavLink color="primary" className="btn btn-primary w-100 mt-4" to={ROUTE_MY_DASHBOARD}>
          <FormattedMessage id="trade/view-orders" defaultMessage="See working orders" />
        </NavLink>
      }
    </div>
  );

  render() {
    const {
      pristine,
      handleSubmit,
      invalid,
      submitting,
      error,
      intl: { formatMessage },
      futTradingUnits,
      isClient,
      roles,
      isSlackId
    } = this.props;
    const { submitted } = this.state;
    const hasMyDashboard = roles.some(x => x.name === ROLE_MY_DASHBOARD);

    return submitted ? (
      <SuccessMessage children={this.successContent(hasMyDashboard)} />
    ) : (
      <div className="pricing-mini-trade-form">
        <div className="modal-header">
          <div className="modal-title">
            <FormattedMessage
              id="pricing-mini-trade.title"
              defaultMessage="Order details"
            />
          </div>
        </div>
        <MainErrorMessage error={error} />
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ClientFields isClient={isClient} />
          <Row>
            <Col xs={6}>
              <Field
                name="notional"
                type="formatted-number"
                label="Notional"
                placeHolder={formatMessage({
                  id: "form.pricinig-mini.notional",
                  defaultMessage: "Enter Notional",
                })}
                showMessage={false}
                component={InlineInputWithMask}
              />
            </Col>
            <Col xs={6} className="text-right py-4">
              {futTradingUnits}
            </Col>
          </Row>
          <Field
            name="comment"
            type="textarea"
            label="Comment"
            placeHolder={formatMessage({
              id: "form.pricinig-mini.comment",
              defaultMessage: "Enter Comment",
            })}
            component={InlineInput}
          />

          <div className="text-right">
            <Button
              color="default"
              type="button"
              className="mr-3"
              disabled={submitting}
              onClick={this.closeModal}
            >
              <FormattedMessage id="common/cancel" defaultMessage="Cancel" />
            </Button>
            { isSlackId ?
            <Button
              className="mr-3"
              type="button"
              onClick={handleSubmit((fields) => this.submit({ ...fields, alreadyTraded: true }))}
              color="primary"
              disabled={pristine || invalid || submitting}
            >
              <FormattedMessage
                id="common/already-traded"
                defaultMessage="Already traded"
              />
            </Button> : null }
            <Button
              type="button"
              onClick={handleSubmit(this.submit)}
              color="primary"
              disabled={pristine || invalid || submitting}
            >
              <FormattedMessage
                id="common/send-order"
                defaultMessage="Send order"
              />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const reduxFormOptions = {
  form: PRICINIG_MINI_TRADE_FORM,
  validate,
};

export const mapStateToPropsTradePrcingMini = createSelector(
  selectorAccount,
  (account) => ({
    isClient: isClientFields(account?.clientStatus || CLIENT_STATUS_NA),
    isSlackId: account?.isSlackId,
    roles: account ? account.roles : [],
  })
);

const mapStateToProps = (state, ownProps) => {
  const cardFormName = getPricingMiniFormName(ownProps.cardId);
  const cardFormData = pricingMiniFormValuesSelector(cardFormName)(state);
  return {
    ...mapStateToPropsTradePrcingMini(state),
    cardFormData,
  };
};

const mapDispatchToPropsTradePrcingMini = (dispatch) =>
  bindActionCreators(
    {
      miniAccumulatorCardUpdate,
    },
    dispatch
  );

TradeForm.defaultProps = {
  isClient: false,
  cardFormData: {},
};

TradeForm.propTypes = {
  isClient: PropTypes.bool.isRequired,
  cardId: PropTypes.string.isRequired,
  cardFormData: PropTypes.object.isRequired,
};

export default compose(
  withApollo,
  reduxForm(reduxFormOptions),
  injectIntl,
  connect(mapStateToProps, mapDispatchToPropsTradePrcingMini)
)(TradeForm);
