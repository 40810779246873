import React, { Fragment, PureComponent } from 'react';
import { apolloErrors } from 'providers/ApolloClientProvider';
import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import MainErrorMessage from 'components/form/MainErrorMessage';
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT, RECORD_EMPTY, STRUCTURE_VANILLA } from 'constants.js';
import { formatDate2UI, filterTimeInForceOptions } from 'pages/price/PriceForm/utils';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { Field, Form, getFormValues, reduxForm, reset, SubmissionError } from 'redux-form';
import {
  TIME_IN_FORCE_OPTIONS, TIME_IN_FORCE_DEFAULT, ORDER_TYPES_DEFAULT, TYPE_MARKET,
  PRICINIG_ORDER_TYPES, TIME_IN_FORCE_GTD
} from '../constants.js';
import TradeLabel from '../TradeLabel';
import { CREATE_VANILLA_ORDER_MUTATION } from './query';
import { VANILLA_ORDERS_FORM_INITIAL_VALUES } from './constants.js';
import validate from './vanillaTradeValidate';
import { injectIntl } from 'react-intl';
import OrderTypesRow from 'components/trade/OrderTypesRow';
import OrderAccount from 'components/orders/accounts/AccountsTrade';
import OrderTypeField from 'components/trade/components/OrderTypeField';
import ExpiryDateField from 'components/trade/components/ExpiryDateField';
import { getLivePrice } from 'components/trade/helpers';

class VanillaTrade extends PureComponent {

  constructor(props) {
    super(props);
    const { price, form } = props;
    this.state = {
      submitted: false,
      price,
      formName: form,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { price } = props;
    const { price: statePrice } = state;
    return price !== statePrice ? { price } : null;
  }

  submit = async data => {
    const {
      client,
      commodityCode,
      contractExpiry,
      direction,
      quotedCurrency,
      contractCode,
      commodityContract,
      strikeLevel,
      structureType,
      priceForCalcSpread,
      swapContractCode,
      price: livePrice
    } = this.props;

    const { price,
      quantity,
      timeInForce = TIME_IN_FORCE_DEFAULT.value,
      orderType = ORDER_TYPES_DEFAULT.value,
      stopPrice,
      maxShow,
      expiryDate,
      account,
    } = data;

    try {
      const variables = {
        commodityCode,
        contractExpiry,
        direction,
        price: livePrice || price,
        quotedCurrency,
        quantity,
        contractCode,
        commodityContract,
        strikeLevel,
        structureType,
        timeInForce,
        orderType,
        stopPrice,
        maxShow,
        expiryDate,
        structure: STRUCTURE_VANILLA.toLowerCase(),
        slackIceClientsId: account,
        priceForCalcSpread,
        swapContractCode,
      }

      let response = await client.mutate({
        mutation: CREATE_VANILLA_ORDER_MUTATION,
        variables
      }).then((resp) => {
        if (resp.data.createOrder && resp.data.createOrder.id) {
          this.runResetFormTimeout();
          this.setState({
            submitted: true
          });
        }
        return resp.data;
      });
      return response.data;
    } catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  }

  runResetFormTimeout = () => {
    this.timeout = setTimeout(() => {
      this.resetFormAfterSubmit();
    }, PRICING_FORM_SUBMIT_SUCCESS_TIMEOUT);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  }

  resetFormAfterSubmit = () => {
    const { onClose } = this.props;
    reset(VANILLA_ORDERS_FORM);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  componentWillUnmount() {
    this.clearResetFormTimeout();
  }

  onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      direction,
      contractExpiry,
      commodityContract,
      error,
      firstName,
      lastName,
      structureType,
      strikeLevel,
      formData,
      intl: { formatMessage },
      filterDate,
      structure
    } = this.props;
    const { submitted, formName } = this.state;
    const { quantity, price, timeInForce, orderType, stopPrice, maxShow, expiryDate, maxExpiryDate } = formData;
    const showFormLabel = quantity && ((price && parseFloat(price) > 0) || stopPrice || orderType === TYPE_MARKET) && parseFloat(quantity) > 0;
    const { label: orderTypeLable } = PRICINIG_ORDER_TYPES.find(item => item.value === orderType) || ORDER_TYPES_DEFAULT;
    return (
      submitted ?
        <SuccessMessage children={renderSuccessMessage('Order is currently being reviewed by the Trading team. Please wait.')} />
        :
        <Fragment>
          <h1>
            {direction === 'buyer' ? 'Buy' : 'Sell'}&nbsp;-&nbsp;{commodityContract} - {formatDate2UI(contractExpiry)} - <FormattedNumber
              value={strikeLevel}
              minimumFractionDigits={0}
              maximumFractionDigits={2} /> - <span className="text-capitalize">{structureType}</span>
          </h1>
          <MainErrorMessage error={error} />
          <Form id={`form-card-swap`} onSubmit={handleSubmit(this.submit)} noValidate>
            <Row className="row-size-xs">
              <OrderTypeField structure={structure} isCombinedCard={false} sm={4} />
              <Col xs={12} sm={4}>
                <Field
                  name="timeInForce"
                  className="timeInForce"
                  label={<FormattedMessage id="trade/timeInForce.label"
                    defaultMessage="Time In Force" />}
                  justValue={true}
                  component={InlineSelectInput}
                  options={filterTimeInForceOptions(TIME_IN_FORCE_OPTIONS, orderType)}
                />
              </Col>
              {timeInForce === TIME_IN_FORCE_GTD ? <ExpiryDateField formatMessage={formatMessage} maxExpiryDate={maxExpiryDate} filterDate={filterDate} /> : null}
            </Row>
            <OrderTypesRow {...this.props} />
            <Row>
              <Col xs={12}>
                <OrderAccount
                  direction={direction}
                  formName={formName}
                />
              </Col>
            </Row>

            <div className="mb-3">
              {showFormLabel ?
                <TradeLabel {...this.props} price={price} stopPrice={stopPrice} orderType={orderType} quantity={quantity} maxShow={maxShow} />
                : RECORD_EMPTY}
            </div>

            <div className="mb-3">
              <FormattedMessage id="common/account"
                defaultMessage="User" />: {firstName} {lastName}
            </div>

            <div className="text-right">
              <Button color="default" type="button" className="mr-3" disabled={submitting} onClick={this.onCloseModal}>
                <FormattedMessage id="common/cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button type="submit"
                className={"text-uppercase" + (direction === 'buyer' ? ' btn-buy' : ' btn-sell')}
                onClick={this.trade}
                disabled={invalid || submitting || (timeInForce === TIME_IN_FORCE_GTD && !expiryDate)}>
                <FormattedMessage id="orders.send-limit"
                  defaultMessage={`Send ${orderTypeLable} order`} />
              </Button>
            </div>
          </Form>
        </Fragment>
    );
  }
}

VanillaTrade.defaultProps = {
  quantity: 1,
  formData: {}
};

VanillaTrade.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.number
};

export const VANILLA_ORDERS_FORM = '@PRICINIG/VANILLA/FORM_TRADE';

export const selectorFormValues = getFormValues(VANILLA_ORDERS_FORM);

const mapStateToProps = (state, ownProps) => {
  const { id, price, quantity } = ownProps;
  const formData = selectorFormValues(state);

  const priceData = getLivePrice(id, false, formData, state);
  return {
    formData,
    ...priceData,
    initialValues: {
      price: price || null,
      quantity,
      ...VANILLA_ORDERS_FORM_INITIAL_VALUES
    }
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: VANILLA_ORDERS_FORM,
    enableReinitialize: true,
    validate,
  }),
  withApollo,
  injectIntl,
)(VanillaTrade);
