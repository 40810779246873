import React, { Fragment, PureComponent } from 'react';
import Alerts from 'components/Alerts';
import Loader from 'components/common/loader/Loader';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { IDLE_TIMEOUT } from 'constants.js';
import { ROUTE_LOGIN } from 'pages/constants';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { themeSet } from 'redux/actions/theme';
import IdleContent from '../IdleContent';
import VideoBundle from '../VideoBundle';
import './NonLoggedLayout.scss';
import { QUERY_LOGIN_THEME } from './query';
import RenderLayout from './RenderLayout';

class NonLoggedLayout extends PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      isLoading: true,
      pauseVideo: false
    };
    this.initTheme();
  }

  initTheme = async () => {
    const { client, themeSet } = this.props;
    let theme = null;
    try {
      const { data: { domainTheme } = {} } = await client.query({
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
        query: QUERY_LOGIN_THEME,
      });
      theme = domainTheme;
    } catch (error) {
      console.error(error);
    }
    themeSet(theme);
    this.setState({ isLoading: false });
  }

  onChangeIdle = (idle) => {
    this.setState({ pauseVideo: idle });
  }

  layout = _ => {
    const { children } = this.props;
    return (
      <RenderLayout>
        {children}
      </RenderLayout>
    )
  }

  render() {
    const { location, isTheme } = this.props;
    const { isLoading } = this.state;
    const showIdle = location?.pathname === ROUTE_LOGIN;
    return (
      <div className="nonloged loader-wrapper">
        {!isLoading ?
          <Fragment>
            <Container className="alert-container">
              <Alerts />
            </Container>
            {!isTheme && <VideoBundle />}
            {showIdle ?
              <IdleContent timeout={IDLE_TIMEOUT} onChange={this.onChangeIdle}>
                {this.layout()}
              </IdleContent>
              :
              this.layout()}
          </Fragment>
          : <Loader />}
      </div>
    );
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  themeSet,
}, dispatch);

NonLoggedLayout.defaultProps = {
  isTheme: false,
};

NonLoggedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  themeSet: PropTypes.func.isRequired,
  isTheme: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  withApollo,
  connect(null, mapDispatchToProps),
)(NonLoggedLayout);
