import React, { ReactNode, ReactElement, Fragment, useEffect } from 'react';
import useJwtAuth from './useJwtAuth';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import { logoutContext } from 'providers/Auth0Provider/Auth0Context';
import { useAuthContext } from 'providers/AuthProvider/AuthContext';


interface JwtAdapterProps {
  children: ReactNode,
};

export const JwtAdapter: React.FC<JwtAdapterProps> = ({
  children,
}): ReactElement => {
  const { isLoading, logout } = useJwtAuth();

  useEffect(() => {
    useAuthContext.provider(useJwtAuth);
    logoutContext.provider(logout);
  }, []);

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (<Fragment>{children}</Fragment>);
}