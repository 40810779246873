import { boolean } from "mathjs";

export const AUTH = '@AUTH';
export const ACCOUNT = '@ACCOUNT';
export const AUTH_LOGIN = `${AUTH}/LOGIN`;
export const AUTH_LOGOUT = `${AUTH}/LOGOUT`;
export const AUTH_RESET_TOKEN = `${AUTH}/RESET_TOKEN`;
export const AUTH_SET_ACCOUNT = `${AUTH}/SET_ACCOUNT`;
export const ACCOUNT_DASHBOARD_REQUEST_STATUS = `${ACCOUNT}/DASHBOARD_REQUEST_STATUS`;
export const USER_ONLINE_STATUS = `${ACCOUNT}/STATUS_ONLINE`;
export const UNWIND_STATUS = `${ACCOUNT}/UNWIND_STATUS`;
export const AUTH_SET_TYPE = `${AUTH}/TYPE`;
export const AUTH_JWT_LOADING = `${AUTH}/JWT/LOADING`;
export const AUTH_JWT_AUTHENTICATED = `${AUTH}/JWT/AUTHENTICATED`;

export const login = () => ({ type: AUTH_LOGIN });

export const logout = ( manually = false ) => ({ type: AUTH_LOGOUT, payload: { manually } });

export const setAccountAction = payload => ({ type: AUTH_SET_ACCOUNT, payload });

export const setDashboardRequestAction = payload => ({ type: ACCOUNT_DASHBOARD_REQUEST_STATUS, payload });

export const setUserOnlineStatusAction = payload => ({ type: USER_ONLINE_STATUS, payload });

export const setUnwindIdAction = payload => ({ type: UNWIND_STATUS, payload });

export const setAuthTypeAction = payload => ({ type: AUTH_SET_TYPE, payload });

export const setIsLoadingAction = (payload: boolean) => ({ type: AUTH_JWT_LOADING, payload });

export const setIsAuthenticatedAction = (payload: boolean) => ({ type: AUTH_JWT_AUTHENTICATED, payload });
