import React from 'react';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import NotifyMeDropdown from './NotifyMeDropdown';

const NOTIFYME_SUBSCRIPTION = gql`subscription notifyMeSubscription{
  notifyMeCheck {
    id
    value
  }
}`;


const NotifyMeSubscription = () => (
  <Subscription subscription={ NOTIFYME_SUBSCRIPTION } >
    {({ data }) => {
      return <NotifyMeDropdown className="nav-item-icon" notifyMe={data && !data.loading && data.notifyMeCheck ? data.notifyMeCheck : null} />
    }}
  </Subscription>
);

export default NotifyMeSubscription;
