import 'assets/scss/main.scss';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { bindActionCreators } from 'redux';
import UserOnlineStatusProvider from 'components/common/online/UserOnlineStatus';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import Errors from 'components/errors/Errors';
import InfoMessages from 'components/infoMessages/InfoMessages';
import OldBrowserPage from 'components/oldBrowser/';
import oldBrowserDetect from 'components/oldBrowser/detectOldBrowser';
import { history } from 'components/router/BrowserRouter';
import BreakPointsProvider from 'components/theme/breakPoints/BreakPoints';
import { themeNameSelector } from 'components/theme/utils';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { DEFAULT_APP_TITLE } from './constants';
import RootRoutes from './pages/RootRoute';
import { ConnectedRouter } from 'connected-react-router'
import { QUERY_SETTINGS } from 'redux/queries/settings';
import { setAuthTypeAction } from 'redux/actions/auth';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { IS_AUTH0, isAuth0Selector } from 'providers/AuthProvider';

const App = props => {
  const { themeName, setAuthType, isAuth0Default } = props;

  const { loading, data } = useQuery(QUERY_SETTINGS, {
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
  });
  
  useEffect(() => {
    if(!loading && data) {
      const { isAuth0 } = data?.settings || { isAuth0: false };
      if(isAuth0Default !== isAuth0) {
        setAuthType(isAuth0);
        localStorage.setItem(IS_AUTH0, isAuth0);
      }
    }
  }, [loading, data, isAuth0Default, setAuthType])

  if (loading) {
    return <LoadingFullPage />
  }

  return (
    oldBrowserDetect() ?
      <OldBrowserPage />
      :
      <div className={themeName}>
        <BreakPointsProvider>
          <UserOnlineStatusProvider >
            <Helmet title={DEFAULT_APP_TITLE} />
            <InfoMessages />
            <Errors />
            <ConnectedRouter history={history}>
              <RootRoutes />
            </ConnectedRouter>
          </UserOnlineStatusProvider >
        </BreakPointsProvider>
      </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ setAuthType: setAuthTypeAction }, dispatch);
const mapStateToProps = state => ({
  themeName: themeNameSelector(state),
  isAuth0Default: isAuth0Selector(state),
});

App.propTypes = {
  themeName: PropTypes.string,
  isAuth0Default: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

