import React, { PureComponent } from "react";
import { apolloErrors } from "providers/ApolloClientProvider";
import { withApollo } from "react-apollo";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Form } from "reactstrap";
import { compose } from "redux";
import CountDown from 'components/common/countdown/CountDown';
import { InlineInput } from "components/form/InlineInput";
import MainErrorMessage from "components/form/MainErrorMessage";
import SuccessMessage, { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import { Field, reduxForm, SubmissionError } from "redux-form";
import { OPEN_TRADES_FORM_TRADE_UNWIND } from "./constants";
import { CONFIRM_UNWIND_MUTATION } from './query'
import OpenTradesDataView from './OpenTradesDataView'
import UnwindSummaryView from './UnwindSummaryView';

class OpenTradesUnwindForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      successMsg: "Your order is currently being reviewed by the Trading team. Please wait.",
    };
  }

  submitTrade = async (formValues) => {
    const {
      unwindHistoryId,
      client,
      setUnwindIdAction
    } = this.props;
    const { comment } = formValues || {};

    const variables = {
      unwindHistoryId,
      comment
    }

    await client.mutate({ mutation: CONFIRM_UNWIND_MUTATION, variables });
    setUnwindIdAction(null)
  };

  submit = async (data) => {
    try {
      await this.submitTrade(data);
      this.setState({ submitted: true });
    } catch (e) {
      throw new SubmissionError({ _error: apolloErrors(e) });
    }
  };

  startTimeout = () => {
    const { countDown, onCloseUnwind } = this.props;
    const countDownMS = countDown * 1000;
    this.timeout = setTimeout(() => {
      onCloseUnwind();
    }, countDownMS);
  }

  clearResetFormTimeout = () => {
    clearTimeout(this.timeout);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.onCloseUnwind);
    this.clearResetFormTimeout();
  }

  componentDidMount() {
    if (this.timeout) this.clearResetFormTimeout();
    this.startTimeout();
    window.addEventListener('beforeunload', this.props.onCloseUnwind);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      intl: { formatMessage },
      items,
      summary,
      onCloseUnwind,
      countDown
    } = this.props;
    const { submitted, successMsg } = this.state;

    return submitted ? (
      <SuccessMessage children={renderSuccessMessage(successMsg)} />
    ) : (
      <div className="pricing-mini-trade-form">
        <div className="modal-header">
          <div className="modal-title">
            <FormattedMessage
              id="pricing-mini-trade.title"
              defaultMessage="Indicative Pricing"
            />
          </div>
        </div>
        <MainErrorMessage error={error} />
        <OpenTradesDataView items={items} />
        {summary ? <UnwindSummaryView {...summary} /> : null}
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <Field
            name="comment"
            type="textarea"
            label="Comment"
            placeHolder={formatMessage({
              id: "form.pricinig-mini.comment",
              defaultMessage: "Enter Comment",
            })}
            component={InlineInput}
          />

          <div className="text-right">
            <Button
              color="default"
              type="button"
              className="mr-3"
              disabled={submitting}
              onClick={onCloseUnwind}
            >
              <FormattedMessage id="common/cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              type="button"
              onClick={handleSubmit(this.submit)}
              color="primary"
              disabled={submitting}
            >
              <FormattedMessage
                id="common/send-order"
                defaultMessage="Send order"
              />
            </Button>
          </div>
          <CountDown showCounter={false} count={countDown} showProgress={true} />
        </Form>
      </div>
    );
  }
}

const reduxFormOptions = {
  form: OPEN_TRADES_FORM_TRADE_UNWIND,
};

export default compose(
  withApollo,
  reduxForm(reduxFormOptions),
  injectIntl,
)(OpenTradesUnwindForm);

