import React, { memo, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { bindActionCreators, compose } from "redux";
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from "react-redux";
import { Field, Form, getFormValues, reduxForm, change } from "redux-form";
import { withRouter } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import InlineInput from "components/form/InlineInput";
import InlineSelectInput from "components/form/inputs/InlineSelectInput";
import InputAnimation from 'components/form/inputs/InputAnimation';
import { ROUTE_PRICING_ORDERS_ASIAN } from "pages/constants";
import SizePerSettlementInput from './SizePerSettlementInput';
import { orderInit } from "redux/actions/orders";
import { asianSwapCardsRequestPrice } from "redux/actions/asianSwap";
import validate from "./validate.js";
import { datesBetweenLastMonth, datesBetweenFirstMonth, DEFAULT_MAX_MONTH, DEFAULT_START_DATE_MONTH } from './../helpers.js';
import {
  RECORD_EMPTY,
  CARD_STATUSES,
  DEFAULT_UNIT_TYPE,
} from "constants.js";
const { REQUESTED, APPROVED, NEW } = CARD_STATUSES;

export const getSelectedCurrency = (currencyOptions = [], currency = "") =>
  currencyOptions.find(({ value }) => value === currency) || {};


const checkLegDates = (allLegDates=[], requestInfoDates=[]) => {
  if (allLegDates.length !== requestInfoDates.length) {
    return false;
  }
  return allLegDates.every(date => requestInfoDates.includes(date));
}

const useIsPriceRequestValid = ({
    legs = [],
    commodityCode,
    fxCode,
    direction,
    underlyingContracts: underlying,
    unitType,
    settlement
  }, requestInfo)=> {

  return useMemo(()=> {
    if (!requestInfo) {
      return false;
    }

    const {total, allDates} = legs.reduce((result, leg)=> {
      result.total += leg.notional;
      result.allDates.push(moment.utc(leg.contractExpiry).format('YYYY/MM'))
      return result;
    }, { total: 0 , allDates: [] })

    const isCommoditySame = commodityCode === requestInfo.commodity;
    const isTotalSizeSame = total === requestInfo.totalSize;
    const isCurrencySame = fxCode == requestInfo.currencyPair; // can be null or undefined
    const isDirectionSame = direction === requestInfo.direction;
    const isUnderlyingContractsSame = underlying === requestInfo.underlyingContracts;
    const isUnitTypeSame = unitType === requestInfo.unitType;
    const isDatesSame = checkLegDates(allDates, requestInfo.months);
    const isSettlementSame = settlement === requestInfo.settlement;

    return isCommoditySame
        && isTotalSizeSame
        && isCommoditySame
        && isCurrencySame
        && isDirectionSame
        && isUnderlyingContractsSame
        && isUnitTypeSame
        && isDatesSame
        && isSettlementSame;
  }, [requestInfo, legs, commodityCode, fxCode, direction, underlying, unitType, settlement])
}

function AsianSwapPricingOutputCardForm(props) {
  const {
    touch,
    change,
    direction,
    id,
    index,
    handleSubmit,
    cardRequestPrice,
    invalid,
    isLoading,
    history,
    orderInit,
    currencyOptions,
    status,
    baseCurrency,
    unitOptions,
    formData: { payoutCurrency = '', underlyingContracts = 'Underlying Contract' },
    price,
    unitsDisable,
    disableCompo,
    isError,
    maxMonthes,
    UnderlyingContractOptions,
    SettlementOptions,
    formData,
    formData: { month },
    requestInfo
  } = props;

  const isPriceRequestValid = useIsPriceRequestValid(props, requestInfo);
  const isCombinedCard = payoutCurrency !== baseCurrency;
  const isNewStatus = status === NEW;
  const isRequestedStatus = status === REQUESTED;
  const isDisabledRequestOffer = isCombinedCard && !price;

  const requestBtnName = direction === "buyer" ? "Offer" : "Bid";
  const trade = () => {
    orderInit({ id });
    history.push(ROUTE_PRICING_ORDERS_ASIAN);
  };

  const requestPrice = () => {
    cardRequestPrice({ id });
  };

  useEffect(() => {
    touch('termCurrency');
  }, []);

  const unitHandler = (unit) => {
    const { unitType = DEFAULT_UNIT_TYPE } = unitOptions.find(({ value }) => value === unit) || { unitType: DEFAULT_UNIT_TYPE };
    change('unitType', unitType);
  };

  const FIRST_MONTH_OPTIONS = useMemo(() => datesBetweenFirstMonth(DEFAULT_START_DATE_MONTH, maxMonthes), [maxMonthes]);
  const LAST_MONTH_OPTIONS = useMemo(() => datesBetweenLastMonth(month || DEFAULT_START_DATE_MONTH, maxMonthes), [maxMonthes, month]);

  const onChangeFirstMonthHandler = ({ e }) => {
    const { balmo, month } = FIRST_MONTH_OPTIONS.find(({ value }) => value === e);
    change('balmo', balmo);
    change('month', month);
  }

  const isPriceMismatch = (!isCombinedCard || status === APPROVED) && !isPriceRequestValid && isNumber(price);

  const tradeBtnName = isCombinedCard
      ? !isPriceMismatch ? "Trade" : 'Mismatch'
      : direction === "buyer"
          ? "Request Offer"
          : "Request Bid";

  return (
    <Form id={`form-card-swap-${id}`} onSubmit={handleSubmit} noValidate>
      <Row xs={0} className="pr-0 pl-3">
        <Col className="pl-0">
          <div className="position-relative h1 d-inline-block">
            {isNewStatus ? RECORD_EMPTY : isNumber(price) ?
              <FormattedNumber
                value={price}
                minimumFractionDigits={0}
                maximumFractionDigits={4} />
              : RECORD_EMPTY}
            <InputAnimation value={price} active={true} />
          </div>
        </Col>
        <Col className="pl-0">
          <Field
            name="termCurrency"
            label={
              <FormattedMessage
                id="form.price.fields.payout-currency.label"
                defaultMessage="Currency"
              />
            }
            justValue={true}
            clearable={false}
            showError={true}
            component={InlineSelectInput}
            options={disableCompo ? currencyOptions?.filter(i => isNull(i.fxCode)) : currencyOptions}
            forceDisabled={!currencyOptions}
            preselectIfOneOption={true}
            className={{
              'form-control-force-disabled': false
            }}
          />
        </Col>
      </Row>
      <Row xs={2} className="pr-0 pl-3">
        <Col className="pl-0">
          <Field
            name="firstMonth"
            label="First Month"
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            showError={true}
            onChangeFilter={onChangeFirstMonthHandler}
            options={FIRST_MONTH_OPTIONS}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="lastMonth"
            label="Last Month"
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            showError={true}
            options={LAST_MONTH_OPTIONS}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="fixing"
            placeHolder="Fixing"
            label={
              <FormattedMessage
                id="form.price.fields/total-size.label"
                defaultMessage="Fixing"
              />
            }
            step="1"
            disabled={true}
            component={InlineInput}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="settlement"
            placeHolder="Settlement"
            label={
              <FormattedMessage
                id="form.price.fields/total-size.label"
                defaultMessage="Settlement"
              />
            }
            disabled={true}
            justValue={true}
            clearable={false}
            component={InlineSelectInput}
            options={SettlementOptions}
          />
        </Col>
        <Col className="pl-0">
          <SizePerSettlementInput
            name="legs"
            label="Total Size"
            formData={formData}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="unit"
            label="Unit"
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            onChange={unitHandler}
            disabled={unitsDisable}
            options={unitOptions}
          />
        </Col>
        <Col className="pl-0">
          <Field
            name="underlyingContracts"
            label="Underlying Contract"
            component={InlineSelectInput}
            justValue={true}
            clearable={false}
            fieldId={`t-${index}`}
            options={UnderlyingContractOptions}
          />
          <UncontrolledTooltip placement="top" target={`t-${index}`}>
            <FormattedMessage id="price.asian.tooltip"
              defaultMessage={underlyingContracts} />
          </UncontrolledTooltip>
        </Col>
        <Col className="pb-3 pl-0">
          { isPriceMismatch && <UncontrolledTooltip placement="top" target={`asian-submit-btn`}>
            <FormattedMessage
                id="price.asian.priceTooltip"
                defaultMessage="Price mismatch, please reload agile" />
          </UncontrolledTooltip>}
          {!isCombinedCard || status === APPROVED ? (
              <Button
                  id="asian-submit-btn"
              type="button"
              className={
                "w-100 px-1 btn-input-h m-t-label text-uppercase" +
                (direction === "buyer" ? " btn-buy" : " btn-sell")
              }
              onClick={trade}
              disabled={
                isLoading || invalid || isDisabledRequestOffer || isError || !isPriceRequestValid
              }
            >
              {!invalid && isLoading
                ? "Loading..."
                : tradeBtnName}
            </Button>
          ) : (
            <Button
              type="button"
              className="w-100 px-1 btn-input-h m-t-label text-uppercase btn-primary"
              onClick={requestPrice}
              disabled={isLoading || invalid || isRequestedStatus || isError}
            >
              {!invalid && isLoading
                ? "Loading..."
                : status === REQUESTED
                ? `Requested ${requestBtnName}`
                : `Request ${requestBtnName}`}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
}

AsianSwapPricingOutputCardForm.defaultProps = {
  isLoading: false,
};

AsianSwapPricingOutputCardForm.propTypes = {
  direction: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.any,
};

export const PRICINIG_ASIAN_SWAP_SWAP_FORM_OUTPUT = "@PRICINIG/ASIAN/SWAP/FORM_OUTPUT";
export const swapFormCardName = (id) => `${PRICINIG_ASIAN_SWAP_SWAP_FORM_OUTPUT}_${id}`;
export const swapFormValuesSelector = (id, state) =>
  getFormValues(swapFormCardName(id))(state);

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    baseCurrency,
    termCurrency,
    currencyState = {},
    fxBankHolidays = [],
    unit,
    unitType,
    units: unitOptions,
    fixing,
    settlement,
    firstMonth,
    balmo,
    lastMonth,
    legs,
    maxMonthes,
    underlyingContracts,
    notional
  } = ownProps;

  const currencyList = currencyState[baseCurrency]
    ? currencyState[baseCurrency]
    : [
      {
        label: baseCurrency,
        value: baseCurrency,
        deliverable: false,
        fxCode: null,
      },
    ];

  // TODO: remove 371-374 and rename currencyList (359) when implementing USD convertation for asian swap
  let currencyOptions = currencyList;
  if (baseCurrency === 'USc') {
    currencyOptions = currencyList?.filter(i => i.value !== 'USD');
  };

  return {
    form: swapFormCardName(id),
    formData: swapFormValuesSelector(id, state) || {},
    currencyOptions,
    unitOptions,
    unitsDisable: unitOptions.length <= 1,
    maxMonthes: maxMonthes ? maxMonthes : DEFAULT_MAX_MONTH,
    initialValues: {
      termCurrency,
      baseCurrency,
      unit,
      unitType,
      fixing,
      settlement,
      month: firstMonth,
      balmo,
      lastMonth,
      legs: legs && legs.length ? legs.map(({ notional, contractExpiry }) => ({ notional, contractExpiry })) : [],
      underlyingContracts,
      notional,
      firstMonth: `${firstMonth}-${balmo}`
    },
    fxBankHolidays,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cardRequestPrice: asianSwapCardsRequestPrice,
      orderInit,
      change,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: false,
    validate,
    shouldValidate: () => true,
  }),
  withRouter,
  memo
)(AsianSwapPricingOutputCardForm);
