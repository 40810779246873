import React, { ReactNode, ReactElement, Fragment, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingFullPage from 'components/loading/LoadingFullPage';
import logger from 'utils/Logger';
import { logoutContext } from './Auth0Context';
import { useAuthContext } from 'providers/AuthProvider/AuthContext';
import { verifyToken, LOGOUT_OPTION } from './helpers';


interface Auth0AdapterProps {
  children: ReactNode,
  verifyScopes?: boolean
};

export const Auth0Adapter: React.FC<Auth0AdapterProps> = ({
  children,
  verifyScopes = true,
}): ReactElement => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    useAuthContext.provider(useAuth0);
    logoutContext.provider(logout);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading && !error && verifyScopes) {
      getAccessTokenSilently()
        .then((token) => {
          if (!verifyToken(token)) {
            logout(LOGOUT_OPTION).then();
          }
        }).catch((error) => {
          logger.error('Auth0 errors.', {
            error: error.message,
            stack: error.stack || "",
          });
        });
    }

  }, [getAccessTokenSilently, error, isLoading, isAuthenticated, verifyScopes]);

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (<Fragment>{children}</Fragment>);
}