import React from 'react';
import PropTypes from 'prop-types';
import { PRICING_MINI_CARD_TYPES, PRICING_MINI_CARD_TYPE_DEFAULT, PRICING_MINI_CARD_TYPE_FLEX } from 'pages/price/mini/components/pricinigMiniForm/constants.js';
import PricingMiniFormDefault from './PricingMiniFormDefault';
import PricingMiniFormFlexible from './PricingMiniFormFlexible';
import useUpdateScheduleDate from './useUpdateScheduleDate';

const PricingMiniFormChooser = (props) => {
  const { type, form, formData } = props;
  const { startDate, endDate, structureExpiryDate } = formData || {};
  useUpdateScheduleDate({ startDate, endDate, structureExpiryDate }, form);

  switch (type) {
    case PRICING_MINI_CARD_TYPE_FLEX:
      return <PricingMiniFormFlexible {...props} />;
    default:
      return <PricingMiniFormDefault {...props} />;
  }
};

PricingMiniFormChooser.defaultProps = {
  type: PRICING_MINI_CARD_TYPE_DEFAULT,
};

PricingMiniFormChooser.propTypes = {
  type: PropTypes.oneOf(PRICING_MINI_CARD_TYPES).isRequired,
};

export default PricingMiniFormChooser;
