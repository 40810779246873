import { createSelector } from 'reselect';

export const STORAGE_NAME = 'AUTH_TOKEN';

export type AuthState = {
  isAuthenticated: boolean,
  isLoading: boolean
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
};

export const jwtStateSelector = createSelector(
  state => state.auth,
  ({ jwtState }) => jwtState || initialAuthState
);