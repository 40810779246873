import apolloClient from 'providers/ApolloClientProvider';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { setMiniAccumulatorCardsLoader } from 'redux/actions/accumulator-mini';
import store from 'store.js';
import { mapNodes } from 'utils/';
import { notificationErrorSimple } from '../alerts/actions';


export const QUERY_PRICING_MINI_CARDS = gql`query queryLoadPricingCardsConnection (
  $filter: [PricingTemplatesCardsFilterType],
  $page: Int
  $first: Int
) {
  pricingCardsConnection (
    filter: $filter
    page: $page
    first: $first
  ) {
    edges {
      node {
        id
        cardId: id
        pricingTemplateId: templateid
        roundingRule: roundingrule
        pricingCardId: pricingcardid
        underlyingId: underlyingid
        title
        productDescription
        #description
        fields {
          label
          range
          defaultValue
          range
          isSolvable
          field
        }
        structure
        flexible
        type
        dateconfig {
          expiryAllowedWeekDays: expiryAllowedDays
        }
        underlying {
          commodityContract
          currency
          logo
          slug
          data {
            id
            commodityContract
            bloombergTicker
            expiryDate
            contractExpiry
            startDate
            endDate
          }
        }
        useMsa
        xmlType
      }
    }
    pageInfo {
      hasNextPage
    },
  }
}`;

const loadAccumulatorMiniPricngsCardsDataFilter = (data) => {
  const { groupIds = [], underlyingId, cardIds = [] } = data || {};

  const underlyingIdFilter = {
    "operator": "eq",
    "value": parseInt(underlyingId, 10),
    "field": "underlyingId"
  };

  const filter = groupIds?.length > 0 ? [
    {
      "operator": "in",
      "value": groupIds,
      "field": "accumulatorGroupsId"
    },
    underlyingIdFilter
  ] : [
      {
        "operator": "eq",
        "value": parseInt(underlyingId, 10),
        "field": "underlyingId"
      }
    ];

  if (cardIds?.length > 0) {
    return [
      underlyingIdFilter,
      {
        "operator": "in",
        "value": cardIds,
        "field": "pricingCardId"
      }
    ]
  }

  return filter;
}

export const loadAccumulatorMiniPricngsCardsData = async (data, page = 1) => {

  const filter = loadAccumulatorMiniPricngsCardsDataFilter(data);

  const variables = { filter, first: 8, page }; // Need to fix pagination
  store.dispatch(setMiniAccumulatorCardsLoader(true));
  try {
    let resp = await apolloClient.query({
      query: QUERY_PRICING_MINI_CARDS,
      variables,
      fetchPolicy: 'no-cache'
    })
      .then(({ data }) => {
        const cards = mapNodes(data.pricingCardsConnection);
        if (isEmpty(cards)) {
          store.dispatch(notificationErrorSimple(`You don't have any Pricing Cards`));
        }

        const pageInfo = data.pricingCardsConnection.pageInfo;
        return { cards, pageInfo };
      });
    return resp;
  } catch (e) {
    console.log(e);
    store.dispatch(notificationErrorSimple(`Can't load Pricing Cards`));
  }
};

export const QUERY_GET_REFERENCE_PRICE = gql`query queryLoadPricingCardsConnection (
  $type: String
  $structure: String!
  $underlyingId: Int!
  $cardId: String
) {
  referencePrice (
    type: $type
    structure: $structure
    underlyingId: $underlyingId
    cardId: $cardId
  ) {
    referencePrice
  }
}`;

export const getReferencePrice = async ({ id: cardId, type = 'accumulator-mini', structure, selectedUnderlying }) => {
  const underlyingId = selectedUnderlying?.id;
  const variables = { type, structure, underlyingId, cardId };
  try {
    let resp = await apolloClient.query({
      query: QUERY_GET_REFERENCE_PRICE,
      variables,
      fetchPolicy: GRAPHQL_NETWORK_ONLY
    })

    return resp.data.referencePrice;
  } catch (e) {
    console.log(e);
    store.dispatch(notificationErrorSimple(`Can't get reference price for Pricing Card`));
    return {}
  }
};

export const QUERY_SCHEDULE_DATE = gql`query queryGetScheduleDates (
  $cardId: Int!
  $underlyingId: String!
  $underlyingDataId: String
  $startDate: DateTime
  $endDate: DateTime!
) {
  getScheduleDate (
    cardId: $cardId
    underlyingId: $underlyingId
    underlyingDataId: $underlyingDataId
    startDate: $startDate
    endDate: $endDate
  ) {
    size
    dailySize
    frequency
    calendar
  }
}`;

export const getScheduleDate = async (variables) => {
  try {
    let resp = await apolloClient.query({
      query: QUERY_SCHEDULE_DATE,
      variables,
      fetchPolicy: GRAPHQL_NETWORK_ONLY
    })

    return resp.data.getScheduleDate;
  } catch (e) {
    console.log(e);
    store.dispatch(notificationErrorSimple(`Can't get schedule dates for Pricing Card`));
  }
};
