import { ROUTE_PRICING, ROUTE_PRICING_MINI } from "pages/constants";
import { ROUTE_PRICING as ROUTE_PRICING_BETA } from 'pages/pricing/constants';

export function isActiveLink(path = '') {
  const { location: { pathname } } = this.props;
  const route = path.split('/');
  if (
    (path === ROUTE_PRICING_MINI && pathname.indexOf(ROUTE_PRICING_MINI) < 0)
    || (path === ROUTE_PRICING && pathname.indexOf(ROUTE_PRICING_MINI) >= 0)
    || (path === ROUTE_PRICING && pathname.indexOf(ROUTE_PRICING_MINI) >= 0)
    || (path === ROUTE_PRICING && pathname.indexOf(ROUTE_PRICING_BETA) >= 0)
  ) return '';
  return pathname === path || (route[1] && pathname.indexOf(route[1]) >= 0) ? ' active' : '';
}
