import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { change, Field } from 'redux-form';

export const markerLeftPosition = (options, active) => {
  const findIndex = options.findIndex((option) => option.value === active);
  const leftPostion = 100 / options.length * findIndex;
  return leftPostion > 0 ? leftPostion.toFixed(4).toString() : '0'
}

const CardRadioBtn = (props) => {

  const { groupType, options = [], label, input, change, meta, cardId, className, onChange } = props;
  const name = input && input.name ? input.name : null;
  const markerStyle = (input && input.value) ?
    {
      left: `${markerLeftPosition(options, input.value)}%`
    }
    : null;

  if (options && options.length === 1) {
    const selectValue = options[0].value;
    change(meta.form, name, selectValue);
  }

  const onChangeHandler = useCallback((event, value) => {
    event.preventDefault();
    input.onChange(value);
  }, []);

  return (
    <FormGroup className={`${className} form-group-radiogroup form-group-size-${groupType} form-group-${name.toLowerCase()}`}>
      {label ? <Label for={name}>{label}</Label> : null}
      <div className={`pills-rounded pills-card nav clearfix nav-pills nav-pills-col-${options.length}`}>
        {options.map((radio, index) => {
          const inputId = name + '-' + index + '-' + cardId;
          return (
            <div className="nav-item" key={radio.value}>
              <Field
                name={name}
                id={inputId}
                component="input"
                type="radio"
                value={radio.value}
                disabled={radio.disabled}
                onChange={onChangeHandler}
              />
              <Label className="nav-link" for={inputId}>
                <span>
                  {radio.label.toUpperCase()}
                </span>
              </Label>
            </div>)
        })}
        {input.value ? <div className="nav-pills-marker" key="pills-marker" style={markerStyle}></div> : null}
      </div>
    </FormGroup>
  )
}

CardRadioBtn.defaultProps = {
  options: [],
  groupType: 'md'
}

CardRadioBtn.propTypes = {
  options: PropTypes.array.isRequired,
  cardId: PropTypes.string.isRequired,
  className: PropTypes.string,
  groupType: PropTypes.oneOf(['xs', 'sm', 'lg', 'md']),
  onChange: PropTypes.func
};

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export { CardRadioBtn as Component };
export default compose(
  memo,
  connect(null, mapDispatchToProps),
)(CardRadioBtn);
