import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import _ from 'lodash';
import { RECORD_EMPTY } from 'constants.js';
import {
  BUY_DIRECTION,
  STRUCTURE_TO_LEG_TYPE,
  STRUCTURE_TO_PACKAGE_TYPE,
  ALLOW_BARRIER,
  ALLOW_FOR_FREQUENCY
} from 'pages/booking/components/FormGenerator/CustomStrip/defaults';
import {
  FIRST_LAST_MONTH_FORMAT,
  EMPTY_TITLE,
  INSTRUMENT_TO_LEG_TITLE,
  CUSTOM_STRIPE_FORMS,
  CUSTOM_STRIPE_STEPS,
  FIXING_DATE_TYPE,
  DEFAULT_PACKAGE_TYPES,
  PACKAGE_TYPE,
  VALID_PACKAGE_TYPES,
  PREMIUM_TYPE,
  CASH_TRANSACTION,
  INSTRUMENT_FORM_TYPE,
} from 'pages/booking/constants';


export const getOptionId = () => (Math.random() + Date.now()).toString(36);

export const flatData = (option = {}) => Object.assign({}, ...Object.values(option))
export const getStepData = ({ active, ...bookingState }) => {
  const { [active]: { data: option }, asyncErrors, options } = bookingState;

  const selectedInstruments = options.map((id) => bookingState[id].data[INSTRUMENT_FORM_TYPE]?.instrument);

  return {
    asyncErrors,
    active,
    option,
    data: flatData(option),
    selectedInstruments
  }
}

export const structureToType = (structure) =>
  STRUCTURE_TO_PACKAGE_TYPE.has(structure) ? STRUCTURE_TO_PACKAGE_TYPE.get(structure) : null

export const getPackageTypes = ({ options, active, ...rest }, excludeActive = false) => {
  return options.reduce((acc, hash) => {
    if (excludeActive && active === hash) return acc;
    const { data } = rest[hash] || {};
    const { structure } = flatData(data);
    const type = structureToType(structure);
    return {
      ...acc,
      [type]: acc[type] + 1,
    }
  }, DEFAULT_PACKAGE_TYPES);
}

export const validatePackageTypes = ({ [PACKAGE_TYPE.SWAP]: swap, [PACKAGE_TYPE.OPTION]: option }) => {
  return swap < VALID_PACKAGE_TYPES[PACKAGE_TYPE.SWAP] || option < VALID_PACKAGE_TYPES[PACKAGE_TYPE.OPTION]
}

export const getFieldOptions = ({ structure }) => {
  const isAsian = /asian/.test(structure.toLowerCase());
  const isFuture = /future/.test(structure.toLowerCase());
  const isBullet = /bullet/.test(structure.toLowerCase());

  return {
    isAsian,
    isFuture,
    isBullet,
    isUnderlyingContracts: !(isBullet || isFuture),
    isFirstFixingDate: true,
    isLastFixingDate: isFuture || isBullet ? false : true,
  }
}

const getFronMonth = (isBullet, isFuture) => !(isBullet || isFuture) ? 'Front Month' : null;

export const getDefaultSchedule = (count, { firstMonth, strikeUnit }, { isFuture, isFirstFixingDate, isLastFixingDate, isUnderlyingContracts, isBullet }) =>
  Array.apply(null, Array(count + 1))
    .map((value, index) => {
      const month = moment.utc(firstMonth).add(index, 'months');
      const workingDays = momentBusinessDays(month).monthBusinessDays();
      const { 0: firstFixingDate, [workingDays.length - 1]: lastFixingDate } = workingDays;
      return {
        month,
        direction: BUY_DIRECTION.toLowerCase(),
        volume: 10000,
        volumeUnit: strikeUnit,
        underlyingContracts: getFronMonth(isBullet, isFuture),
        firstFixingDate: isFirstFixingDate && isUnderlyingContracts ? firstFixingDate : null,
        lastFixingDate: isLastFixingDate ? lastFixingDate : null,
        fixingDateType: FIXING_DATE_TYPE.CUSTOM,
      }
    });


export const FormattedMonthField = ({ input: { value } }) => (<span>{moment.utc(value).format(FIRST_LAST_MONTH_FORMAT)}</span>);
export const DataField = ({ input: { value } }) => (<span>{value || RECORD_EMPTY}</span>);
export const queryVariables = (underlying) => (_, search) => {
  if (!search && underlying) {
    return {
      filter: [{ field: 'shortTicker', operator: 'eq', value: underlying }]
    }
  }
  return search ? { search } : {};
}

export const getLegTitle = (data) => {
  const { instrument, structure, commodityContract, premium, direction, strikeCurrency } = Object.assign({}, ...Object.values(data));
  if (instrument === PREMIUM_TYPE) return premium ? `${INSTRUMENT_TO_LEG_TITLE.get(instrument)} - ${direction} ${premium} ${strikeCurrency}` : EMPTY_TITLE;
  if (instrument === CASH_TRANSACTION) return premium ? `${INSTRUMENT_TO_LEG_TITLE.get(instrument)} - ${commodityContract} - ${direction} ${premium} ${strikeCurrency}` : EMPTY_TITLE;
  return commodityContract ? `${INSTRUMENT_TO_LEG_TITLE.get(instrument)} - ${structure} - ${commodityContract}` : EMPTY_TITLE;
}

export const getLegSubmission = ({ instrument, ...leg }) => {
  if (instrument === PREMIUM_TYPE) {
    const {
      premium,
      strikeCurrency,
      direction
    } = leg;
    return {
      type: 'premium',
      submission: {
        instrument,
        premium,
        strikeCurrency,
        direction
      }
    }
  }
  if (instrument === CASH_TRANSACTION) {
    const {
      underlying,
      strikeCurrency,
      strikeUnit,
      volume,
      price,
      buySell,
      paymentDate,
      premium,
      direction,
    } = leg;
    return {
      type: 'spot',
      submission: {
        instrument,
        underlying,
        strikeCurrency,
        strikeUnit,
        volume,
        price,
        buySell,
        paymentDate,
        premium,
        direction,
      }
    }
  }
  const {
    structure,
    underlying,
    strike,
    strikeCurrency,
    strikeUnit,
    fixingFrequency,
    vanillaType,
    barrierType,
    barrierLevel,
    firstMonth,
    lastMonth,
    schedule,
  } = leg;
  return {
    type: STRUCTURE_TO_LEG_TYPE.has(structure) ? STRUCTURE_TO_LEG_TYPE.get(structure) : null,
    submission: {
      instrument,
      underlying,
      strike,
      strikeCurrency,
      strikeUnit,
      fixingFrequency,
      vanillaType,
      barrierType,
      barrierLevel,
      firstMonth,
      lastMonth,
      schedule
    }
  }
};

export const mapFieldsToForm = (option) => _.transform(option, (result, value, key) => {
  Object.assign(result, ...Object.keys(value).map((item) => ({ [item]: key })))
}, {
  schedule: CUSTOM_STRIPE_FORMS.CALENDAR,
});

export const getStepIdByForm = (form) => CUSTOM_STRIPE_STEPS.findIndex(({ __type }) => __type === form);

export const mapLegErrors = (logicErrors, count, option) => {
  const FIELDS_TO_FORM = mapFieldsToForm(option);

  const { steps, ...errors } = logicErrors.reduce(({ steps, ...acc }, { fieldName, error }) => {
    const [field, row, subField] = fieldName.split('.');
    const { schedule } = acc[CUSTOM_STRIPE_FORMS.CALENDAR];
    if (field === 'schedule') schedule[row] = { ...schedule[row], [subField]: [error] };
    const form = FIELDS_TO_FORM[field];
    steps.add(form || CUSTOM_STRIPE_FORMS.CALENDAR);
    return {
      ...acc,
      [form]: {
        ...acc[form],
        [field]: [error],
      },
      [CUSTOM_STRIPE_FORMS.CALENDAR]: {
        schedule,
      },
      steps,
    };
  }, { [CUSTOM_STRIPE_FORMS.CALENDAR]: { schedule: Array.apply(null, Array(count + 1)).fill({}) }, steps: new Set() });

  return {
    steps: [...steps],
    activeStep: getStepIdByForm(steps.has(CUSTOM_STRIPE_FORMS.CALENDAR) ? CUSTOM_STRIPE_FORMS.CALENDAR : [...steps][0]),
    errors
  };
}

export const isBarriers = (structure) => ALLOW_BARRIER.includes(structure);
export const isFixingFrequency = (structure) => ALLOW_FOR_FREQUENCY.includes(structure);

export const filterUnitOptions = (filterUnit, STRIKE_UNIT_OPTIONS) => {
  const UNIT_OPTIONS = filterUnit && filterUnit.length ?
    STRIKE_UNIT_OPTIONS.filter(({ value }) => filterUnit.includes(value))
    :
    STRIKE_UNIT_OPTIONS;

  return UNIT_OPTIONS.length ? UNIT_OPTIONS : STRIKE_UNIT_OPTIONS;
}
