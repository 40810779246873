export const BOOKING = '@BOOKING';

export const BOOKING_OPTION_ADD = `${BOOKING}/OPTION_ADD`;
export const addOptionAction = () => ({ type: BOOKING_OPTION_ADD });

export const BOOKING_RESET = `${BOOKING}/RESET`;
export const resetAction = () => ({ type: BOOKING_RESET });

export const BOOKING_OPTION_DELETE = `${BOOKING}/OPTION_DELETE`;
export const deleteOptionAction = payload => ({ type: BOOKING_OPTION_DELETE, payload });

export const BOOKING_OPTION_SELECT = `${BOOKING}/OPTION_SELECT`;
export const selectOptionAction = payload => ({ type: BOOKING_OPTION_SELECT, payload });


export const BOOKING_OPTION_NEXT = `${BOOKING}/OPTION_NEXT`;
export const handleNextAction = (data, reset = []) => ({
  type: BOOKING_OPTION_NEXT,
  payload: {
    data,
    reset,
  }
});

export const BOOKING_OPTION_BACK = `${BOOKING}/OPTION_BACK`;
export const handleBackAction = payload => ({ type: BOOKING_OPTION_BACK, payload });

export const BOOKING_OPTION_STEP = `${BOOKING}/OPTION_STEP`;
export const handleStepAction = payload => ({ type: BOOKING_OPTION_STEP, payload });

export const BOOKING_CALENDAR_FILL_ALL = `${BOOKING}/CALENDAR_FILL_ALL`;
export const fillAllAction = payload => ({ type: BOOKING_CALENDAR_FILL_ALL, payload });

export const BOOKING_ASYNC_ERROR = `${BOOKING}/ASYNC_ERROR`;
export const onErrorAction = (errors, data) => ({
  type: BOOKING_ASYNC_ERROR,
  payload: {
    errors, data
  }
});

export const BOOKING_INSTRUMENT_CHANGE = `${BOOKING}/INSTRUMENT_CHANGE`;
export const setInstrument = payload => ({ type: BOOKING_INSTRUMENT_CHANGE, payload });