import React, { ReactNode, ReactElement } from 'react';
import { connect } from 'react-redux';
import JwtProvider from 'providers/JwtProvider';
import Auth0Provider from 'providers/Auth0Provider';
import { isAuth0Selector } from './helpers';


interface AuthProviderProps {
  children: ReactNode,
  isAuth0: boolean
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  isAuth0
}): ReactElement => {

  if (!isAuth0) {
    return (<JwtProvider>{children}</JwtProvider>);
  }

  return (<Auth0Provider>{children}</Auth0Provider>);
}

const authProviderMapStateToProps = state => ({
  isAuth0: isAuth0Selector(state),
});

export default connect(
  authProviderMapStateToProps
)(AuthProvider);