import gql from 'graphql-tag';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';

export const ORDERS_QUERY = gql`query neonOrdersQuery (
  $cursor: String
  $total: Int
  $filter: [NeonOrderFilterType]
  $sorter: [NeonOrderSorterType]
  ) {
  neonOrders (
    after: $cursor
    first: $total
    filter: $filter
    sorter: $sorter
  ) {
    edges {
      node {
        id
        commodityCode
        createdAt
        updatedAt
        filledQuantity
        quantity
        price
        contractExpiry
        contractExpirySecond
        quotedCurrency
        commodityContract
        direction
        status
        structure
        strikeLevel
        structureType
        avgPrice
        timeInForce
        orderType
        stopPrice
        maxShow
        expiryDate
        notes
        unit
        isin
        issueSize
        totalSold
        remaining
        fairValue
        firstMonth,
        lastMonth,
        fixing,
        settlement,
        underlyingContracts,
        isConvertingUscToUsd
        legs(filter: $filter) {
          orderType
          price
          avgPrice
          stopPrice
          quantity
          filledQuantity
          factor
          status
        }
        user {
          id
          firstName
          lastName
        }
        orderAccount  {
          id
          client
        }
        __typename
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    },
  }
}
`;

export const DAILY_ORDERS_QUERY = gql`query dailyOrdersQuery(
  $cursor: String
  $total: Int
  $sorter: [NeonOrderSorterType]
  $type: DailyOrdersEnumType!
  ) {
  dailyOrders(
    after: $cursor
    first: $total
    sorter: $sorter
    type: $type
    ) {
    edges {
      node {
        id
        commodityCode
        contractCode
        createdAt
        updatedAt
        filledQuantity
        quantity
        price
        contractExpiry
        contractExpirySecond
        quotedCurrency
        quantity
        commodityContract
        direction
        status
        structure
        strikeLevel
        structureType
        avgPrice
        isConvertingUscToUsd
        timeInForce
        orderType
        stopPrice
        maxShow
        expiryDate
        notes
        view
        clientDirectionFirst
        clientDirectionSecond
        unit
        isin
        spotReference
        forwardPoints
        termCurrency
        firstMonth
        lastMonth
        user {
          lastName
          firstName
        }
        orderAccount  {
            id
            client
        }
        legs {
          id
          quantity
          filledQuantity
          price
          avgPrice
          stopPrice
          orderType
        }
        isTas
        __typename
      }
      __typename
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      __typename
    }
    __typename
  }
}`;

export const ORDER_DELETE_MUTATION = gql`mutation neonOrderCancelMutation (
   $id: String!
   ) {
   neonOrderCancel(
     id: $id
  )
}`;

export const QUERY_ORDER_GET = gql`query neonOrderQuery (
  $id: ID!
  ) {
  getOrder(
    id: $id
 ) {
    id
    direction
    quantity
    quotedCurrency
    filledQuantity
    price
    avgPrice
    structure
    status
    strikeLevel
    contractExpiry
    contractExpirySecond
    commodityCode
    commodityContract
    view
    clientDirectionFirst
    clientDirectionSecond
    timeInForce
    orderType
    stopPrice
    maxShow
    expiryDate
    unit
    termCurrency
    isConvertingUscToUsd
    legs {
      id
      quantity
      filledQuantity
      price
      avgPrice
      stopPrice
      orderType
    }
 }
}`;

export const QUERY_MAX_GTD_DATE_QUERY = gql`query maxGtdValueQuery (
    $cardId: ID!
    $structure: String!
  ) {
    maxGtdValue(
      cardId: $cardId
      structure: $structure
  )
}`;


export const QUERY_ISIN = gql`query isinQuery (
  $contractCode: String!
  $strike: Float
  $type: ClientDirectionSecondEnumType
  $clientId: ID
) {
  isin(
    contractCode: $contractCode
    strike: $strike
    type: $type
    clientId: $clientId
  ) {
    label
    value
  }
}`;

export const getISINOptions = async (client, variables) => {
  const response = await client.query({
    query: QUERY_ISIN,
    variables,
    fetchPolicy: GRAPHQL_NETWORK_ONLY,
  });
  const { data: { isin = [] } = {} } = response;
  return isin || [];
};
